import { connect } from 'react-redux';
import { map } from 'lodash';
import { selectTranslations } from '../../store/languageSlice';
import {
  deleteObjects,
  runCustomProcess,
  setFilteredRows,
  resetFilteredRows,
  setCurrentTableRows
} from '../../store/objectSlice';
import DataGridItem from './DataGridItem';

function mapStateToProps(state, ownProps) {
  const {
    item: { id: tableConfigId, dataGridColumns },
    moduleId,
    submoduleId,
    searchText,
    isHistory,
    isFavorites,
    isTemplates,
    getColumns
  } = ownProps;
  const {
    entities: { columns, properties, submodules },
    module: {
      objectListingRows,
      searchResultRows,
      historyRows,
      favoritesRows,
      templateRows
    },
    object: {
      activeObject: { processRunAt },
      filteredRowsByItem
    }
  } = state;
  const itemColumns = map(dataGridColumns, (id) => columns[id]);
  const processes = map(submodules[submoduleId]?.processes, (process) => ({
    label: process
  }));
  let rows;

  if (searchText) {
    rows = searchResultRows[moduleId] && searchResultRows[moduleId][searchText];
  } else if (isHistory) {
    rows = historyRows;
  } else if (isFavorites) {
    rows = favoritesRows;
  } else if (isTemplates) {
    rows = templateRows;
  } else {
    rows =
      objectListingRows[submoduleId] &&
      objectListingRows[submoduleId][tableConfigId];
  }

  rows = rows || [];

  return {
    translations: selectTranslations(state),
    properties,
    itemColumns,
    itemSubmodules: [],
    rows,
    filteredRows: filteredRowsByItem[tableConfigId] || [],
    processes,
    processRunAt,
    getColumns
  };
}

export default connect(mapStateToProps, {
  deleteObjects,
  runCustomProcess,
  setFilteredRows,
  resetFilteredRows,
  setCurrentTableRows
})(DataGridItem);

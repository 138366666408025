import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Helmet } from 'react-helmet-async';
import memoizeOne from 'memoize-one';
import ReactDataGrid from 'react-data-grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import EditUserGroupDialog from './EditUserGroupDialog';
import DeleteDialog from './DeleteDialog';
import { selectTranslations } from '../store/languageSlice';
import { loadUserGroupList, deleteUserGroup } from '../store/userSlice';
import './Users.scss';

class UsersGroups extends React.PureComponent {
  state = {
    groupToEdit: null,
    groupIdToDelete: null
  };

  componentDidMount() {
    const { loadUserGroupList } = this.props;

    loadUserGroupList();
  }

  componentDidUpdate(prevProps) {
    const { groupSavedAt, groupDeletedAt, loadUserGroupList } = this.props;

    if (
      (groupSavedAt && groupSavedAt !== prevProps.groupSavedAt) ||
      (groupDeletedAt && groupDeletedAt !== prevProps.groupDeletedAt)
    ) {
      loadUserGroupList();
    }
  }

  render() {
    const { translations, groups } = this.props;
    const { groupToEdit, groupIdToDelete } = this.state;
    const columns = this._getColumns();

    return (
      <div className="users object-listing">
        <Helmet>
          <title>{translations.user_group}</title>
        </Helmet>
        <div className="cta-container">
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={this._onCreateGroup}
          >
            Neuer Benutzergruppe
          </Button>
        </div>
        <ReactDataGrid
          className="data-grid rdg-light"
          columns={columns}
          rows={groups}
          rowHeight={30}
          rowKeyGetter={(row) => row.id}
        />
        {groupToEdit && (
          <EditUserGroupDialog
            group={groupToEdit}
            onClose={this._onCloseEdit}
          />
        )}
        <DeleteDialog
          open={!!groupIdToDelete}
          onConfirm={this._onConfirmDelete}
          onClose={this._onCloseDeleteConfirmation}
        />
      </div>
    );
  }

  _getColumns = memoizeOne(() => {
    const { translations } = this.props;

    return [
      {
        key: 'name',
        name: translations.user_group_name
      },
      {
        key: 'optionsColumn',
        cellClass: 'options-cell',
        width: 50,
        maxWidth: 50,
        formatter: (props) => {
          const {
            row: { id, token }
          } = props;

          if (token === 'GENERAL_ROLE_TOKEN') {
            return null;
          }

          return (
            <div>
              <Tooltip title={translations.edit_user_group} arrow>
                <Button
                  data-id={id}
                  className="icon-button"
                  color="primary"
                  variant="contained"
                  onClick={this._onEditGroup}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title={translations.delete} arrow>
                <Button
                  data-id={id}
                  className="icon-button"
                  color="primary"
                  variant="contained"
                  onClick={this._onDeleteGroup}
                >
                  <RemoveIcon fontSize="small" />
                </Button>
              </Tooltip>
            </div>
          );
        }
      }
    ];
  });

  _onCreateGroup = () => {
    this.setState({
      groupToEdit: {
        name: ''
      }
    });
  };

  _onEditGroup = (e) => {
    const { groups } = this.props;
    const { id } = e.currentTarget.dataset;
    const group = find(groups, { id });

    this.setState({ groupToEdit: { ...group } });
  };

  _onCloseEdit = () => {
    this.setState({ groupToEdit: null });
  };

  _onDeleteGroup = (e) => {
    const { id } = e.currentTarget.dataset;

    this.setState({ groupIdToDelete: id });
  };

  _onCloseDeleteConfirmation = () => {
    this.setState({ groupIdToDelete: null });
  };

  _onConfirmDelete = () => {
    const { deleteUserGroup } = this.props;
    const { groupIdToDelete } = this.state;

    deleteUserGroup({ groupId: groupIdToDelete });

    this._onCloseDeleteConfirmation();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    user: { groups, groupSavedAt, groupDeletedAt }
  } = state;

  return {
    translations: selectTranslations(state),
    groups,
    groupSavedAt,
    groupDeletedAt
  };
}

export default connect(mapStateToProps, { loadUserGroupList, deleteUserGroup })(
  UsersGroups
);

import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  isEmpty,
  isEqual,
  map,
  filter,
  forEach,
  find,
  compact,
  split,
  get
} from 'lodash';
import { Helmet } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getObjectListingPath,
  getObjectViewPath,
  getBulkEditPath
} from '../constants/paths';
import { getDependentValues } from '../utils/DataSourceUtils';
import ItemView from './items/ItemView';
import ObjectInfo from './ObjectInfo';
import ExportAuftragDialog from './ExportAuftragDialog';
import {
  getSubmoduleLayout,
  loadObject,
  loadObjects,
  getObjectParentChain,
  saveObject,
  saveMultipleObjects,
  getScreenItems,
  getObjectsChildrenTableData,
  getPlaningToolData,
  clearActiveObject,
  resetActiveObject,
  setActiveObjectValue
} from '../store/objectSlice';
import { addHistoryItem } from '../store/moduleSlice';
import { loadSubmoduleTemplatesList } from '../store/exportSlice';
import { selectTranslations } from '../store/languageSlice';
import './ObjectView.scss';

const INPUT_ITEM_TYPES = [
  'NeoTextInput',
  'NeoTextArea',
  'NeoAutoTextInput',
  'NeoAutoShowTextInput',
  'NeoFormularTextInput',
  'NeoKeyGenerator',
  'NeoLinkedCombobox',
  'NeoDateField'
];

class ObjectView extends React.PureComponent {
  state = {
    showExportAuftragDialog: false
  };

  componentDidMount() {
    this._loadSubmodule();
    this._loadScreen();
    this._loadObject();
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      globalFiltering,
      object,
      moduleId,
      submoduleId,
      objectId,
      objectIds,
      screenId,
      screenItems,
      activeObject,
      createdDraft,
      duplicate,
      draftParentPath,
      bulkEditParentPath,
      prevPath,
      objectSavedAt,
      objectLoadedAt,
      screenLoadedAt,
      fileUploadedAt,
      objectsDeletedAt,
      isBulkEditMode,
      isSavingChildObjectInline,
      clearActiveObject,
      getObjectParentChain
    } = this.props;

    if (submoduleId !== prevProps.submoduleId) {
      this._loadSubmodule();
    }

    if (screenId !== prevProps.screenId) {
      this._loadScreen();
    }

    if (
      objectId !== prevProps.objectId ||
      (isBulkEditMode && !isEqual(objectIds, prevProps.objectIds)) ||
      globalFiltering !== prevProps.globalFiltering
    ) {
      clearActiveObject({ preserveScreenItems: true });

      this._loadObject();
    }

    // when the object or screen is loaded, load table data and parent chain
    if (
      !isBulkEditMode &&
      !createdDraft &&
      (prevProps.objectLoadedAt !== objectLoadedAt ||
        prevProps.screenLoadedAt !== screenLoadedAt) &&
      activeObject.id &&
      (!prevProps.activeObject.id ||
        prevProps.activeObject.id === activeObject.id) &&
      !isEmpty(screenItems)
    ) {
      this._loadTableData();
      getObjectParentChain({ objectId: activeObject.id });
    }

    // when objects in a table are deleted or saved, reload object (and thereby
    // the table data)
    if (
      (prevProps.isSavingChildObjectInline && !isSavingChildObjectInline) ||
      prevProps.fileUploadedAt !== fileUploadedAt ||
      prevProps.objectsDeletedAt !== objectsDeletedAt
    ) {
      this._loadObject();
    }

    // when a new draft object is created, open it
    if (createdDraft && prevProps.createdDraft !== createdDraft) {
      const { moduleId, submoduleId, objectId } = createdDraft;

      history.push(getObjectViewPath(moduleId, submoduleId, objectId));
    }

    // when a draft object is saved, go back to the parent
    if (prevProps.object?.isDraft && !object?.isDraft && draftParentPath) {
      history.push(draftParentPath);
    }

    // when this object is duplicated, open the duplicate
    if (duplicate && prevProps.duplicate !== duplicate) {
      const { moduleId, submoduleId, objectId } = duplicate;

      history.push(getObjectViewPath(moduleId, submoduleId, objectId));
    }

    if (objectSavedAt && prevProps.objectSavedAt !== objectSavedAt) {
      if (isBulkEditMode) {
        // go back to previous object
        history.push(bulkEditParentPath || prevPath || '/');
      } else {
        // load newly created object after saving
        if (activeObject.id && prevProps.activeObject.id !== activeObject.id) {
          history.push(
            getObjectViewPath(moduleId, submoduleId, activeObject.id)
          );
        }

        // show Export Auftrag dialog
        const task = find(object?.tasks, { actionType: 'exportAndMail' });

        if (task && !isEmpty(task.params)) {
          this.setState({ showExportAuftragDialog: true });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearActiveObject();
  }

  render() {
    const {
      screens,
      items,
      translations,
      screenId,
      objectId,
      module,
      submodule,
      object,
      parentChain,
      screensArray,
      screenItems: screenItemIds,
      itemsByScreen,
      isBulkEditMode,
      isDirty,
      isLoadingScreenOrSubmodule,
      isSaving
    } = this.props;
    const { showExportAuftragDialog } = this.state;
    const isDebugMode = false;
    const isNewObject = objectId === '0' || object?.isDraft;
    const title = isBulkEditMode
      ? translations.edit_selected_objects
      : object &&
        submodule &&
        (object.name ? `${object.name} | ${submodule.name}` : submodule.name);
    const screenItems = map(screenItemIds, (id) => items[id]);
    let filteredItems = isDebugMode
      ? screenItems
      : filter(screenItems, 'visible');

    if (isBulkEditMode) {
      filteredItems = map(
        filter(
          filteredItems,
          ({ flexClass }) =>
            ![
              'NeoCalc',
              'NeoFormulaTable',
              'NeoFileList',
              'NeoPlaningTool'
            ].includes(flexClass)
        ),
        (item) => ({ ...item, required: false })
      );
    }

    let disableSave = false;

    forEach(screensArray, (screenId) => {
      forEach(itemsByScreen[screenId], (itemId) => {
        const item = items[itemId];

        if (!item) {
          return;
        }

        const { flexClass, required } = item;

        if (
          !isBulkEditMode &&
          required &&
          INPUT_ITEM_TYPES.includes(flexClass) &&
          isEmpty(this._getItemValue(item).value)
        ) {
          disableSave = true;
          return false;
        }
      });

      if (disableSave) {
        return false;
      }
    });

    return (
      <div className="object-view">
        {title && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
        <header>
          {module && submodule && parentChain ? (
            <div className="breadcrumbs">
              <Link to={getObjectListingPath(module.id)}>{module.name}</Link> :{' '}
              {map(parentChain, (parent) => (
                <span key={parent.id}>
                  <Link
                    to={getObjectViewPath(
                      parent.submodule.fk_module_id,
                      parent.submodule.id,
                      parent.id
                    )}
                  >
                    {parent.name}
                  </Link>{' '}
                  :{' '}
                </span>
              ))}
              <span className="current-object-breadcrumb">
                {submodule.name}
              </span>
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
          {object && object.name ? (
            <h2>
              {object.templateId && object.templateName
                ? `(Vorlage) ${object.templateName}`
                : object.name}
            </h2>
          ) : isBulkEditMode ? (
            translations.edit_selected_objects
          ) : isNewObject ? (
            <h2>({translations.new_object})</h2>
          ) : (
            <h2>&nbsp;</h2>
          )}
        </header>
        <div className="tabs-container">
          <Tabs
            value={screenId}
            variant="scrollable"
            scrollButtons="auto"
            onChange={this._onScreenTabSelected}
          >
            {map(screensArray, (screenId) => (
              <Tab
                key={screenId}
                value={screenId}
                label={screens[screenId].name}
              />
            ))}
          </Tabs>
          {(isDirty || object?.templateId) && (
            <div className="cta-container">
              <>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<ClearIcon />}
                  disabled={isSaving}
                  onClick={this._onDiscardChanges}
                >
                  {translations.cancel}
                </Button>
                {disableSave ? (
                  <Tooltip
                    title={translations.save_object_failed_missing_required}
                    arrow
                  >
                    <span>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        startIcon={<CheckIcon />}
                        disabled
                      >
                        {translations.save}
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<CheckIcon />}
                    disabled={isSaving}
                    onClick={this._onSave}
                  >
                    {translations.save}
                  </Button>
                )}
              </>
            </div>
          )}
        </div>
        {!isLoadingScreenOrSubmodule && (
          <div className="items-container">
            {map(filteredItems, (item) => (
              <ItemView
                key={item.id}
                item={item}
                value={this._getItemValue(item)}
                isNewObject={isNewObject}
                isDebugMode={isDebugMode}
                onChange={this._onItemValueChanged}
              />
            ))}
            {!isBulkEditMode && <ObjectInfo />}
          </div>
        )}
        {showExportAuftragDialog && (
          <ExportAuftragDialog onClose={this._onCloseExportAuftrag} />
        )}
      </div>
    );
  }

  _loadSubmodule = () => {
    const { submoduleId, getSubmoduleLayout, loadSubmoduleTemplatesList } =
      this.props;

    if (submoduleId) {
      getSubmoduleLayout({ submoduleId });
      loadSubmoduleTemplatesList({ submoduleId });
    }
  };

  _loadScreen = () => {
    const { submoduleId, screenId, getScreenItems } = this.props;

    if (screenId) {
      getScreenItems({ submoduleId, screenId });
    }
  };

  _loadObject = () => {
    const {
      moduleId,
      submoduleId,
      objectId,
      objectIds,
      isBulkEditMode,
      addHistoryItem,
      loadObject,
      loadObjects,
      getObjectParentChain
    } = this.props;

    if (objectId && objectId !== '0') {
      addHistoryItem({ moduleId, submoduleId, objectId });
      loadObject({ objectId });
      getObjectParentChain({ objectId });
    } else if (isBulkEditMode) {
      loadObjects({ objectIds });
    }
  };

  _loadTableData = () => {
    const {
      items,
      submodule,
      submoduleId,
      objectId,
      valueByProperty,
      screenItems,
      getObjectsChildrenTableData,
      getPlaningToolData
    } = this.props;

    forEach(screenItems, (itemId) => {
      const item = items[itemId];

      switch (item?.flexClass) {
        case 'NeoCalc':
        case 'NeoFormulaTable':
        case 'NeoFileList':
          const dependentValues = getDependentValues(
            submodule,
            item.dataSource,
            items,
            valueByProperty
          );
          getObjectsChildrenTableData({ item, objectId, dependentValues });
          break;

        case 'NeoPlaningTool':
          getPlaningToolData({ item, submoduleId, objectId });
          break;

        default:
      }
    });
  };

  _getItemValue = (item) => {
    const { valueByProperty } = this.props;

    return get(valueByProperty, item.property, { value: null });
  };

  _onScreenTabSelected = (e, value) => {
    const {
      history,
      moduleId,
      submoduleId,
      objectId,
      objectIds,
      isBulkEditMode
    } = this.props;
    const path = isBulkEditMode
      ? getBulkEditPath(moduleId, submoduleId, objectIds, value)
      : getObjectViewPath(moduleId, submoduleId, objectId, value);

    history.push(path);
  };

  _onItemValueChanged = (item, value, selectedOption = null) => {
    if (item.property) {
      this.props.setActiveObjectValue({
        propertyId: item.property,
        value,
        selectedOption
      });
    }
  };

  _onDiscardChanges = () => {
    const {
      history,
      object,
      values,
      isBulkEditMode,
      draftParentPath,
      bulkEditParentPath,
      prevPath,
      resetActiveObject
    } = this.props;

    if (isBulkEditMode) {
      history.push(bulkEditParentPath || prevPath || '/');
    } else if (object?.isDraft) {
      history.push(draftParentPath || prevPath || '/');
    } else {
      resetActiveObject({ object, values });
    }
  };

  _onSave = () => {
    const {
      objectIds,
      submoduleId,
      valueByProperty,
      isBulkEditMode,
      saveObject,
      saveMultipleObjects
    } = this.props;

    if (isBulkEditMode) {
      saveMultipleObjects({ objectIds, valueByProperty });
    } else {
      saveObject({ submoduleId, valueByProperty });
    }
  };

  _onCloseExportAuftrag = (e, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      this.setState({ showExportAuftragDialog: false });
    }
  };
}

function mapStateToProps(state, ownProps) {
  const {
    match: {
      params: {
        moduleId,
        submoduleId,
        objectId,
        objectIds: commaSeparatedObjectIds,
        screenId
      }
    }
  } = ownProps;
  const {
    entities: { modules, submodules, objects, screens, items, values },
    app: { globalFiltering },
    route: { prevPath },
    object: {
      activeObject,
      activeObject: {
        isDirty,
        valueByProperty,
        parentChain,
        screenItems,
        duplicate,
        savedAt: objectSavedAt,
        loadedAt: objectLoadedAt,
        screenLoadedAt,
        fileUploadedAt,
        objectsDeletedAt
      },
      itemsByScreen,
      createdDraft,
      draftParentPath,
      bulkEditParentPath
    },
    request: { pending }
  } = state;
  const objectIds = compact(split(commaSeparatedObjectIds, ','));
  const isBulkEditMode = !isEmpty(objectIds);
  const module = modules[moduleId];
  const submodule = submodules[submoduleId];
  const object = objects[objectId];
  const screensArray = submodule?.screensArray;
  const effectiveScreenId = screenId || (screensArray && screensArray[0]);
  const screen = effectiveScreenId && screens[effectiveScreenId];
  const isLoadingScreenOrSubmodule = !!(
    pending.getScreenItems ||
    pending.getSubmoduleLayout ||
    pending.loadSubmoduleTemplatesList
  );
  const isSavingChildObjectInline = !!pending.saveChildObjectInline;
  const isSaving = !!pending.saveObject || !!pending.saveMultipleObjects;

  return {
    screens,
    items,
    values,
    translations: selectTranslations(state),
    globalFiltering,
    activeObject,
    moduleId,
    submoduleId,
    objectId,
    objectIds,
    screenId: effectiveScreenId,
    module,
    submodule,
    object,
    screenItems,
    screensArray,
    itemsByScreen,
    screen,
    isBulkEditMode,
    isDirty,
    valueByProperty,
    parentChain,
    createdDraft,
    duplicate,
    draftParentPath,
    bulkEditParentPath,
    prevPath,
    objectSavedAt,
    objectLoadedAt,
    screenLoadedAt,
    fileUploadedAt,
    objectsDeletedAt,
    isLoadingScreenOrSubmodule,
    isSavingChildObjectInline,
    isSaving
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSubmoduleLayout,
    loadSubmoduleTemplatesList,
    loadObject,
    loadObjects,
    getObjectParentChain,
    saveObject,
    saveMultipleObjects,
    addHistoryItem,
    getScreenItems,
    getObjectsChildrenTableData,
    getPlaningToolData,
    clearActiveObject,
    resetActiveObject,
    setActiveObjectValue
  })(ObjectView)
);

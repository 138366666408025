import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

class CheckboxItem extends React.PureComponent {
  componentDidMount() {
    this._setDefault();
  }

  componentDidUpdate(prevProps) {
    const { isNewObject } = this.props;

    if (!prevProps.isNewObject && isNewObject) {
      this._setDefault();
    }
  }

  render() {
    const { item, value, onChange } = this.props;

    return (
      <Checkbox
        checked={value.value === '1'}
        color="primary"
        onChange={(e) => onChange(item, e.target.checked ? '1' : '0')}
      />
    );
  }

  _setDefault = () => {
    const { item, isNewObject, onChange } = this.props;

    if (isNewObject && item.checked === '1') {
      onChange(item, item.checked);
    }
  };
}

export default CheckboxItem;

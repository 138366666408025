import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty, findIndex } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getObjectViewPath } from '../constants/paths';
import { selectTranslations } from '../store/languageSlice';

class ObjectInfo extends React.PureComponent {
  render() {
    const {
      translations,
      submoduleId,
      screenId,
      objectId,
      updatorName,
      updatedAt,
      currentTableRows
    } = this.props;
    const activeObjectIndex = findIndex(
      currentTableRows,
      (row) => row.objectId === objectId
    );
    const prevObject =
      activeObjectIndex > 0 && currentTableRows[activeObjectIndex - 1];
    const nextObject =
      activeObjectIndex > -1 &&
      activeObjectIndex < currentTableRows.length - 1 &&
      currentTableRows[activeObjectIndex + 1];

    return (
      <div className="object-info-container">
        <Tooltip
          title={translations.object_info
            .replace('{0}', updatorName || '???')
            .replace('{1}', updatedAt || '???')}
          arrow
        >
          <IconButton onClick={this._onPrevObject}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        {!isEmpty(currentTableRows) && activeObjectIndex > -1 && (
          <>
            {prevObject ? (
              <Link
                to={getObjectViewPath(
                  prevObject.moduleId,
                  prevObject.submoduleId,
                  prevObject.objectId,
                  submoduleId === prevObject.submoduleId ? screenId : null
                )}
              >
                <Tooltip title={prevObject['31'] || ''} arrow>
                  <IconButton>
                    <ChevronLeftIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Link>
            ) : (
              <IconButton disabled>
                <ChevronLeftIcon />
              </IconButton>
            )}
            {nextObject ? (
              <Link
                to={getObjectViewPath(
                  nextObject.moduleId,
                  nextObject.submoduleId,
                  nextObject.objectId,
                  submoduleId === nextObject.submoduleId ? screenId : null
                )}
              >
                <Tooltip title={nextObject['31'] || ''} arrow>
                  <IconButton>
                    <ChevronRightIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Link>
            ) : (
              <IconButton disabled>
                <ChevronRightIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    match: {
      params: { submoduleId, objectId, screenId }
    }
  } = ownProps;
  const {
    entities: { submodules, objects, users },
    object: { currentTableRows }
  } = state;
  const submodule = submodules[submoduleId];
  const object = objects[objectId];
  const updatorName =
    object && users[object.updator || object.creator]?.username;
  const updatedAt = object?.updatedAt || object?.createdAt;
  const screensArray = submodule?.screensArray;
  const effectiveScreenId = screenId || (screensArray && screensArray[0]);

  return {
    translations: selectTranslations(state),
    objectId,
    screenId: effectiveScreenId,
    submoduleId,
    updatorName,
    updatedAt,
    currentTableRows
  };
}

export default withRouter(connect(mapStateToProps)(ObjectInfo));

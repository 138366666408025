import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { denormalize } from 'normalizr';
import {
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from '../constants/notificationTypes';
import { callRemoteMethod } from './api/api';
import { objectSchema, printTemplateSchema } from './schemas/schemas';
import { addNotification } from './notificationSlice';

export const loadSubmoduleTemplatesList = createAsyncThunk(
  'loadSubmoduleTemplatesList',
  async ({ submoduleId }) =>
    callRemoteMethod(
      'NeoPrintService',
      'loadSubmoduleTemplatesList',
      [submoduleId],
      [printTemplateSchema],
      { submoduleId }
    )
);

export const getEmailTemplates = createAsyncThunk(
  'getEmailTemplates',
  async () =>
    callRemoteMethod('NeoPrintService', 'getEmailTemplates', null, [
      objectSchema
    ])
);

export const getEmailRecipients = createAsyncThunk(
  'getEmailRecipients',
  async ({ objectId }) =>
    callRemoteMethod(
      'NeoPrintService',
      'getEmailRecipients',
      [objectId],
      null,
      { objectId }
    )
);

export const exportAndDownload = createAsyncThunk(
  'exportAndDownload',
  async ({ exportRequest }) =>
    callRemoteMethod('NeoPrintService', 'export', [exportRequest])
);

export const exportAndMail = createAsyncThunk(
  'exportAndMail',
  async (
    { exportRequest, emailRecipients, emailTemplateId },
    { getState, dispatch }
  ) => {
    const {
      entities,
      entities: { objects }
    } = getState();
    const emailTemplate = denormalize(
      objects[emailTemplateId],
      objectSchema,
      entities
    );
    const response = await callRemoteMethod(
      'NeoPrintService',
      'exportAndMail',
      [[exportRequest], emailRecipients, emailTemplate]
    );
    dispatch(
      addNotification(
        response.result === true
          ? {
              type: NOTIFICATION_TYPE_SUCCESS,
              message: `${
                emailRecipients?.length === 1 ? 'E-Mail' : 'E-Mails'
              } gesendet`
            }
          : {
              type: NOTIFICATION_TYPE_WARNING,
              message: `${
                emailRecipients?.length === 1 ? 'E-Mail' : 'E-Mails'
              } konnten nicht gesendet werden`
            }
      )
    );

    return response;
  }
);

export const exportAuftrags = createAsyncThunk(
  'exportAuftrags',
  async ({ auftragIds }) =>
    callRemoteMethod('NeoPrintService', 'exportAuftrags', [auftragIds])
);

export const emailAuftrags = createAsyncThunk(
  'emailAuftrags',
  async ({ auftragIds, emailTemplateId }, { getState, dispatch }) => {
    const {
      entities,
      entities: { objects }
    } = getState();
    const emailTemplate = denormalize(
      objects[emailTemplateId],
      objectSchema,
      entities
    );

    const response = await callRemoteMethod(
      'NeoPrintService',
      'emailAuftrags',
      [auftragIds, emailTemplate]
    );
    dispatch(
      addNotification(
        isEmpty(response.result)
          ? {
              type: NOTIFICATION_TYPE_SUCCESS,
              message: `E-Mails gesendet`
            }
          : {
              type: NOTIFICATION_TYPE_WARNING,
              message: `E-Mails konnten nicht gesendet werden`
            }
      )
    );

    return response;
  }
);

const initialState = {
  /*
    exportRequest = {
      type: 'email' | 'download'
      exportSource: 'printSourceIsTable' | 'printSourceIsObject'
      outputFormat: 'OUTPUT_CSV' | 'OUTPUT_PDF' | 'OUTPUT_XLS' | 'OUTPUT_HTML' | 'OUTPUT_PLAIN_CSV'
      orientation: 'ORIENTATION_PORTRAIT' | 'ORIENTATION_LANDSCAPE',
      objectId,
      templateId,
      tablesData: [
        {
          columnData: [
            {
              headerText: <string>,
              dataField: <propertyId>,
              formatter: ?
            },
            ...
          ],
          rowData: [
            {
              <propertyId1>: <value1>,
              <propertyId2>: <value2>,
              ...
            },
            ...
          ]
        },
        ...
      ]
    }
  */
  exportRequest: null,
  lastDownloadURL: null,
  lastEmailsSentAt: null,
  lastEmailsSuccessful: true,
  emailAuftragFailures: [],
  printTemplatesBySubmodule: {},
  emailTemplates: [],
  emailRecipientsByObject: {}
};

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    createExportRequest: (state, action) => {
      state.exportRequest = action.payload;
    },
    updateExportRequest: (state, action) => {
      state.exportRequest = {
        ...state.exportRequest,
        ...action.payload
      };
    },
    clearExportRequest: (state, action) => {
      state.exportRequest = initialState.exportRequest;
    },
    resetAuftragExport: (state, action) => {
      state.lastDownloadURL = initialState.lastDownloadURL;
      state.lastEmailsSentAt = initialState.lastEmailsSentAt;
      state.lastEmailsSuccessful = initialState.lastEmailsSuccessful;
      state.emailAuftragFailures = initialState.emailAuftragFailures;
    }
  },
  extraReducers: {
    [loadSubmoduleTemplatesList.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { submoduleId }
      } = action.payload;

      state.printTemplatesBySubmodule[submoduleId] = result;
    },
    [getEmailTemplates.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.emailTemplates = result;
    },
    [getEmailRecipients.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { objectId }
      } = action.payload;

      state.emailRecipientsByObject = { [objectId]: result };
    },
    [exportAndDownload.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.lastDownloadURL = result;
    },
    [exportAndMail.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.lastEmailsSentAt = Date.now();
      state.lastEmailsSuccessful = result;
    },
    [exportAuftrags.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.lastDownloadURL = result;
      state.lastEmailsSentAt = initialState.lastEmailsSentAt;
      state.lastEmailsSuccessful = initialState.lastEmailsSuccessful;
      state.emailAuftragFailures = initialState.emailAuftragFailures;
    },
    [emailAuftrags.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.lastEmailsSentAt = Date.now();
      state.lastEmailsSuccessful = isEmpty(result);
      state.emailAuftragFailures = result;
    }
  }
});

export const {
  createExportRequest,
  updateExportRequest,
  clearExportRequest,
  resetAuftragExport
} = exportSlice.actions;

export default exportSlice.reducer;

import React from 'react';
import { connect } from 'react-redux';
import { map, forEach, isEmpty } from 'lodash';
import { NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import HomeIcon from '@material-ui/icons/Home';
import StarIcon from '@material-ui/icons/Star';
import HistoryIcon from '@material-ui/icons/History';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getObjectListingPath,
  getFavoritesPath,
  getHistoryPath,
  getTemplatesPath,
  getUsersPath,
  getUserGroupsPath,
  getPermissionsPath
} from '../constants/paths';
import { parseObjectListingPath } from '../utils/PathUtils';
import CreateTemplateDialog from './CreateTemplateDialog';
import DeleteDialog from './DeleteDialog';
import { logOut } from '../store/sessionSlice';
import { selectTranslations } from '../store/languageSlice';
import {
  changeObjectFavoriteStatus,
  deleteTemplate,
  selectTableConfig
} from '../store/moduleSlice';
import './Sidebar.scss';

class Sidebar extends React.PureComponent {
  state = {
    deleteTemplateRequested: false,
    createTemplateRequested: false,
    areModulesExpanded: true,
    areParametersExpanded: true
  };

  componentDidUpdate(prevProps) {
    const { history, selectedModule, templateDeletedAt } = this.props;

    if (
      templateDeletedAt &&
      templateDeletedAt !== prevProps.templateDeletedAt
    ) {
      this._onCloseDeleteTemplateConfirmation();
      history.push(getTemplatesPath(selectedModule));
    }
  }

  render() {
    const {
      translations,
      user,
      modules,
      parameters,
      moduleTableConfigs,
      selectedModule,
      selectedTableConfig,
      object,
      isDeletingTemplate
    } = this.props;
    const {
      deleteTemplateRequested,
      createTemplateRequested,
      areModulesExpanded,
      areParametersExpanded
    } = this.state;

    return (
      <div className="sidebar">
        <div className="top">
          <ul>
            <li>
              <NavLink className="icon-link" to="/" exact>
                <HomeIcon fontSize="small" color="primary" />
                <span className="link-text">{translations.home}</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="icon-link" to={getFavoritesPath()}>
                <StarIcon fontSize="small" color="primary" />
                <span className="link-text">
                  {translations.favorites}
                  {object && !object.templateId && (
                    <Button
                      className="icon-button"
                      color="primary"
                      variant="contained"
                      onClick={this._onToggleFavorite}
                    >
                      {object.favorite ? (
                        <RemoveIcon fontSize="small" />
                      ) : (
                        <AddIcon fontSize="small" />
                      )}
                    </Button>
                  )}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="icon-link" to={getHistoryPath()}>
                <HistoryIcon fontSize="small" color="primary" />
                <span className="link-text">{translations.history}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="icon-link"
                to={getTemplatesPath(selectedModule)}
              >
                <AssignmentIcon fontSize="small" color="primary" />
                <span className="link-text">
                  {translations.templates}
                  {object && (
                    <Button
                      className="icon-button"
                      color="primary"
                      variant="contained"
                      onClick={this._onToggleTemplate}
                    >
                      {object.templateId ? (
                        <RemoveIcon fontSize="small" />
                      ) : (
                        <AddIcon fontSize="small" />
                      )}
                    </Button>
                  )}
                </span>
              </NavLink>
            </li>
            <li>
              <a className="icon-link" href="/" onClick={this._onLogoutClicked}>
                <PowerSettingsNewIcon fontSize="small" color="error" />
                <span className="link-text">{translations.log_out}</span>
              </a>
            </li>
          </ul>
          {!isEmpty(modules) && (
            <div className="module-lists">
              <Button
                className="sidebar-section-header"
                color="secondary"
                variant="contained"
                endIcon={
                  areModulesExpanded ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )
                }
                fullWidth
                onClick={this._onModulesHeaderClicked}
              >
                {translations.modules}
              </Button>
              <Collapse in={areModulesExpanded}>
                <ul>{map(modules, this._renderModuleLink)}</ul>
              </Collapse>
              <Button
                className="sidebar-section-header"
                color="primary"
                variant="contained"
                endIcon={
                  areParametersExpanded ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )
                }
                fullWidth
                onClick={this._onParametersHeaderClicked}
              >
                {translations.parameters}
              </Button>
              <Collapse in={areParametersExpanded}>
                <ul>
                  {user.isAdmin && (
                    <>
                      <li>
                        <NavLink to={getUsersPath()}>
                          {translations.user}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={getUserGroupsPath()}>
                          {translations.user_group}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={getPermissionsPath()}>
                          {translations.permissions}
                        </NavLink>
                      </li>
                    </>
                  )}
                  {map(parameters, this._renderModuleLink)}
                </ul>
              </Collapse>
              {!isEmpty(moduleTableConfigs) && !object && (
                <ul className="table-config-list">
                  {map(moduleTableConfigs, (tableConfig) => (
                    <li key={tableConfig.id}>
                      <a
                        className={classnames({
                          active: tableConfig.id === selectedTableConfig
                        })}
                        data-id={tableConfig.id}
                        href="/"
                        onClick={this._onTableConfigSelected}
                      >
                        {tableConfig.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <img
          className="logo"
          src="/ui-assets/logo_small.png"
          alt="expert logo"
        />
        <DeleteDialog
          open={deleteTemplateRequested}
          inProgress={isDeletingTemplate}
          onClose={this._onCloseDeleteTemplateConfirmation}
          onConfirm={this._onConfirmDeleteTemplate}
        />
        {createTemplateRequested && (
          <CreateTemplateDialog
            object={object}
            onClose={this._onCloseCreateTemplate}
          />
        )}
      </div>
    );
  }

  _renderModuleLink = (module) => {
    return (
      <li key={module.id}>
        <NavLink to={getObjectListingPath(module.id)}>{module.name}</NavLink>
      </li>
    );
  };

  _onToggleFavorite = (e) => {
    e.preventDefault();

    this.props.changeObjectFavoriteStatus();
  };

  _onToggleTemplate = (e) => {
    const {
      object: { templateId }
    } = this.props;

    e.preventDefault();

    if (templateId) {
      this.setState({ deleteTemplateRequested: true });
    } else {
      this.setState({ createTemplateRequested: true });
    }
  };

  _onCloseDeleteTemplateConfirmation = () => {
    this.setState({ deleteTemplateRequested: false });
  };

  _onConfirmDeleteTemplate = () => {
    const {
      object: { id, templateId },
      deleteTemplate
    } = this.props;

    deleteTemplate({ templateId, objectId: id });
  };

  _onCloseCreateTemplate = () => {
    this.setState({ createTemplateRequested: false });
  };

  _onLogoutClicked = (e) => {
    e.preventDefault();

    this.props.logOut();
  };

  _onModulesHeaderClicked = () => {
    const { areModulesExpanded } = this.state;

    this.setState({ areModulesExpanded: !areModulesExpanded });
  };

  _onParametersHeaderClicked = () => {
    const { areParametersExpanded } = this.state;

    this.setState({ areParametersExpanded: !areParametersExpanded });
  };

  _onTableConfigSelected = (e) => {
    e.preventDefault();

    const { id: tableConfigId } = e.currentTarget.dataset;

    this.props.selectTableConfig({ tableConfigId });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    location: { pathname }
  } = ownProps;
  const {
    entities: { users, modules: moduleEntities, objects, tableConfigs },
    session: { user: userId },
    module: {
      moduleIds,
      tableConfigsByModule,
      selectedModule,
      selectedTableConfig,
      templateDeletedAt
    },
    request: { pending }
  } = state;
  const user = users[userId];
  let modules = [];
  let parameters = [];

  forEach(moduleIds, (id) => {
    const module = moduleEntities[id];

    if (module.parameter) {
      parameters.push(module);
    } else {
      modules.push(module);
    }
  });

  const moduleTableConfigs =
    selectedModule &&
    map(tableConfigsByModule[selectedModule], (id) => tableConfigs[id]);
  const { objectId } = parseObjectListingPath(pathname);
  const object = objectId && objects[objectId];
  const isDeletingTemplate = !!pending.deleteTemplate;

  return {
    translations: selectTranslations(state),
    user,
    modules,
    parameters,
    moduleTableConfigs,
    selectedModule,
    selectedTableConfig,
    object,
    templateDeletedAt,
    isDeletingTemplate
  };
}

export default withRouter(
  connect(mapStateToProps, {
    changeObjectFavoriteStatus,
    deleteTemplate,
    logOut,
    selectTableConfig
  })(Sidebar)
);

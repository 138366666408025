import React from 'react';
import { connect } from 'react-redux';
import { Switch as RouterSwitch, Route, withRouter } from 'react-router-dom';
import ObjectListing from './ObjectListing';
import ObjectView from './ObjectView';
import Users from './Users';
import UserGroups from './UserGroups';
import Permissions from './Permissions';
import Home from './Home';
import Toolbar from './Toolbar';
import {
  getObjectListingPath,
  getObjectViewPath,
  getBulkEditPath,
  getSearchPath,
  getFavoritesPath,
  getHistoryPath,
  getTemplatesPath,
  getUsersPath,
  getUserGroupsPath,
  getPermissionsPath,
  getHomePath
} from '../constants/paths';
import { setCurrentPath } from '../store/routeSlice';
import './Main.scss';

class Main extends React.PureComponent {
  componentDidMount() {
    const { location, setCurrentPath } = this.props;

    setCurrentPath(location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { location, setCurrentPath } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      setCurrentPath(location.pathname);
    }
  }

  render() {
    return (
      <div className="main">
        <Toolbar />
        <RouterSwitch>
          <Route
            path={[
              getObjectViewPath(
                ':moduleId',
                ':submoduleId',
                ':objectId',
                ':screenId'
              ),
              getObjectViewPath(':moduleId', ':submoduleId', ':objectId'),
              getBulkEditPath(
                ':moduleId',
                ':submoduleId',
                [':objectIds'],
                ':screenId'
              ),
              getBulkEditPath(':moduleId', ':submoduleId', [':objectIds'])
            ]}
          >
            <ObjectView />
          </Route>
          <Route
            path={[
              getObjectListingPath(':moduleId', ':submoduleId'),
              getObjectListingPath(':moduleId')
            ]}
          >
            <ObjectListing isSubmoduleListing />
          </Route>
          <Route path={getSearchPath(':moduleId')}>
            <ObjectListing />
          </Route>
          <Route path={getFavoritesPath()}>
            <ObjectListing isFavorites />
          </Route>
          <Route path={getHistoryPath()}>
            <ObjectListing isHistory />
          </Route>
          <Route path={getTemplatesPath(':moduleId')}>
            <ObjectListing isTemplates />
          </Route>
          <Route path={getUsersPath()}>
            <Users />
          </Route>
          <Route path={getUserGroupsPath()}>
            <UserGroups />
          </Route>
          <Route path={getPermissionsPath()}>
            <Permissions />
          </Route>
          <Route path={getHomePath()}>
            <Home />
          </Route>
        </RouterSwitch>
      </div>
    );
  }
}

export default withRouter(connect(null, { setCurrentPath })(Main));

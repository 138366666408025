import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdvancedSelect from '../controls/AdvancedSelect';
import { selectTranslations } from '../store/languageSlice';
import { duplicateObject } from '../store/objectSlice';
import './ExportDialog.scss';

class DuplicateDialog extends React.PureComponent {
  state = {
    depth: 0,
    includeOrders: false,
    includeJobs: false
  };

  render() {
    const { translations, open, submoduleId, onClose } = this.props;
    const { depth, includeOrders, includeJobs } = this.state;

    return (
      <Dialog className="export-dialog" open={open} onClose={onClose}>
        <DialogTitle>{translations.duplicate}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translations.duplicate_linked_objects_confirmation}
          </DialogContentText>
          <DialogContentText>{translations.depth}</DialogContentText>
          <div className="input-row text-field-container">
            <AdvancedSelect
              options={[
                {
                  label: translations.none,
                  value: 0
                },
                {
                  label: translations.level_1,
                  value: 1
                },
                {
                  label: translations.level_2,
                  value: 2
                },
                {
                  label: translations.level_3,
                  value: 3
                },
                {
                  label: translations.level_4,
                  value: 4
                },
                {
                  label: translations.level_5,
                  value: 5
                },
                {
                  label: translations.level_6,
                  value: 6
                }
              ]}
              value={depth}
              optionLabelField="label"
              optionValueField="value"
              onChange={this._onDepthChanged}
            />
          </div>
          {submoduleId === '121' && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeOrders}
                    color="primary"
                    onChange={this._onIncludeOrdersChanged}
                  />
                }
                label={translations.orders}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeJobs}
                    color="primary"
                    onChange={this._onIncludeJobsChanged}
                  />
                }
                label={translations.jobs}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button color="primary" onClick={this._onDuplicate}>
            {translations.duplicate}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _onDepthChanged = (depth) => {
    this.setState({ depth });
  };

  _onIncludeOrdersChanged = (e) => {
    this.setState({ includeOrders: e.target.checked });
  };

  _onIncludeJobsChanged = (e) => {
    this.setState({ includeJobs: e.target.checked });
  };

  _onDuplicate = () => {
    const { moduleId, submoduleId, objectId, onClose, duplicateObject } =
      this.props;
    const { depth, includeOrders, includeJobs } = this.state;

    duplicateObject({
      moduleId,
      submoduleId,
      objectId,
      depth,
      includeOrders,
      includeJobs
    });
    onClose();
  };
}

function mapStateToProps(state, ownProps) {
  return {
    translations: selectTranslations(state)
  };
}

export default connect(mapStateToProps, { duplicateObject })(DuplicateDialog);

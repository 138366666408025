import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { selectTranslations } from '../store/languageSlice';

class DeleteDialog extends React.PureComponent {
  render() {
    const { translations, open, inProgress, onConfirm, onClose } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{translations.delete}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translations.delete_confirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={!!inProgress} onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button color="primary" disabled={!!inProgress} onClick={onConfirm}>
            {translations.delete}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    translations: selectTranslations(state)
  };
}

export default connect(mapStateToProps)(DeleteDialog);

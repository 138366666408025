import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import {
  has,
  isEmpty,
  map,
  filter,
  forEach,
  sortBy,
  intersectionWith,
  includes,
  find,
  mapKeys,
  min,
  max,
  pickBy,
  minBy,
  maxBy,
  assign
} from 'lodash';
import { NOTIFICATION_TYPE_SUCCESS } from '../constants/notificationTypes';
import { isNumericString } from '../utils/FormatUtils';
import {
  getTableRowFromDataProviderRow,
  fillTableRow
} from '../utils/TableUtils';
import { callRemoteMethod, getEndpointURL } from './api/api';
import {
  itemSchema,
  submoduleSchema,
  objectSchema,
  wizardSchema
} from './schemas/schemas';
import { selectTranslations } from './languageSlice';
import { addNotification } from './notificationSlice';

export const loadWizardData = createAsyncThunk('loadWizardData', async () =>
  callRemoteMethod('NeoObjectService', 'loadWizardData', [true], wizardSchema)
);

export const getSubmoduleLayout = createAsyncThunk(
  'getSubmoduleLayout',
  async ({ submoduleId }) =>
    callRemoteMethod(
      'NeoModuleService',
      'getSubmoduleLayout',
      [submoduleId],
      submoduleSchema
    )
);

export const loadObject = createAsyncThunk('loadObject', async ({ objectId }) =>
  callRemoteMethod('NeoObjectService', 'loadObject', [objectId], objectSchema)
);

export const loadObjects = createAsyncThunk(
  'loadObjects',
  async ({ objectIds }) =>
    callRemoteMethod(
      'NeoObjectService',
      'loadObjects',
      [objectIds],
      [objectSchema]
    )
);

export const getObjectParentChain = createAsyncThunk(
  'getObjectParentChain',
  async ({ objectId }) =>
    callRemoteMethod('NeoObjectService', 'getObjectParentChain', [objectId])
);

export const saveObject = createAsyncThunk(
  'saveObject',
  async ({ submoduleId, valueByProperty }, { getState, dispatch }) => {
    const {
      entities,
      entities: { objects, properties, items },
      object: {
        activeObject: { id, screenItems }
      }
    } = getState();
    let object = denormalize(objects[id], objectSchema, entities);

    if (!object) {
      object = {
        id: '0',
        submodule: { id: submoduleId }
      };
    }

    const oldValuesById = mapKeys(object.values, 'id');
    const oldValuesByPropertyId = mapKeys(
      object.values,
      (value) => value.property.id
    );
    const itemsByPropertyId = mapKeys(items, 'property');
    object.values = map(valueByProperty, (value, propertyId) =>
      value.isDirty || !value.id
        ? {
            id: value.id || 0,
            value: value.value,
            object: null,
            item: itemsByPropertyId[propertyId] || null,
            dependentObjectId: value.selectedOption
              ? value.selectedOption.objectId
              : null,
            property: properties[propertyId],
            oldValue: oldValuesByPropertyId[propertyId],
            complexValue: value.selectedOption || null,
            valueType: null,
            dirty: true
          }
        : oldValuesById[value.id]
    );
    object.isDraft = false;
    object.tasks = [];

    let response;

    if (object.templateId) {
      response = await callRemoteMethod(
        'NeoObjectService',
        'saveObjectFromTemplate',
        [object],
        objectSchema
      );
    } else {
      const formulaItems = filter(
        map(screenItems, (id) => denormalize(items[id], itemSchema, entities)),
        { flexClass: 'NeoFormularTextInput' }
      );
      response = await callRemoteMethod(
        'NeoObjectService',
        'saveObject',
        [object, 0, 0, false, formulaItems],
        objectSchema
      );
    }
    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: selectTranslations(getState()).object_saved
      })
    );

    return response;
  }
);

export const saveMultipleObjects = createAsyncThunk(
  'saveMultipleObjects',
  async ({ objectIds, valueByProperty }, { getState, dispatch }) => {
    const {
      entities,
      entities: { properties, items },
      object: {
        activeObject: { screenItems }
      }
    } = getState();
    const itemsByPropertyId = mapKeys(items, 'property');
    const values = map(
      pickBy(valueByProperty, 'isDirty'),
      (value, propertyId) => ({
        value: value.value,
        item: itemsByPropertyId[propertyId] || null,
        dependentObjectId: value.selectedOption
          ? value.selectedOption.objectId
          : null,
        property: properties[propertyId]
      })
    );
    const formulaItems = filter(
      map(screenItems, (id) => denormalize(items[id], itemSchema, entities)),
      { flexClass: 'NeoFormularTextInput' }
    );
    const response = await callRemoteMethod(
      'NeoObjectService',
      'saveMultipleObjects',
      [objectIds, values, formulaItems]
    );

    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: selectTranslations(getState()).object_saved
      })
    );

    return response;
  }
);

export const saveChildObjectInline = createAsyncThunk(
  'saveChildObjectInline',
  async ({ row, itemId }, { getState }) => {
    const {
      entities,
      entities: { properties, submodules, items },
      object: {
        activeObject: { id }
      }
    } = getState();
    const { index, submoduleId, objectId } = row;
    const submodule = denormalize(
      submodules[submoduleId],
      submoduleSchema,
      entities
    );
    const item = items[itemId];
    const oldValuesByPropertyId = pickBy(
      item.dataGridDataProvider[index],
      _filterByNumKeys
    );
    const rowValues = pickBy(row, _filterByNumKeys);
    const object = {
      id: objectId,
      submodule,
      values: map(rowValues, (value, propertyId) => {
        const oldValue = oldValuesByPropertyId[propertyId];

        return {
          id: oldValue?.id,
          value: row[propertyId],
          object: null,
          item: null,
          dependentObjectId: null,
          property: properties[propertyId] || oldValue.property,
          oldValue,
          complexValue: null,
          valueType: null,
          dirty: true
        };
      })
    };

    return callRemoteMethod('NeoObjectService', 'saveChildObject', [
      object,
      id
    ]);
  }
);

export const saveChildObjectExternal = createAsyncThunk(
  'saveChildObjectExternal',
  async ({ submoduleId }, { getState }) => {
    const {
      entities,
      entities: { submodules },
      object: {
        activeObject: { id }
      }
    } = getState();
    const submodule = denormalize(
      submodules[submoduleId],
      submoduleSchema,
      entities
    );
    const object = {
      id: 0,
      submodule,
      values: [],
      isDraft: true
    };

    return callRemoteMethod('NeoObjectService', 'saveChildObject', [
      object,
      id
    ]);
  }
);

export const getScreenItems = createAsyncThunk(
  'getScreenItems',
  async ({ submoduleId, screenId }, { getState }) =>
    callRemoteMethod(
      'NeoModuleService',
      'getScreenItems',
      [screenId],
      [itemSchema],
      {
        submoduleId,
        screenId,
        lastScreenSubmoduleId: getState().object.lastScreenSubmoduleId
      }
    )
);

export const loadItemData = createAsyncThunk(
  'loadItemData',
  async ({ item, text, objectId }, { getState }) =>
    callRemoteMethod(
      'NeoObjectService',
      'loadItemData',
      [denormalize(item, itemSchema, getState().entities), text, objectId],
      itemSchema
    )
);

export const getDependentItemsValues = createAsyncThunk(
  'getDependentItemsValues',
  async ({ propertyId, selectedOption }, { getState, dispatch }) => {
    if (!selectedOption) {
      return;
    }
    
    const {
      entities,
      object: {
        activeObject: { screenItems }
      }
    } = getState();
    const { items } = entities;
    const dependentItems = map(
      filter(screenItems, (id) =>
        includes(items[id].dependentProperties, propertyId)
      ),
      (id) => denormalize(items[id], itemSchema, entities)
    );

    if (isEmpty(dependentItems)) {
      return;
    }

    const response = await callRemoteMethod(
      'NeoObjectService',
      'getDependentItemsValues',
      [dependentItems, selectedOption],
      [itemSchema]
    );
    const {
      entities: { items: responseItems },
      result
    } = response;

    forEach(result, (itemId) => {
      const { flexClass, property, value } = responseItems[itemId];

      // TODO: Figure out what to do about the values for NeoAutoTextInput and
      // NeoLinkedCombobox. Ignoring them for now.
      if (!['NeoAutoTextInput', 'NeoLinkedCombobox'].includes(flexClass)) {
        dispatch(
          setActiveObjectValue({
            propertyId: property,
            value: value?.value || ''
          })
        );
      }
    });

    return response;
  }
);

export const calculateItemValueByFormula = createAsyncThunk(
  'calculateItemValueByFormula',
  async ({ item }, { getState, dispatch }) => {
    const {
      entities,
      object: {
        activeObject: { id }
      }
    } = getState();
    const { objects } = entities;
    const object = objects[id];

    const response = await callRemoteMethod(
      'NeoObjectService',
      'calculateItemValueByFormula',
      [
        [denormalize(item, itemSchema, entities)],
        denormalize(object, objectSchema, entities)
      ],
      [itemSchema]
    );
    const {
      entities: { items: responseItems },
      result
    } = response;

    forEach(result, (itemId) => {
      const item = responseItems[itemId];
      const valueUpdateData = { propertyId: item.property };

      if (item.value) {
        valueUpdateData.value = item.value.value || '';
        valueUpdateData.valueId = item.value.id;
      }

      dispatch(setActiveObjectValue(valueUpdateData));
    });

    return response;
  }
);

export const getObjectsChildrenTableData = createAsyncThunk(
  'getObjectsChildrenTableData',
  async ({ item, objectId, dependentValues }, { getState }) =>
    callRemoteMethod(
      'NeoObjectService',
      'getObjectsChildrenTableData',
      [
        denormalize(item, itemSchema, getState().entities),
        objectId,
        dependentValues
      ],
      itemSchema
    )
);

export const getPlaningToolData = createAsyncThunk(
  'getPlaningToolData',
  async ({ item, submoduleId, objectId }, { getState }) =>
    callRemoteMethod(
      'NeoObjectService',
      'getPlaningToolData',
      [
        denormalize(item, itemSchema, getState().entities),
        submoduleId,
        objectId
      ],
      null,
      { itemId: item.id }
    )
);

export const deleteObjects = createAsyncThunk(
  'deleteObjects',
  async ({ objectIds }, { getState, dispatch }) => {
    const response = await callRemoteMethod(
      'NeoObjectService',
      'deleteObjects',
      [objectIds]
    );

    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: selectTranslations(getState()).object_deleted
      })
    );

    return response;
  }
);

export const duplicateObject = createAsyncThunk(
  'duplicateObject',
  async (
    { moduleId, submoduleId, objectId, depth, includeOrders, includeJobs },
    { dispatch }
  ) => {
    const response = await callRemoteMethod(
      'NeoObjectService',
      'duplicateObject',
      [objectId, { data: depth, offers: includeOrders, jobs: includeJobs }],
      null,
      { moduleId, submoduleId }
    );

    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: 'Objekt dupliziert'
      })
    );

    return response;
  }
);

export const uploadImage = createAsyncThunk(
  'uploadImage',
  async ({ propertyId, file }, { getState, dispatch }) => {
    const data = new FormData();
    data.append('Filedata', file);
    const imageURL = await _sendXHR(getEndpointURL('/uploadImage.php'), data);

    dispatch(setActiveObjectValue({ propertyId, value: imageURL || '' }));
  }
);

export const uploadFile = createAsyncThunk(
  'uploadFile',
  async ({ file }, { getState }) => {
    const {
      object: {
        activeObject: { id }
      }
    } = getState();
    const data = new FormData();
    data.append('parentObjectId', id);
    data.append('Filedata', file);
    const response = await _sendXHR(getEndpointURL('/upload.php'), data);

    if (response !== '1') {
      throw new Error(response);
    }
  }
);

export const runCustomProcess = createAsyncThunk(
  'runCustomProcess',
  async ({ processName, objectIds }, { getState, dispatch }) => {
    const {
      object: {
        activeObject: { id }
      }
    } = getState();

    const response = await callRemoteMethod(
      'NeoWorkflowService',
      'runCustomProcess',
      [processName, objectIds || [id]],
      [objectSchema]
    );
    dispatch(loadObject({ objectId: id }));

    return response;
  }
);

const _sendXHR = async (url, formData) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.responseText);
      } else {
        reject(xhr.status);
      }
    };

    xhr.send(formData);
  });

const _getStateFromObject = (object, values) => {
  const valueByProperty = {};

  forEach(object?.values, (valueId) => {
    const value = values[valueId];

    if (value) {
      valueByProperty[value.property] = {
        id: value.id,
        value: value.value
      };

      if (value.complexValue) {
        valueByProperty[value.property].selectedOption = value.complexValue;
      }
    }
  });

  return {
    id: object?.id || '0',
    isDirty: !!object?.isDraft,
    valueByProperty
  };
};

const _filterByNumKeys = (value, key) => isNumericString(key);

const initialState = {
  wizardData: {
    modules: [],
    submodules: [],
    templates: [],
    extraFilters: []
  },
  activeObject: {
    id: null,
    parentChain: null,
    screenItems: [],
    valueByProperty: {},
    tableRowsByItem: {},
    ganttDataByItem: {},
    isDirty: false,
    duplicate: null,
    loadedAt: null,
    savedAt: null,
    screenLoadedAt: null,
    processRunAt: null,
    fileUploadedAt: null,
    objectsDeletedAt: null
  },
  itemsByScreen: {},
  filteredRowsByItem: {},
  currentTableRows: [], // rows of the table from which the current object was opened
  createdDraft: null,
  draftParentPath: null,
  bulkEditParentPath: null,
  lastScreenSubmoduleId: null
};

const objectSlice = createSlice({
  name: 'object',
  initialState,
  reducers: {
    clearActiveObject: (state, action) => {
      state.activeObject = action.payload?.preserveScreenItems
        ? {
            ...initialState.activeObject,
            screenItems: state.activeObject.screenItems
          }
        : initialState.activeObject;
    },
    resetActiveObject: (state, action) => {
      const { object, values } = action.payload;
      const { activeObject } = state;

      assign(activeObject, _getStateFromObject(object, values));
    },
    setActiveObjectValue: (state, action) => {
      const { propertyId, valueId, value, selectedOption } = action.payload;
      const { activeObject } = state;
      const { valueByProperty } = activeObject;

      if (
        (valueByProperty[propertyId] || {}).value !== value &&
        (has(valueByProperty, propertyId) || value !== '')
      ) {
        activeObject.isDirty = true;
      }

      valueByProperty[propertyId] = {
        ...valueByProperty[propertyId],
        value,
        isDirty: true
      };

      if (valueId) {
        valueByProperty[propertyId].id = valueId;
      }

      if (selectedOption) {
        valueByProperty[propertyId].selectedOption = selectedOption;
      }
    },
    setBulkEditParentPath: (state, action) => {
      const { path } = action.payload;

      state.bulkEditParentPath = path;
    },
    updateTableRow: (state, action) => {
      const { itemId, row } = action.payload;
      const {
        activeObject: { tableRowsByItem }
      } = state;
      const rows = tableRowsByItem[itemId];
      rows[row.index] = {
        ...row,
        isDirty: true
      };
    },
    addNewTableRow: (state, action) => {
      const { itemId, moduleId, submoduleId, columnPropertyIds } =
        action.payload;
      const {
        activeObject: { tableRowsByItem }
      } = state;
      const tableRows = tableRowsByItem[itemId];

      if (!find(tableRows, { objectId: 0 })) {
        tableRows.push(
          fillTableRow(
            {
              moduleId,
              submoduleId,
              objectId: 0,
              enabled: true,
              isDirty: true
            },
            tableRows.length,
            columnPropertyIds
          )
        );
      }
    },
    resetTableRow: (state, action) => {
      const {
        row: { index, objectId },
        item: { id, dataGridDataProvider, columnPropertyIds }
      } = action.payload;
      const {
        activeObject: { tableRowsByItem }
      } = state;
      const itemRows = tableRowsByItem[id];

      if (!objectId) {
        itemRows.splice(index, 1);
      } else {
        itemRows[index] = getTableRowFromDataProviderRow(
          dataGridDataProvider[index],
          index,
          columnPropertyIds
        );
      }
    },
    setFilteredRows: (state, action) => {
      const { itemId, filteredRows } = action.payload;
      const { filteredRowsByItem } = state;

      state.filteredRowsByItem = {
        ...filteredRowsByItem,
        [itemId]: filteredRows
      };
    },
    resetFilteredRows: (state, action) => {
      state.filteredRowsByItem = initialState.filteredRowsByItem;
    },
    setCurrentTableRows: (state, action) => {
      state.currentTableRows = action.payload;
    },
    resetCurrentTableRows: (state, action) => {
      state.currentTableRows = initialState.currentTableRows;
    }
  },
  extraReducers: {
    [loadWizardData.fulfilled]: (state, action) => {
      const {
        modulesArray,
        submodulesArray,
        templatesArray,
        extraFiltersArray
      } = action.payload.result;

      state.wizardData = {
        modules: modulesArray.id,
        submodules: submodulesArray.id,
        templates: templatesArray.id,
        extraFilters: extraFiltersArray
      };
    },
    [loadObject.fulfilled]: (state, action) => {
      const {
        result,
        entities: { objects, values }
      } = action.payload;
      const { activeObject } = state;
      const object = objects[result];

      assign(activeObject, _getStateFromObject(object, values));
      activeObject.loadedAt = Date.now();

      if (state.createdDraft?.objectId !== result) {
        state.createdDraft = null;
        state.draftParentPath = null;
      }
    },
    [loadObjects.fulfilled]: (state, action) => {
      const {
        result,
        entities: { objects, values }
      } = action.payload;
      const { activeObject } = state;
      const valueByProperty = {};
      const objectValues = map(result, (id) =>
        map(objects[id].values, (id) => values[id])
      );
      const commonValues = intersectionWith(
        ...objectValues,
        (value1, value2) =>
          value1.property === value2.property && value1.value === value2.value
      );

      forEach(commonValues, (value) => {
        valueByProperty[value.property] = {
          id: value.id,
          value: value.value
        };

        if (value.complexValue) {
          valueByProperty[value.property].selectedOption = value.complexValue;
        }
      });

      activeObject.id = result[0] || '0';
      activeObject.isDirty = true;
      activeObject.valueByProperty = valueByProperty;
      activeObject.loadedAt = Date.now();
      state.createdDraft = null;
      state.draftParentPath = null;
    },
    [getObjectParentChain.fulfilled]: (state, action) => {
      let {
        result: { object: currentObject }
      } = action.payload;
      const { activeObject } = state;
      const parentChain = [];

      for (; currentObject.parent; currentObject = currentObject.parent) {
        parentChain.unshift(currentObject.parent);
      }

      activeObject.parentChain = parentChain;
    },
    [saveObject.fulfilled]: (state, action) => {
      const {
        result,
        entities: { objects, values }
      } = action.payload;
      const { activeObject } = state;
      const object = objects[result];

      assign(activeObject, _getStateFromObject(object, values));
      activeObject.loadedAt = Date.now();
      activeObject.savedAt = Date.now();
    },
    [saveMultipleObjects.fulfilled]: (state, action) => {
      const { activeObject } = state;
      activeObject.savedAt = Date.now();
    },
    [getScreenItems.fulfilled]: (state, action) => {
      const {
        result,
        entities: { items },
        requestInfo: { submoduleId, screenId }
      } = action.payload;
      const { activeObject } = state;

      activeObject.screenItems = sortBy(result, [
        (id) => items[id].y,
        (id) => items[id].x
      ]);

      state.lastScreenSubmoduleId = submoduleId;
      state.itemsByScreen[screenId] = activeObject.screenItems;
      activeObject.tableRowsByItem = {};
      activeObject.screenLoadedAt = Date.now();
    },
    [getObjectsChildrenTableData.fulfilled]: (state, action) => {
      const { result, entities } = action.payload;

      if (!result) {
        return;
      }

      const { items } = entities;
      const {
        activeObject: { tableRowsByItem }
      } = state;
      const { dataGridDataProvider, columnPropertyIds } = items[result];

      tableRowsByItem[result] = map(dataGridDataProvider, (data, index) =>
        getTableRowFromDataProviderRow(data, index, columnPropertyIds)
      );
    },
    [getPlaningToolData.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { itemId }
      } = action.payload;
      const {
        activeObject: { ganttDataByItem }
      } = state;
      let minStart;
      let maxEnd;

      forEach(result, (row) => {
        minStart = minStart
          ? min([minStart, minBy(row.segments, 'start')?.start])
          : row.segments[0]?.start;
        maxEnd = maxEnd
          ? max([maxEnd, maxBy(row.segments, 'end')?.end])
          : row.segments[0]?.end;
      });

      ganttDataByItem[itemId] = {
        rows: result,
        minStart,
        maxEnd
      };
    },
    [saveChildObjectExternal.fulfilled]: (state, action) => {
      const {
        result: {
          id,
          submodule: { id: submoduleId, fk_module_id }
        }
      } = action.payload;

      state.createdDraft = {
        moduleId: fk_module_id,
        submoduleId,
        objectId: id
      };
      state.draftParentPath = window.location.pathname;
    },
    [runCustomProcess.fulfilled]: (state, action) => {
      state.activeObject.processRunAt = Date.now();
    },
    [uploadFile.fulfilled]: (state, action) => {
      state.activeObject.fileUploadedAt = Date.now();
    },
    [deleteObjects.fulfilled]: (state, action) => {
      state.activeObject.objectsDeletedAt = Date.now();
    },
    [duplicateObject.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { moduleId, submoduleId }
      } = action.payload;

      state.activeObject.duplicate = {
        moduleId,
        submoduleId,
        objectId: result
      };
    }
  }
});

export const {
  clearActiveObject,
  resetActiveObject,
  setActiveObjectValue,
  setBulkEditParentPath,
  updateTableRow,
  addNewTableRow,
  resetTableRow,
  setFilteredRows,
  resetFilteredRows,
  setCurrentTableRows,
  resetCurrentTableRows
} = objectSlice.actions;

export const selectWizardData = (state) => {
  const {
    entities: { modules, submodules, templates },
    object: { wizardData }
  } = state;

  return {
    modules: map(wizardData.modules, (id) => modules[id]),
    submodules: map(wizardData.submodules, (id) => submodules[id]),
    templates: map(wizardData.templates, (id) => templates[id]),
    extraFilters: wizardData.extraFilters
  };
};

export default objectSlice.reducer;

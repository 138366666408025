import React from 'react';
import { isEmpty } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

class DateFieldItem extends React.PureComponent {
  render() {
    const { item, value } = this.props;
    const { formatString, required } = item;
    const adjustedFormat = formatString.replace(/D/gi, 'd').replace(/Y/gi, 'y');
    const noValue = isEmpty(value.value);
    const selectedDate = noValue ? null : new Date(value.value);

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <KeyboardDatePicker
          value={selectedDate}
          format={adjustedFormat || 'dd.MM.yyyy'}
          variant="inline"
          autoOk
          disableToolbar
          TextFieldComponent={(params) => (
            <TextField
              {...params}
              error={required && noValue}
              variant="outlined"
            />
          )}
          onChange={this._onChange}
        />
      </MuiPickersUtilsProvider>
    );
  }

  _onChange = (value) => {
    const { item, onChange } = this.props;

    if (!isNaN(value) && value instanceof Date) {
      const formattedDate = value.toISOString();

      onChange(item, formattedDate.substr(0, formattedDate.indexOf('T')));
    }
  };
}

export default DateFieldItem;

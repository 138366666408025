import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import moment from 'moment';
import 'moment/locale/de';
import numeral from 'numeral';
import 'numeral/locales/de';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme
} from '@material-ui/core/styles';
import App from './App';
import storeConfig from './store/storeConfig';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const theme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: { main: '#0686CF' },
    secondary: { main: '#F37C38' }
  }
});
moment.locale('de');
numeral.locale('de');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={storeConfig}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { checkLogin, selectSessionUser } from '../store/sessionSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    width: theme.spacing(40),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  }
}));

function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const sessionUser = useSelector(selectSessionUser);
  const classes = useStyles();

  if (sessionUser) {
    const { from } = location.state || { from: { pathname: '/' } };

    return (
      <Redirect
        to={{
          pathname: from.pathname
        }}
      />
    );
  }

  return (
    <form className={classes.container} onSubmit={(e) => e.preventDefault()}>
      <Card className={classes.card} raised>
        <label htmlFor="username-field">Benutzername</label>
        <TextField
          id="username-field"
          value={username}
          variant="outlined"
          autoFocus
          fullWidth
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password-field">Kennwort</label>
        <TextField
          id="password-field"
          value={password}
          variant="outlined"
          type="password"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={remember}
              color="primary"
              onChange={(e) => setRemember(e.target.checked)}
            />
          }
          label="Angemeldet bleiben"
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => dispatch(checkLogin({ username, password, remember }))}
        >
          Anmelden
        </Button>
      </Card>
    </form>
  );
}

export default Login;

import { getSearchPath } from '../constants/paths';

export function parseObjectListingPath(path) {
  const pathMatches = path.match(
    /module-(\d+)(\/submodule-(\d+))?(\/object-(\d+))?/
  );
  const moduleId = pathMatches && pathMatches[1];
  const submoduleId = pathMatches && pathMatches[3];
  const objectId = pathMatches && pathMatches[5];

  return {
    moduleId,
    submoduleId,
    objectId
  };
}

export function getSearchTextFromPath(location) {
  return (
    (location.pathname.startsWith(getSearchPath('')) &&
      new URLSearchParams(location.search).get('q')) ||
    null
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { map, isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdvancedSelect from '../controls/AdvancedSelect';
import { selectTranslations } from '../store/languageSlice';
import {
  loadUserList,
  loadUserGroupUsers,
  saveUserGroup
} from '../store/userSlice';
import './ExportDialog.scss';

class EditUserGroupDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      group: { name }
    } = props;

    this.state = {
      name,
      selectedGroupUsers: []
    };
  }

  componentDidMount() {
    const { users, group, loadUserList, loadUserGroupUsers } = this.props;

    if (isEmpty(users)) {
      loadUserList();
    }

    if (group.id) {
      loadUserGroupUsers({ groupId: group.id });
    }
  }

  componentDidUpdate(prevProps) {
    const { groupUsers } = this.props;

    if (groupUsers !== prevProps.groupUsers) {
      this.setState({
        selectedGroupUsers: map(groupUsers, 'id')
      });
    }
  }

  render() {
    const { translations, users, group, isLoadingUsers, onClose } = this.props;
    const { name, selectedGroupUsers } = this.state;

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="sm"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          {group.id ? 'Benutzergruppe bearbeiten' : 'Neuer Benutzergruppe'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <label htmlFor="name-field">{translations.user_group_name}</label>
          </DialogContentText>
          <div className="input-row text-field-container">
            <TextField
              id="name-field"
              value={name}
              variant="outlined"
              fullWidth
              onChange={this._onNameChange}
            />
          </div>
          <DialogContentText>{translations.user}</DialogContentText>
          <div className="input-row">
            <AdvancedSelect
              options={users}
              value={selectedGroupUsers}
              optionLabelField="username"
              multiple
              loading={isLoadingUsers}
              onChange={this._onGroupUsersChange}
            />
            <Button
              className="add-all-button"
              color="primary"
              variant="contained"
              size="small"
              onClick={this._onSelectAllUsers}
            >
              {translations.add_all}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button
            color="primary"
            disabled={!this._isFormInputValid()}
            onClick={this._onSave}
          >
            {translations.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _isFormInputValid = () => {
    const { name } = this.state;

    return !!name;
  };

  _onNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  };

  _onGroupUsersChange = (selectedGroupUsers) => {
    this.setState({
      selectedGroupUsers
    });
  };

  _onSelectAllUsers = () => {
    const { users } = this.props;

    this.setState({
      selectedGroupUsers: map(users, 'id')
    });
  };

  _onSave = () => {
    const { group, saveUserGroup, onClose } = this.props;
    const { name, selectedGroupUsers } = this.state;

    saveUserGroup({
      group: {
        ...group,
        name,
        users: map(selectedGroupUsers, (id) => ({ id }))
      }
    });
    onClose();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    user: { users, usersByGroup },
    request: { pending }
  } = state;
  const { group } = ownProps;
  const groupUsers = (group.id && usersByGroup[group.id]) || [];
  const isLoadingUsers = !!pending.loadUserGroupUsers;

  return {
    translations: selectTranslations(state),
    users,
    groupUsers,
    isLoadingUsers
  };
}

export default connect(mapStateToProps, {
  loadUserList,
  loadUserGroupUsers,
  saveUserGroup
})(EditUserGroupDialog);

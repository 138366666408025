import React from 'react';
import { connect } from 'react-redux';
import { map, isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdvancedSelect from '../controls/AdvancedSelect';
import { selectTranslations } from '../store/languageSlice';
import {
  loadUserList,
  loadUserGroupList,
  savePermission
} from '../store/userSlice';
import './ExportDialog.scss';

class EditPermissionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      permission: { users: permissionUsers, groups: permissionGroups }
    } = props;

    this.state = {
      selectedPermissionUsers: map(permissionUsers, 'id'),
      selectedPermissionGroups: map(permissionGroups, 'id')
    };
  }

  componentDidMount() {
    const { users, groups, loadUserList, loadUserGroupList } = this.props;

    if (isEmpty(users)) {
      loadUserList();
    }

    if (isEmpty(groups)) {
      loadUserGroupList();
    }
  }

  render() {
    const {
      translations,
      actions,
      users,
      groups,
      permission: { name, actionId },
      onClose
    } = this.props;
    const { selectedPermissionUsers, selectedPermissionGroups } = this.state;

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="md"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>{translations.edit_permissions}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translations.area}</DialogContentText>
          <div className="input-row">
            {name} : {actions[actionId].name}
          </div>
          <DialogContentText>{translations.user}</DialogContentText>
          <div className="input-row">
            <AdvancedSelect
              options={users}
              value={selectedPermissionUsers}
              optionLabelField="username"
              multiple
              onChange={this._onPermissionUsersChange}
            />
            <Button
              className="add-all-button"
              color="primary"
              variant="contained"
              size="small"
              onClick={this._onSelectAllUsers}
            >
              {translations.add_all}
            </Button>
          </div>
          <DialogContentText>{translations.user_group}</DialogContentText>
          <div className="input-row">
            <AdvancedSelect
              options={groups}
              value={selectedPermissionGroups}
              multiple
              onChange={this._onPermissionGroupsChange}
            />
            <Button
              className="add-all-button"
              color="primary"
              variant="contained"
              size="small"
              onClick={this._onSelectAllGroups}
            >
              {translations.add_all}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button color="primary" onClick={this._onSave}>
            {translations.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _onNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  };

  _onPermissionUsersChange = (selectedPermissionUsers) => {
    this.setState({
      selectedPermissionUsers
    });
  };

  _onSelectAllUsers = () => {
    const { users } = this.props;

    this.setState({
      selectedPermissionUsers: map(users, 'id')
    });
  };

  _onPermissionGroupsChange = (selectedPermissionGroups) => {
    this.setState({
      selectedPermissionGroups
    });
  };

  _onSelectAllGroups = () => {
    const { groups } = this.props;

    this.setState({
      selectedPermissionGroups: map(groups, 'id')
    });
  };

  _onSave = () => {
    const { permission, savePermission, onClose } = this.props;
    const { selectedPermissionUsers, selectedPermissionGroups } = this.state;

    savePermission({
      permission: {
        ...permission,
        users: map(selectedPermissionUsers, (id) => ({ id })),
        userGroups: map(selectedPermissionGroups, (id) => ({ id }))
      }
    });
    onClose();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { actions },
    user: { users, groups }
  } = state;

  return {
    translations: selectTranslations(state),
    actions,
    users,
    groups
  };
}

export default connect(mapStateToProps, {
  loadUserList,
  loadUserGroupList,
  savePermission
})(EditPermissionDialog);

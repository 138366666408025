import React from 'react';
import { connect } from 'react-redux';
import { has, map, mapKeys, mapValues, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import {
  getObjectListingPath,
  getSearchPath,
  getHomePath
} from '../constants/paths';
import {
  EXPORT_TYPE_DOWNLOAD,
  EXPORT_TYPE_EMAIL,
  EXPORT_SOURCE_OBJECT,
  EXPORT_SOURCE_TABLE,
  OUTPUT_FORMAT_PDF,
  OUTPUT_FORMAT_CSV,
  ORIENTATION_PORTRAIT
} from '../constants/exportSettings';
import {
  parseObjectListingPath,
  getSearchTextFromPath
} from '../utils/PathUtils';
import { formatCellValue } from '../utils/FormatUtils';
import AdvancedSelect from '../controls/AdvancedSelect';
import DuplicateDialog from './DuplicateDialog';
import DeleteDialog from './DeleteDialog';
import CreateObjectDialog from './CreateObjectDialog';
import ExportDialog from './ExportDialog';
import ExportAndEmailDialog from './ExportAndEmailDialog';
import { setGlobalFiltering } from '../store/appSlice';
import { selectTranslations } from '../store/languageSlice';
import { deleteObjects } from '../store/objectSlice';
import { createExportRequest } from '../store/exportSlice';

class Toolbar extends React.PureComponent {
  state = {
    objectToDelete: null,
    objectToDuplicate: null,
    showCreateObjectDialog: false,
    searchText: getSearchTextFromPath(this.props.location) || ''
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.setState({ searchText: getSearchTextFromPath(location) || '' });
    }
  }

  render() {
    const {
      translations,
      globalFiltering,
      printTemplatesBySubmodule,
      moduleId,
      submoduleId,
      objectId,
      object,
      objectListingRows,
      moduleSubmodules,
      exportRequest
    } = this.props;
    const {
      objectToDelete,
      objectToDuplicate,
      showCreateObjectDialog,
      searchText
    } = this.state;
    const isObject = object && !object.templateId;
    const isExportEnabled =
      (moduleId && !objectId && !isEmpty(objectListingRows)) ||
      (isObject && !isEmpty(printTemplatesBySubmodule[submoduleId]));

    return (
      <div className="toolbar">
        {isExportEnabled && (
          <div className="global-cta-item">
            <Tooltip title={translations.print} arrow>
              <Button
                className="icon-button"
                color="primary"
                variant="contained"
                onClick={this._onExport}
              >
                <PrintIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        )}
        {isExportEnabled && (
          <div className="global-cta-item">
            <Tooltip title={translations.email} arrow>
              <Button
                className="icon-button"
                color="primary"
                variant="contained"
                onClick={this._onEmail}
              >
                <MailIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        )}
        {isObject && (
          <div className="global-cta-item">
            <Tooltip title={translations.duplicate} arrow>
              <Button
                className="icon-button"
                color="primary"
                variant="contained"
                onClick={this._onDuplicateObject}
              >
                <AddToPhotosIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        )}
        {isObject && (
          <div className="global-cta-item">
            <Tooltip title={translations.delete_object} arrow>
              <Button
                className="icon-button"
                color="primary"
                variant="contained"
                onClick={this._onDeleteObject}
              >
                <RemoveIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        )}
        <div className="global-cta-item">
          <Tooltip title={translations.new_object} arrow>
            <Button
              className="icon-button"
              color="primary"
              variant="contained"
              onClick={this._onCreateObject}
            >
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip>
        </div>
        <div className="global-cta-item global-filter-item">
          <FormControlLabel
            control={
              <Switch
                checked={globalFiltering}
                color="primary"
                size="small"
                onChange={this._onGlobalFilterChanged}
              />
            }
            label={translations.global_filter}
            variant="button"
          />
        </div>
        <div className="global-cta-item input-item">
          <AdvancedSelect
            options={moduleSubmodules || []}
            value={
              objectId || isEmpty(moduleSubmodules)
                ? null
                : submoduleId || moduleSubmodules[0].id
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={translations.functions}
              />
            )}
            onChange={this._onSubmoduleSelected}
          />
        </div>
        <div className="global-cta-item input-item search-item">
          <TextField
            value={searchText}
            variant="outlined"
            placeholder={translations.search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="search-button"
                    onClick={this._onSearch}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
            onKeyDown={this._onSearchKeyDown}
            onChange={this._onSearchChange}
          />
        </div>
        <DuplicateDialog
          open={!!objectToDuplicate}
          moduleId={moduleId}
          submoduleId={submoduleId}
          objectId={objectId}
          onClose={this._onCloseDuplicateConfirmation}
        />
        <DeleteDialog
          open={!!objectToDelete}
          onConfirm={this._onConfirmDelete}
          onClose={this._onCloseDeleteConfirmation}
        />
        {showCreateObjectDialog && (
          <CreateObjectDialog onClose={this._onCloseCreateObjectDialog} />
        )}
        {exportRequest?.type === EXPORT_TYPE_DOWNLOAD && <ExportDialog />}
        {exportRequest?.type === EXPORT_TYPE_EMAIL && <ExportAndEmailDialog />}
      </div>
    );
  }

  _createExportRequest = (type) => {
    const {
      properties,
      printTemplatesBySubmodule,
      moduleId,
      submoduleId,
      objectId,
      objectListingRows,
      objectListingColumns,
      filteredRows,
      createExportRequest
    } = this.props;

    if (moduleId && !objectId && !isEmpty(objectListingRows)) {
      const columnsByProperty = mapKeys(objectListingColumns, 'property');
      const columnData = map(objectListingColumns, (c) => ({
        headerText: c.name,
        dataField: c.property,
        formatter: c.formatter
      }));
      const rowData = map(filteredRows, (row, rowIndex) =>
        mapValues(row, (value, propertyId) => {
          if (has(columnsByProperty, propertyId)) {
            const column = columnsByProperty[propertyId];
            const { formatter, property } = column;

            return formatCellValue(formatter, properties[property], value);
          }

          return value;
        })
      );

      createExportRequest({
        type,
        exportSource: EXPORT_SOURCE_TABLE,
        outputFormat: OUTPUT_FORMAT_CSV,
        orientation: ORIENTATION_PORTRAIT,
        objectId,
        tablesData: [
          {
            columnData,
            rowData
          }
        ]
      });
    } else {
      createExportRequest({
        type,
        exportSource: EXPORT_SOURCE_OBJECT,
        outputFormat: OUTPUT_FORMAT_PDF,
        orientation: ORIENTATION_PORTRAIT,
        objectId,
        templateId:
          printTemplatesBySubmodule[submoduleId] &&
          printTemplatesBySubmodule[submoduleId][0],
        tablesData: []
      });
    }
  };

  _onExport = () => {
    this._createExportRequest(EXPORT_TYPE_DOWNLOAD);
  };

  _onEmail = () => {
    this._createExportRequest(EXPORT_TYPE_EMAIL);
  };

  _onDuplicateObject = () => {
    const { objectId } = this.props;

    this.setState({ objectToDuplicate: objectId });
  };

  _onCloseDuplicateConfirmation = () => {
    this.setState({ objectToDuplicate: null });
  };

  _onDeleteObject = () => {
    const { objectId } = this.props;

    this.setState({ objectToDelete: objectId });
  };

  _onCloseDeleteConfirmation = () => {
    this.setState({ objectToDelete: null });
  };

  _onConfirmDelete = () => {
    const { history, prevPath, deleteObjects } = this.props;
    const { objectToDelete } = this.state;

    this._onCloseDeleteConfirmation();
    deleteObjects({ objectIds: [objectToDelete] });
    history.push(prevPath || getHomePath());
  };

  _onCreateObject = () => {
    this.setState({ showCreateObjectDialog: true });
  };

  _onCloseCreateObjectDialog = () => {
    this.setState({ showCreateObjectDialog: false });
  };

  _onGlobalFilterChanged = (e) => {
    const { setGlobalFiltering } = this.props;

    setGlobalFiltering(e.target.checked);
  };

  _onSubmoduleSelected = (value) => {
    const { history, moduleId } = this.props;

    history.push(getObjectListingPath(moduleId, value));
  };

  _onSearchKeyDown = (e) => {
    if (e.keyCode === 13 && e.target.nodeName === 'INPUT') {
      this._onSearch();
    }
  };

  _onSearchChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  _onSearch = () => {
    const { history, searchModuleId } = this.props;
    const { searchText } = this.state;

    if (searchText) {
      history.push(getSearchPath(searchModuleId, searchText));
    }
  };
}

function mapStateToProps(state, ownProps) {
  const {
    location: { pathname }
  } = ownProps;
  const {
    entities: { properties, submodules, objects, tableConfigs, columns },
    app: { globalFiltering },
    route: { prevPath },
    object: { filteredRowsByItem },
    module: {
      moduleIds,
      submodulesByModule,
      selectedSubmodule,
      selectedTableConfig,
      objectListingRows: objectListingRowsState
    },
    export: { exportRequest, printTemplatesBySubmodule }
  } = state;
  const { moduleId, submoduleId, objectId } = parseObjectListingPath(pathname);
  const object = objectId && objects[objectId];
  const moduleSubmodules =
    moduleId &&
    map(submodulesByModule[moduleId], (submoduleId) => submodules[submoduleId]);
  const objectListingRows =
    selectedSubmodule &&
    selectedTableConfig &&
    objectListingRowsState[selectedSubmodule] &&
    objectListingRowsState[selectedSubmodule][selectedTableConfig];
  const filteredRows = filteredRowsByItem[selectedTableConfig];
  const searchModuleId = moduleId || moduleIds[0];
  let objectListingColumns = [];

  if (
    selectedTableConfig &&
    tableConfigs[selectedTableConfig] &&
    !isEmpty(objectListingRows)
  ) {
    objectListingColumns = map(
      tableConfigs[selectedTableConfig].dataGridColumns,
      (id) => columns[id]
    );
  }

  return {
    translations: selectTranslations(state),
    properties,
    globalFiltering,
    prevPath,
    printTemplatesBySubmodule,
    moduleId,
    submoduleId,
    objectId,
    object,
    objectListingRows,
    objectListingColumns,
    filteredRows,
    searchModuleId,
    moduleSubmodules,
    exportRequest
  };
}

export default withRouter(
  connect(mapStateToProps, {
    setGlobalFiltering,
    deleteObjects,
    createExportRequest
  })(Toolbar)
);

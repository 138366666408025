import React from 'react';
import { connect } from 'react-redux';
import md5 from 'md5';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { selectTranslations } from '../store/languageSlice';
import { saveUser } from '../store/userSlice';
import './ExportDialog.scss';

class EditUserDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      user: { username, email }
    } = props;

    this.state = {
      fieldValues: {
        username,
        email,
        password: '',
        repeatPassword: ''
      }
    };
  }

  render() {
    const { translations, user, onClose } = this.props;

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="sm"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          {user.id ? 'Benutzer bearbeiten' : 'Neuer Benutzer'}
        </DialogTitle>
        <DialogContent>
          {this._renderField(translations.username, 'username', 'text')}
          {this._renderField(translations.email, 'email', 'email')}
          {this._renderField(translations.password, 'password', 'password')}
          {this._renderField(
            translations.repeat_password,
            'repeatPassword',
            'password'
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button
            color="primary"
            disabled={!this._isFormInputValid()}
            onClick={this._onSave}
          >
            {translations.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderField = (label, name, type) => {
    const { fieldValues } = this.state;

    return (
      <>
        <DialogContentText>
          <label htmlFor={`${name}-field`}>{label}</label>
        </DialogContentText>
        <div className="input-row text-field-container">
          <TextField
            id={`${name}-field`}
            value={fieldValues[name]}
            type={type}
            variant="outlined"
            fullWidth
            onChange={(e) => this._onFieldValueChange(name, e.target.value)}
          />
        </div>
      </>
    );
  };

  _isFormInputValid = () => {
    const {
      fieldValues: { username, email, password, repeatPassword }
    } = this.state;

    return username && email && password === repeatPassword;
  };

  _onFieldValueChange = (name, value) => {
    const { fieldValues } = this.state;

    this.setState({
      fieldValues: {
        ...fieldValues,
        [name]: value
      }
    });
  };

  _onSave = () => {
    const {
      user: { id },
      saveUser,
      onClose
    } = this.props;
    const {
      fieldValues: { username, email, password }
    } = this.state;

    saveUser({
      user: {
        id,
        username,
        email,
        password: password && md5(password)
      }
    });

    onClose();
  };
}

function mapStateToProps(state, ownProps) {
  return {
    translations: selectTranslations(state)
  };
}

export default connect(mapStateToProps, { saveUser })(EditUserDialog);

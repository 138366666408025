import { forEach, mapValues, has } from 'lodash';
import { isNumericString } from './FormatUtils';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});

export function getTableRowFromDataProviderRow(
  dataProviderRow,
  index,
  columnPropertyIds = null
) {
  return fillTableRow(
    mapValues(dataProviderRow, (value, key) =>
      isNumericString(key) ? value.value : value
    ),
    index,
    columnPropertyIds
  );
}

export function fillTableRow(row, index, columnPropertyIds = null) {
  const newRow = {
    ...row,
    index
  };

  forEach(columnPropertyIds, (propertyId) => {
    if (!has(row, propertyId)) {
      newRow[propertyId] = '';
    }
  });

  return newRow;
}

export function getSortCompareFunction(property, direction = 'ASC') {
  return (row1, row2) =>
    row1.objectId === 0
      ? 1
      : collator.compare(row1[property], row2[property]) *
        (direction === 'DESC' ? -1 : 1);
}

import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import AdvancedSelect from '../../controls/AdvancedSelect';
import { selectTranslations } from '../../store/languageSlice';
import { runCustomProcess } from '../../store/objectSlice';

class ProcessRunnerItem extends React.PureComponent {
  state = {
    selectedValue: ''
  };

  render() {
    const { item, translations, runCustomProcess } = this.props;
    const { selectedValue } = this.state;
    const { choices } = item;
    const options = isEmpty(choices) ? [] : choices;

    return (
      <div className="process-runner-item">
        <AdvancedSelect
          options={options}
          value={selectedValue}
          optionValueField="label"
          optionLabelField="label"
          disableClearable={false}
          onChange={(value) => this.setState({ selectedValue: value })}
        />
        <Button
          className="run-process-button"
          color="primary"
          variant="contained"
          disabled={!selectedValue}
          onClick={() => runCustomProcess({ processName: selectedValue })}
        >
          {translations.execute}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const translations = selectTranslations(state);

  return { translations };
}

export default connect(mapStateToProps, {
  runCustomProcess
})(ProcessRunnerItem);

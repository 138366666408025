import React from 'react';
import { map, find, intersectionWith } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function AdvancedSelect({
  options,
  value,
  optionValueField,
  optionLabelField,
  multiple,
  disableClearable,
  error,
  onChange,
  ...otherProps
}) {
  const selectedOption = multiple
    ? intersectionWith(
        options,
        value || [],
        (o, v) => o[optionValueField] === v
      )
    : find(options, { [optionValueField]: value });

  return (
    <Autocomplete
      options={options}
      value={selectedOption || null}
      multiple={multiple}
      filterSelectedOptions={multiple}
      disableClearable={disableClearable}
      getOptionLabel={(option) => option[optionLabelField]}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
          error={!!error}
          variant="outlined"
        />
      )}
      onChange={(e, option, reason) =>
        onChange &&
        onChange(
          option
            ? multiple
              ? map(option, (o) => o[optionValueField])
              : option[optionValueField]
            : null,
          option,
          reason
        )
      }
      {...otherProps}
    />
  );
}

AdvancedSelect.defaultProps = {
  optionValueField: 'id',
  optionLabelField: 'name',
  multiple: false,
  disableClearable: true
};

export default AdvancedSelect;

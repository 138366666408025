import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../utils/CookieUtils';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    sessionId: null,
    globalFiltering: true,
    cookiesLoadedAt: null
  },
  reducers: {
    loadCookies: (state, action) => {
      const sessionId = getCookie('PHPSESSID');
      const globalFiltering = getCookie('globalFiltering') !== '0';

      window.globalSettings = {
        sessionId,
        globalFiltering
      };

      state.sessionId = sessionId;
      state.globalFiltering = globalFiltering;
      state.cookiesLoadedAt = Date.now();
    },
    setGlobalFiltering: (state, action) => {
      const globalFiltering = action.payload;

      window.globalSettings.globalFiltering = globalFiltering;
      setCookie('globalFiltering', globalFiltering ? '1' : '0');

      state.globalFiltering = globalFiltering;
    }
  }
});

export const { loadCookies, setGlobalFiltering } = appSlice.actions;

export default appSlice.reducer;

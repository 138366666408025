import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getObjectViewPath } from '../constants/paths';
import { selectTranslations } from '../store/languageSlice';
import { createTemplate } from '../store/moduleSlice';
import TemplateNameDialog from './TemplateNameDialog';

class CreateTemplateDialog extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      history,
      createdTemplateObject,
      createdTemplateObjectSubmodule,
      onClose
    } = this.props;

    if (
      createdTemplateObject &&
      prevProps.createdTemplateObject !== createdTemplateObject
    ) {
      history.push(
        getObjectViewPath(
          createdTemplateObjectSubmodule.fk_module_id,
          createdTemplateObjectSubmodule.id,
          createdTemplateObject.id
        )
      );
      onClose();
    }
  }

  render() {
    const { translations, isSaving, onClose } = this.props;

    return (
      <TemplateNameDialog
        title={translations.save_template_as}
        isSaving={isSaving}
        onClose={onClose}
        onSave={this._onSave}
      />
    );
  }

  _onSave = (name) => {
    const { object, createTemplate } = this.props;

    createTemplate({ name, objectId: object.id });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { objects, submodules },
    request: { pending },
    module: { createdTemplate }
  } = state;
  const createdTemplateObject = createdTemplate && objects[createdTemplate];
  const createdTemplateObjectSubmodule =
    createdTemplateObject && submodules[createdTemplateObject.submodule];
  const isSaving = !!pending.createTemplate;

  return {
    translations: selectTranslations(state),
    createdTemplateObject,
    createdTemplateObjectSubmodule,
    isSaving
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createTemplate
  })(CreateTemplateDialog)
);

import { createSlice } from '@reduxjs/toolkit';

const routeSlice = createSlice({
  name: 'route',
  initialState: {
    currentPath: null,
    prevPath: null
  },
  reducers: {
    setCurrentPath: (state, action) => {
      state.prevPath = state.currentPath;
      state.currentPath = action.payload;
    }
  }
});

export const { setCurrentPath } = routeSlice.actions;

export default routeSlice.reducer;

import React from 'react';
import { connect } from 'react-redux';
import { get, replace, reduce } from 'lodash';
import TextField from '@material-ui/core/TextField';

class ConcatValuesItem extends React.PureComponent {
  render() {
    const { displayValue } = this.props;

    return (
      <div className="concat-values-item">
        <TextField
          value={displayValue}
          disabled
          multiline
          rows={1}
          variant="outlined"
          fullWidth
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    object: {
      activeObject: { valueByProperty }
    }
  } = state;
  const {
    item: { propertiesList, formatString }
  } = ownProps;

  const displayValue = reduce(
    propertiesList,
    (acc, propertyId, index) =>
      replace(
        acc,
        new RegExp(`\\[#Var${index + 1}#\\]`, 'g'),
        get(valueByProperty[propertyId], 'value', '')
      ),
    formatString
  );

  return {
    displayValue
  };
}

export default connect(mapStateToProps)(ConcatValuesItem);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import md5 from 'md5';
import { NOTIFICATION_TYPE_ERROR } from '../constants/notificationTypes';
import { getCookie } from '../utils/CookieUtils';
import { callRemoteMethod } from './api/api';
import { userSchema } from './schemas/schemas';
import { addNotification } from './notificationSlice';

export const loadUserFromSession = createAsyncThunk(
  'loadUserFromSession',
  async () =>
    callRemoteMethod(
      'NeoMongoPublicService',
      'loadUserFromSession',
      [1],
      userSchema
    )
);

export const checkLogin = createAsyncThunk(
  'checkLogin',
  async ({ username, password, remember }, { dispatch }) => {
    const response = await callRemoteMethod(
      'NeoMongoPublicService',
      'checkLogin',
      [
        {
          username,
          password: md5(password),
          remember
        }
      ],
      userSchema
    );

    if (!response.result) {
      dispatch(
        addNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: 'Ungültiger Benutzername oder Passwort'
        })
      );
    }

    return response;
  }
);

export const logOut = createAsyncThunk('logOut', async () =>
  callRemoteMethod('NeoUserService', 'logOUT')
);

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isSessionUserLoaded: false,
    user: null
  },
  reducers: {
    resetSession: (state, action) => {
      state.user = null;
    }
  },
  extraReducers: {
    [loadUserFromSession.fulfilled]: (state, action) => {
      state.isSessionUserLoaded = true;
      state.user = action.payload.result;

      const sessionId = getCookie('PHPSESSID');
      window.globalSettings = {
        ...window.globalSettings,
        sessionId
      };
    },
    [checkLogin.fulfilled]: (state, action) => {
      state.user = action.payload.result;
    },
    [logOut.fulfilled]: (state, action) => {
      state.user = null;
    },
    [logOut.rejected]: (state, action) => {
      state.user = null;
    }
  }
});

export const { resetSession } = sessionSlice.actions;

export const selectSessionUser = (state) => state.session.user;

export default sessionSlice.reducer;

import { schema } from 'normalizr';
import { map, compact, toInteger, isEmpty } from 'lodash';

const languageSchema = new schema.Entity('languages');

export const userSchema = new schema.Entity('users', {
  language: languageSchema
});

export const actionSchema = new schema.Entity('actions');

export const propertySchema = new schema.Entity('properties');

export const valueSchema = new schema.Entity(
  'values',
  { property: propertySchema },
  {
    processStrategy: (entity) => ({
      ...entity,
      value: entity.value === 'null' ? '' : entity.value
    })
  }
);

export const screenSchema = new schema.Entity('screens');

export const moduleSchema = new schema.Entity('modules');

export const submoduleSchema = new schema.Entity('submodules', {
  parent: moduleSchema,
  screensArray: [screenSchema]
});

export const objectSchema = new schema.Entity('objects', {
  submodule: submoduleSchema,
  values: [valueSchema],
  updator: userSchema
});

export const printTemplateSchema = new schema.Entity('printTemplates');

export const templateSchema = new schema.Entity('templates');

export const wizardSchema = new schema.Values(
  {
    modulesArray: [moduleSchema],
    submodulesArray: [submoduleSchema],
    templatesArray: [templateSchema]
  },
  (value, parent, key) => key
);

export const columnSchema = new schema.Entity('columns', {
  property: propertySchema
});

export const itemSchema = new schema.Entity(
  'items',
  {
    property: propertySchema,
    submodules: [submoduleSchema],
    searchableProperties: [propertySchema],
    dependentProperties: [propertySchema],
    dataGridColumns: [columnSchema],
    propertiesList: [propertySchema]
  },
  {
    processStrategy: (entity) => ({
      ...entity,
      x: toInteger(entity.x),
      y: toInteger(entity.y),
      width: toInteger(entity.width),
      height: toInteger(entity.height),
      required: entity.mandatory === '1',
      columnPropertyIds: _getColumnPropertyIds(entity)
    })
  }
);

export const tableConfigSchema = new schema.Entity(
  'tableConfigs',
  {
    dataGridColumns: [columnSchema]
  },
  {
    processStrategy: (entity) => ({
      ...entity,
      childrenCreationMethod: 'none',
      submodules: [],
      columnPropertyIds: _getColumnPropertyIds(entity)
    })
  }
);

function _getColumnPropertyIds(entity) {
  return isEmpty(entity.dataGridColumns)
    ? []
    : compact(map(entity.dataGridColumns, (column) => column.property?.id));
}

import { forEach } from 'lodash';

// ported from the AS3 class NeoDataSourceFilterHelper

export function getDependentValues(
  submodule,
  dataSourceArray,
  items,
  objectValues
) {
  if (!dataSourceArray || !submodule) {
    return null;
  }

  const dependentValues = {};

  forEach(dataSourceArray, (dataSource) =>
    _processDependentObjects(
      submodule,
      dataSource,
      dependentValues,
      items,
      objectValues
    )
  );

  return dependentValues;
}

function _processDependentObjects(
  submodule,
  dataSource,
  dependentValues,
  items,
  objectValues
) {
  //if there are filters
  if (dataSource.filters && dataSource.filters.length > 0) {
    //search each filter
    for (let filter of dataSource.filters) {
      //if the filter's 'compare to' submodule is the same submodule as the given submodule
      if (
        filter.compareToSubmodule &&
        filter.compareToSubmodule.id === submodule.id
      ) {
        //saerch all items in the submodule
        forEach(items, (item) => {
          //if the item's property is the same as the filter's property
          if (item.property === filter.compareToProperty.id) {
            // get the property value
            const value =
              objectValues[item.property] && objectValues[item.property].value;

            if (value) {
              dependentValues[item.property] = value;
            }
          }
        });
      }
    }
  }

  //if the filters did not contain the current submodule
  //traverse its children
  if (dataSource.children && dataSource.children.length > 0) {
    for (let child of dataSource.children) {
      //see if the children dataSource need dependent values
      _processDependentObjects(
        submodule,
        child,
        dependentValues,
        items,
        objectValues
      );
    }
  }

  return dependentValues;
}

import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { getEndpointURL } from '../../store/api/api';
import { selectTranslations } from '../../store/languageSlice';
import { uploadFile } from '../../store/objectSlice';
import DataGridItemContainer from './DataGridItemContainer';

class FileListItem extends React.PureComponent {
  state = {
    isDraggedOver: false
  };
  fileInput = React.createRef();

  render() {
    const { translations } = this.props;
    const { isDraggedOver } = this.state;

    return (
      <div
        className="file-list-item"
        onDragOver={this._onDragOver}
        onDragEnter={this._onDragOver}
      >
        <DataGridItemContainer
          {...this.props}
          getColumns={this._getColumns}
          onCreateObject={this._onCreateObject}
        />
        <input
          ref={this.fileInput}
          type="file"
          onChange={this._onFileSelected}
        />
        {isDraggedOver && (
          <div
            className="drop-area"
            onDragEnd={this._onDragOut}
            onDragLeave={this._onDragOut}
            onDrop={this._onDrop}
          >
            <LibraryAddIcon className="drop-area-icon" />
            <h1>{translations.add}</h1>
          </div>
        )}
      </div>
    );
  }

  _getColumns = (defaultColumns) => {
    const { translations } = this.props;
    const downloadColumn = {
      key: 'downloadColumn',
      cellClass: 'download-cell',
      width: 30,
      maxWidth: 30,
      formatter: (props) => {
        const {
          row: { objectId }
        } = props;

        return (
          <Tooltip title={translations.download} arrow>
            <a
              href={getEndpointURL('downloadFile.php', { id: objectId })}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="icon-button"
                color="primary"
                variant="contained"
              >
                <GetAppIcon fontSize="small" />
              </Button>
            </a>
          </Tooltip>
        );
      }
    };

    return [...defaultColumns, downloadColumn];
  };

  _uploadFile = (file) => {
    const { uploadFile } = this.props;

    uploadFile({ file });
    this.fileInput.current.value = '';
  };

  _onCreateObject = () => {
    if (this.fileInput) {
      this.fileInput.current.click();
    }
  };

  _onFileSelected = (e) => {
    const { files } = e.target;

    if (files.length > 0) {
      this._uploadFile(files[0]);
    }
  };

  _onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isDraggedOver: true });
  };

  _onDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isDraggedOver: false });
  };

  _onDrop = (e) => {
    const {
      dataTransfer: { files }
    } = e;

    this._onDragOut(e);

    if (files.length > 0) {
      this._uploadFile(files[0]);
    }
  };
}

function mapStateToProps(state, ownProps) {
  return {
    translations: selectTranslations(state)
  };
}

export default connect(mapStateToProps, { uploadFile })(FileListItem);

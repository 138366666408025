import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Helmet } from 'react-helmet-async';
import memoizeOne from 'memoize-one';
import ReactDataGrid from 'react-data-grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import EditUserDialog from './EditUserDialog';
import DeleteDialog from './DeleteDialog';
import { selectTranslations } from '../store/languageSlice';
import { loadUserList, deleteUser } from '../store/userSlice';
import './Users.scss';

class Users extends React.PureComponent {
  state = {
    userToEdit: null,
    userIdToDelete: null
  };

  componentDidMount() {
    const { loadUserList } = this.props;

    loadUserList();
  }

  componentDidUpdate(prevProps) {
    const { userSavedAt, userDeletedAt, loadUserList } = this.props;

    if (
      (userSavedAt && userSavedAt !== prevProps.userSavedAt) ||
      (userDeletedAt && userDeletedAt !== prevProps.userDeletedAt)
    ) {
      loadUserList();
    }
  }

  render() {
    const { translations, users } = this.props;
    const { userToEdit, userIdToDelete } = this.state;
    const columns = this._getColumns();

    return (
      <div className="users object-listing">
        <Helmet>
          <title>{translations.user}</title>
        </Helmet>
        <div className="cta-container">
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={this._onCreateUser}
          >
            Neuer Benutzer
          </Button>
        </div>
        <ReactDataGrid
          className="data-grid rdg-light"
          columns={columns}
          rows={users}
          rowHeight={30}
          rowKeyGetter={(row) => row.id}
        />
        {userToEdit && (
          <EditUserDialog user={userToEdit} onClose={this._onCloseEdit} />
        )}
        <DeleteDialog
          open={!!userIdToDelete}
          onConfirm={this._onConfirmDelete}
          onClose={this._onCloseDeleteConfirmation}
        />
      </div>
    );
  }

  _getColumns = memoizeOne(() => {
    const { translations } = this.props;

    return [
      {
        key: 'username',
        name: 'Benutzername',
        minWidth: 20,
        resizable: true
      },
      {
        key: 'email',
        name: 'Email',
        minWidth: 20,
        resizable: true
      },
      {
        key: 'optionsColumn',
        cellClass: 'options-cell',
        width: 50,
        maxWidth: 50,
        formatter: (props) => {
          const {
            row: { id }
          } = props;

          return (
            <div>
              <Tooltip title={translations.edit_user} arrow>
                <Button
                  data-id={id}
                  className="icon-button"
                  color="primary"
                  variant="contained"
                  onClick={this._onEditUser}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title={translations.delete} arrow>
                <Button
                  data-id={id}
                  className="icon-button"
                  color="primary"
                  variant="contained"
                  onClick={this._onDeleteUser}
                >
                  <RemoveIcon fontSize="small" />
                </Button>
              </Tooltip>
            </div>
          );
        }
      }
    ];
  });

  _onCreateUser = () => {
    this.setState({
      userToEdit: {
        username: '',
        email: ''
      }
    });
  };

  _onEditUser = (e) => {
    const { users } = this.props;
    const { id } = e.currentTarget.dataset;
    const user = find(users, { id });

    this.setState({ userToEdit: { ...user } });
  };

  _onCloseEdit = () => {
    this.setState({ userToEdit: null });
  };

  _onDeleteUser = (e) => {
    const { id } = e.currentTarget.dataset;

    this.setState({ userIdToDelete: id });
  };

  _onCloseDeleteConfirmation = () => {
    this.setState({ userIdToDelete: null });
  };

  _onConfirmDelete = () => {
    const { deleteUser } = this.props;
    const { userIdToDelete } = this.state;

    deleteUser({ userId: userIdToDelete });

    this._onCloseDeleteConfirmation();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    user: { users, userSavedAt, userDeletedAt }
  } = state;

  return {
    translations: selectTranslations(state),
    users,
    userSavedAt,
    userDeletedAt
  };
}

export default connect(mapStateToProps, { loadUserList, deleteUser })(Users);

import React from 'react';
import classnames from 'classnames';
import LabelItem from './LabelItem';
import TextFieldItem from './TextFieldItem';
import ObjectSelectorItem from './ObjectSelectorItem';
import ObjectSelectorNavItem from './ObjectSelectorNavItem';
import CheckboxItem from './CheckboxItem';
import DateFieldItem from './DateFieldItem';
import ColorSelectorItem from './ColorSelectorItem';
import RectangleItem from './RectangleItem';
import ConcatValuesItem from './ConcatValuesItem';
import ImageItem from './ImageItem';
import ProcessRunnerItem from './ProcessRunnerItem';
import DataGridItemContainer from './DataGridItemContainer';
import FileListItem from './FileListItem';
import GanttItem from './GanttItem';
import './ItemView.scss';

const rendererByType = {
  NeoLabel: LabelItem,
  NeoTextInput: TextFieldItem,
  NeoTextArea: TextFieldItem,
  NeoAutoTextInput: ObjectSelectorNavItem,
  NeoAutoShowTextInput: TextFieldItem,
  NeoFormularTextInput: TextFieldItem,
  NeoKeyGenerator: TextFieldItem,
  NeoLinkedCombobox: ObjectSelectorItem,
  NeoCheckBox: CheckboxItem,
  NeoDateField: DateFieldItem,
  NeoColorList: ColorSelectorItem,
  NeoRectangle: RectangleItem,
  NeoPropertiesViewer: ConcatValuesItem,
  NeoImage: ImageItem,
  NeoProcessRunner: ProcessRunnerItem,
  NeoCalc: DataGridItemContainer,
  NeoFormulaTable: DataGridItemContainer,
  NeoFileList: FileListItem,
  NeoPlaningTool: GanttItem
};

class ItemView extends React.PureComponent {
  render() {
    const { item, value, isNewObject, isDebugMode, onChange } = this.props;
    const { flexClass, x, y, width, height, visible } = item;
    const Renderer = rendererByType[flexClass];

    if (Renderer) {
      return (
        <div
          className={classnames(`item-view item-view-type-${flexClass}`, {
            'is-hidden': !visible
          })}
          style={{
            left: x,
            top: y,
            width: width,
            height: height
          }}
        >
          <Renderer
            item={item}
            value={value}
            isNewObject={isNewObject}
            onChange={onChange}
          />
          {isDebugMode && <div className="item-info">{item.id}</div>}
        </div>
      );
    }

    return null;
  }
}

export default ItemView;

import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
  has,
  map,
  mapKeys,
  mapValues,
  filter,
  reduce,
  forEach,
  find,
  includes,
  uniqBy,
  toLower,
  toNumber,
  toArray,
  isEmpty,
  isEqual
} from 'lodash';
import { withRouter } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import numeral from 'numeral';
import classnames from 'classnames';
import ReactDataGrid, {
  SelectColumn,
  SortableHeaderCell,
  TextEditor
} from 'react-data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import { getObjectViewPath, getBulkEditPath } from '../../constants/paths';
import {
  EXPORT_TYPE_DOWNLOAD,
  EXPORT_SOURCE_TABLE,
  OUTPUT_FORMAT_PDF,
  ORIENTATION_PORTRAIT
} from '../../constants/exportSettings';
import { formatCellValue, isNumericString } from '../../utils/FormatUtils';
import { getSortCompareFunction } from '../../utils/TableUtils';
import AdvancedSelect from '../../controls/AdvancedSelect';
import GotoObjectButton from '../../controls/GotoObjectButton';
import DeleteDialog from '../DeleteDialog';
import { setBulkEditParentPath } from '../../store/objectSlice';

class FilterHeaderRenderer extends React.PureComponent {
  state = {
    anchor: null
  };

  render() {
    const {
      column: { name, filterValue }
    } = this.props;
    const { anchor } = this.state;

    return (
      <div
        className={classnames('value-header', {
          'is-filter-open': anchor,
          'is-filter-set': !!filterValue
        })}
      >
        <SortableHeaderCell {...this.props}>{name}</SortableHeaderCell>
        <Button
          className="icon-button filter-button"
          color="default"
          variant="contained"
          onClick={this._onFilterClicked}
        >
          <SearchIcon fontSize="small" />
        </Button>
        <Popover
          open={!!anchor}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          onClose={this._onCloseFilter}
        >
          <TextField
            value={filterValue}
            autoFocus
            variant="outlined"
            onKeyDown={this._onKeyDown}
            onChange={this._onChange}
          />
        </Popover>
      </div>
    );
  }

  _onCloseFilter = () => {
    this.setState({ anchor: null });
  };

  _onFilterClicked = (e) => {
    const { anchor } = this.state;

    this.setState({ anchor: anchor ? null : e.currentTarget });
  };

  _onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this._onCloseFilter();
    }
  };

  _onChange = (e) => {
    const {
      column: { key, onFilterChange }
    } = this.props;
    const { value } = e.currentTarget;

    onFilterChange(key, value);
  };
}

class DataGridItem extends React.PureComponent {
  state = {
    selectedRows: null,
    sortColumns: null,
    filterByProperty: {},
    objectsToDelete: null,
    submoduleMenuAnchor: null,
    selectedProcess: ''
  };
  _grid = createRef();

  componentDidMount() {
    this._setDefaultSort();
    this._sortAndFilterRows();
  }

  componentDidUpdate(prevProps, prevState) {
    const { item, rows, processRunAt } = this.props;
    const { sortColumns, filterByProperty } = this.state;

    if (item !== prevProps.item) {
      this._setDefaultSort();
    }

    if (
      !isEqual(rows, prevProps.rows) ||
      !isEqual(sortColumns, prevState.sortColumns) ||
      !isEqual(filterByProperty, prevState.filterByProperty)
    ) {
      this._sortAndFilterRows();
    }

    // when a new row is added, scroll to the bottom
    if (
      rows !== prevProps.rows &&
      rows.length > prevProps.rows.length &&
      !rows[rows.length - 1].objectId
    ) {
      this._grid.current.scrollToRow(rows.length);
    }

    if (processRunAt !== prevProps.processRunAt) {
      this.setState({
        selectedRows: null,
        selectedProcess: ''
      });
    }
  }

  componentWillUnmount() {
    this.props.resetFilteredRows();
  }

  render() {
    const {
      item,
      item: {
        multiSelection,
        childrenCreationMethod,
        showBulkEditButton,
        showTablePrintButton
      },
      itemSubmodules,
      filteredRows,
      processes,
      translations,
      rows
    } = this.props;
    const {
      selectedRows,
      sortColumns,
      filterByProperty,
      objectsToDelete,
      submoduleMenuAnchor,
      selectedProcess
    } = this.state;
    const areSelectedRowsEmpty = isEmpty(selectedRows);
    const columns = this._getColumns(item, filterByProperty);
    const summaryRows = this._getSummaryRows(rows, item);

    return (
      <div className="data-grid-item">
        <div className="data-grid-item-controls-container">
          <div className="data-grid-item-controls">
            {(childrenCreationMethod === 'inline' ||
              childrenCreationMethod === 'external') &&
              !isEmpty(itemSubmodules) && (
                <Tooltip title={translations.new_object} arrow>
                  <Button
                    className="icon-button"
                    color="primary"
                    variant="contained"
                    onClick={this._onCreateObject}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}
            {multiSelection && (
              <Tooltip title={translations.delete_selected_objects} arrow>
                <span>
                  <Button
                    className="icon-button"
                    color="primary"
                    variant="contained"
                    disabled={areSelectedRowsEmpty}
                    onClick={this._onDeleteSelectedRows}
                  >
                    <RemoveIcon fontSize="small" />
                  </Button>
                </span>
              </Tooltip>
            )}
            {showBulkEditButton && (
              <Tooltip title={translations.edit_selected_objects} arrow>
                <span>
                  <Button
                    className="icon-button"
                    color="primary"
                    variant="contained"
                    disabled={areSelectedRowsEmpty}
                    onClick={this._onBulkEdit}
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                </span>
              </Tooltip>
            )}
            {!isEmpty(processes) && (
              <div className="process-runner">
                <AdvancedSelect
                  className="process-selector"
                  options={processes}
                  value={selectedProcess}
                  optionValueField="label"
                  optionLabelField="label"
                  disableClearable={false}
                  onChange={this._onProcessSelected}
                />
                <Button
                  className="goto-object-button"
                  color="primary"
                  variant="contained"
                  disabled={!selectedProcess || areSelectedRowsEmpty}
                  onClick={this._onRunProcess}
                >
                  <ChevronRightIcon style={{ fontSize: 22 }} />
                </Button>
              </div>
            )}
          </div>
          {showTablePrintButton && (
            <div className="data-grid-item-controls">
              <Tooltip title={translations.print} arrow>
                <Button
                  className="icon-button"
                  color="primary"
                  variant="contained"
                  onClick={this._onExport}
                >
                  <PrintIcon fontSize="small" />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
        <ReactDataGrid
          ref={this._grid}
          className="data-grid rdg-light"
          columns={columns}
          rows={filteredRows}
          rowHeight={30}
          rowKeyGetter={(row) => row.objectId}
          selectedRows={selectedRows}
          sortColumns={sortColumns}
          summaryRows={summaryRows}
          onSelectedRowsChange={this._onSelectedRowsChange}
          onSortColumnsChange={this._onSortColumnsChange}
          onRowsChange={this._onRowsChange}
        />
        <Menu
          anchorEl={submoduleMenuAnchor}
          keepMounted
          open={!!submoduleMenuAnchor}
          onClose={this._onCloseSubmoduleMenu}
        >
          {map(itemSubmodules, (submodule) => (
            <MenuItem
              key={submodule.id}
              data-module-id={submodule.fk_module_id}
              data-submodule-id={submodule.id}
              onClick={this._onSubmoduleSelected}
            >
              {submodule.name}
            </MenuItem>
          ))}
        </Menu>
        <DeleteDialog
          open={!!objectsToDelete}
          onConfirm={this._onConfirmDelete}
          onClose={this._onCloseDeleteConfirmation}
        />
      </div>
    );
  }

  _getColumns = (item, filterByProperty) => {
    const { translations, itemColumns, getColumns } = this.props;
    const {
      width,
      multiSelection,
      showNavigationColumn,
      showOptionsColumn,
      childrenCreationMethod
    } = item;
    const columns = [];

    if (multiSelection) {
      columns.push({
        ...SelectColumn,
        width: 30,
        maxWidth: 30
      });
    }

    if (showNavigationColumn) {
      columns.push({
        key: 'navigationColumn',
        cellClass: 'navigation-cell',
        width: 30,
        maxWidth: 30,
        frozen: true,
        formatter: (props) => {
          const {
            row: { moduleId, submoduleId, objectId }
          } = props;

          return (
            <GotoObjectButton
              objectURL={
                objectId && getObjectViewPath(moduleId, submoduleId, objectId)
              }
              onClick={this._onGotoObject}
            />
          );
        }
      });
    }

    const uniqueItemColumns = uniqBy(itemColumns, 'property');
    columns.push(
      ...map(uniqueItemColumns, (c, index) => ({
        key: c.property,
        cellClass: classnames('value-cell', {
          'align-right': c.alignment === 'right'
        }),
        name: c.name,
        width: c.width ? toNumber(c.width) : null,
        minWidth: 20,
        maxWidth: width,
        sortable: true,
        resizable: true,
        filterValue: filterByProperty[c.property] || '',
        headerRenderer: FilterHeaderRenderer,
        editor: childrenCreationMethod === 'inline' ? TextEditor : null,
        formatter: (props) => {
          const {
            row,
            column: { key }
          } = props;

          return this._formatValue(c, row[key]) || '';
        },
        summaryFormatter: (props) => {
          const {
            row,
            column: { key }
          } = props;

          return (
            includes(['SUM', 'COUNT'], c.calculationFormula) && (
              <div
                className={classnames('value-cell', {
                  'align-right': c.alignment === 'right'
                })}
              >
                {this._formatValue(c, row[key])}
              </div>
            )
          );
        },
        onFilterChange: (key, value) =>
          this.setState({
            filterByProperty: {
              ...filterByProperty,
              [key]: value
            }
          })
      }))
    );

    if (showOptionsColumn || childrenCreationMethod === 'inline') {
      columns.push({
        key: 'optionsColumn',
        cellClass: 'options-cell',
        width: 50,
        maxWidth: 50,
        formatter: (props) => {
          const {
            row: { index, objectId, isDirty }
          } = props;

          if (isDirty) {
            return (
              <div>
                <Tooltip title={translations.save} arrow>
                  <Button
                    data-row-index={index}
                    className="icon-button"
                    color="primary"
                    variant="contained"
                    onClick={this._onSaveObject}
                  >
                    <CheckIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title={translations.cancel} arrow>
                  <Button
                    data-row-index={index}
                    className="icon-button"
                    color="primary"
                    variant="contained"
                    onClick={this._onDiscardChanges}
                  >
                    <ClearIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </div>
            );
          }

          return (
            <Tooltip title={translations.delete_object} arrow>
              <Button
                data-object-id={objectId}
                className="icon-button"
                color="primary"
                variant="contained"
                onClick={this._onDeleteObject}
              >
                <RemoveIcon fontSize="small" />
              </Button>
            </Tooltip>
          );
        }
      });
    }

    if (getColumns) {
      return getColumns(columns);
    }

    return columns;
  };

  _setDefaultSort = () => {
    const { itemColumns } = this.props;
    const sortColumn = find(itemColumns, { is_sort: true });
    const newState = { filterByProperty: {} };

    if (sortColumn) {
      newState.sortColumns = [
        {
          columnKey: sortColumn.property,
          direction: sortColumn.sort_direction === 'desc' ? 'DESC' : 'ASC'
        }
      ];
    }

    this.setState(newState);
  };

  _sortAndFilterRows = () => {
    const {
      item: { id: itemId },
      rows,
      setFilteredRows
    } = this.props;
    const { sortColumns, filterByProperty } = this.state;
    const filters = mapValues(filterByProperty, (value) => toLower(value));
    const filteredRows = filter(
      rows,
      (row) =>
        isEmpty(filters) ||
        reduce(
          filters,
          (result, value, propertyId) =>
            result && includes(toLower(row[propertyId]), value),
          true
        )
    );
    const sortColumn = isEmpty(sortColumns) ? null : sortColumns[0];

    if (sortColumn) {
      filteredRows.sort(
        getSortCompareFunction(sortColumn.columnKey, sortColumn.direction)
      );
    }

    setFilteredRows({ itemId, filteredRows });
  };

  _getSummaryRows = memoizeOne((rows, item) => {
    const { itemColumns } = this.props;
    const { flexClass } = item;

    if (flexClass !== 'NeoFormulaTable') {
      return null;
    }

    const summaryRow = {};

    forEach(itemColumns, (column) => {
      const { calculationFormula, property } = column;

      switch (calculationFormula) {
        case 'COUNT':
          summaryRow[property] = rows.length;
          break;

        case 'SUM':
          summaryRow[property] = reduce(
            rows,
            (sum, row) => {
              let value = toNumber(row[property]);
              value = isNaN(value) ? 0 : value;

              return sum + value;
            },
            0
          );
          break;

        default:
      }
    });

    return [summaryRow];
  });

  _formatValue = (column, value) => {
    const { properties } = this.props;
    const { formatter, property } = column;

    return formatCellValue(formatter, properties[property], value);
  };

  _createChildObject = (moduleId, submoduleId) => {
    const {
      item: { id, childrenCreationMethod, columnPropertyIds },
      addNewTableRow,
      saveChildObjectExternal
    } = this.props;

    if (childrenCreationMethod === 'inline') {
      addNewTableRow({
        itemId: id,
        moduleId,
        submoduleId,
        columnPropertyIds
      });
    } else {
      saveChildObjectExternal({ submoduleId });
    }
  };

  _onGotoObject = (e) => {
    const { filteredRows, setCurrentTableRows } = this.props;

    setCurrentTableRows(filteredRows);
  };

  _onRowsChange = (rows, { indexes }) => {
    const {
      item: { id },
      updateTableRow
    } = this.props;

    updateTableRow({
      itemId: id,
      row: rows[indexes[0]]
    });
  };

  _onSelectedRowsChange = (selectedRows) => {
    this.setState({ selectedRows });
  };

  _onSortColumnsChange = (sortColumns) => {
    this.setState({ sortColumns });
  };

  _onDeleteSelectedRows = () => {
    const { selectedRows } = this.state;

    this.setState({ objectsToDelete: toArray(selectedRows) });
  };

  _onBulkEdit = () => {
    const { location, history, rows, setBulkEditParentPath } = this.props;
    const { selectedRows } = this.state;
    const selectedRowsArray = toArray(selectedRows);
    const firstRow = find(rows, { objectId: selectedRowsArray[0] });

    setBulkEditParentPath({ path: location.pathname });
    history.push(
      getBulkEditPath(
        firstRow.moduleId,
        firstRow.submoduleId,
        selectedRowsArray
      )
    );
  };

  _onCreateObject = (e) => {
    const { itemSubmodules, onCreateObject } = this.props;

    if (onCreateObject) {
      onCreateObject();
    } else if (itemSubmodules.length === 1) {
      this._createChildObject(
        itemSubmodules[0].fk_module_id,
        itemSubmodules[0].id
      );
    } else {
      this.setState({ submoduleMenuAnchor: e.currentTarget });
    }
  };

  _onSubmoduleSelected = (e) => {
    const { dataset } = e.currentTarget;
    const moduleId = toNumber(dataset.moduleId);
    const submoduleId = toNumber(dataset.submoduleId);

    this._createChildObject(moduleId, submoduleId);

    this.setState({ submoduleMenuAnchor: null });
  };

  _onCloseSubmoduleMenu = () => {
    this.setState({ submoduleMenuAnchor: null });
  };

  _onDeleteObject = (e) => {
    const { objectId } = e.currentTarget.dataset;

    this.setState({ objectsToDelete: [objectId] });
  };

  _onCloseDeleteConfirmation = () => {
    this.setState({ objectsToDelete: null });
  };

  _onConfirmDelete = () => {
    const { deleteObjects } = this.props;
    const { objectsToDelete } = this.state;

    this._onCloseDeleteConfirmation();
    deleteObjects({ objectIds: objectsToDelete });
  };

  _onDiscardChanges = (e) => {
    const { item, rows, resetTableRow } = this.props;
    const rowIndex = toNumber(e.currentTarget.dataset.rowIndex);

    resetTableRow({
      row: rows[rowIndex],
      item
    });
  };

  _onSaveObject = (e) => {
    const { item, itemColumns, rows, saveChildObjectInline } = this.props;
    const rowIndex = toNumber(e.currentTarget.dataset.rowIndex);
    const row = { ...rows[rowIndex] };

    // convert values of numeric columns from German decimal format
    forEach(itemColumns, (c) => {
      const value = row[c.property];

      if (
        value &&
        !isNumericString(value) &&
        [
          'Currency',
          '2 decimals',
          'Procent with 2 decimals',
          'Rounded numbers'
        ].includes(c.formatter)
      ) {
        row[c.property] = String(numeral(value).value());
      }
    });
    saveChildObjectInline({
      row,
      itemId: item.id
    });
  };

  _onProcessSelected = (value) => {
    this.setState({ selectedProcess: value });
  };

  _onRunProcess = () => {
    const { runCustomProcess } = this.props;
    const { selectedRows, selectedProcess } = this.state;

    runCustomProcess({
      processName: selectedProcess,
      objectIds: toArray(selectedRows)
    });
  };

  _onExport = () => {
    const { activeObjectId, itemColumns, filteredRows, createExportRequest } =
      this.props;
    const columnsByProperty = mapKeys(itemColumns, 'property');
    const columnData = map(itemColumns, (c) => ({
      headerText: c.name,
      dataField: c.property,
      formatter: c.formatter
    }));
    const rowData = map(filteredRows, (row, rowIndex) =>
      mapValues(row, (value, propertyId) => {
        if (has(columnsByProperty, propertyId)) {
          return this._formatValue(columnsByProperty[propertyId], value);
        }

        return value;
      })
    );

    createExportRequest({
      type: EXPORT_TYPE_DOWNLOAD,
      exportSource: EXPORT_SOURCE_TABLE,
      outputFormat: OUTPUT_FORMAT_PDF,
      orientation: ORIENTATION_PORTRAIT,
      objectId: activeObjectId,
      tablesData: [
        {
          columnData,
          rowData
        }
      ]
    });
  };
}

export default withRouter(
  connect(null, { setBulkEditParentPath })(DataGridItem)
);

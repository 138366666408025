import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { forEach, min } from 'lodash';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import ReactDataGrid from 'react-data-grid';
import { withTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GotoObjectButton from '../../controls/GotoObjectButton';
import { getObjectViewPath } from '../../constants/paths';
import { selectTranslations } from '../../store/languageSlice';

const CELL_WIDTH_BY_UNIT = {
  days: 30,
  months: 80,
  years: 120
};

function _floorDate(dateString, unit) {
  const date = dateString instanceof moment ? dateString : moment(dateString);

  switch (unit) {
    case 'days':
      return moment([date.year(), date.month(), date.date()]);

    case 'months':
      return moment([date.year(), date.month(), 1]);

    default:
      return moment([date.year(), 0, 1]);
  }
}

function _ceilDate(dateString, unit) {
  return _floorDate(dateString, unit).add(1, unit).subtract(1, 'days');
}

class GanttHeaderRenderer extends React.PureComponent {
  render() {
    const {
      column: { start, unit, colSpan }
    } = this.props;
    const cellWidth = CELL_WIDTH_BY_UNIT[unit];
    const isDaysSelected = unit === 'days';
    const numDivisions = colSpan({ type: 'HEADER' });
    let divisions = new Array(numDivisions);

    for (let i = 0; i < numDivisions; i++) {
      divisions[i] = (
        <div
          key={i}
          className={classnames('division', {
            'is-weekend':
              isDaysSelected &&
              [0, 6].includes(
                moment(start)
                  .date(start.date() + i)
                  .day()
              )
          })}
          style={{ width: cellWidth }}
        >
          {isDaysSelected
            ? start.date() + i
            : moment()
                .month(start.month() + i)
                .format('MMM')}
        </div>
      );
    }

    return (
      <div className="gantt-header">
        <div className="top-row">
          {divisions.length * cellWidth > 60 &&
            start.format(isDaysSelected ? 'MMM YYYY' : 'YYYY')}
        </div>
        {unit !== 'years' && <div className="bottom-row">{divisions}</div>}
      </div>
    );
  }
}

class GanttCellRenderer extends React.PureComponent {
  render() {
    const {
      row: { segments },
      column: { width, start, end },
      theme: {
        palette: { primary }
      }
    } = this.props;
    const unitDuration = end.diff(start);
    const fills = [];

    forEach(segments, (segment, index) => {
      const segmentStart = moment(segment.start);
      const segmentEnd = moment(segment.end).add(1, 'days');

      // if segment and column intersect, render the intersection
      if (segmentEnd.isSameOrAfter(start) && segmentStart.isBefore(end)) {
        const segmentPortionStart = moment.max(segmentStart, start);
        const segmentPortionEnd = moment.min(segmentEnd, end);
        const segmentPortionX =
          width - (end.diff(segmentPortionStart) / unitDuration) * width;
        const segmentPortionWidth =
          (segmentPortionEnd.diff(segmentPortionStart) / unitDuration) * width;

        fills.push(
          <div
            key={index}
            className="gantt-segment-portion"
            style={{
              backgroundColor: primary.main,
              left: segmentPortionX,
              width: segmentPortionWidth
            }}
          />
        );
      }
    });

    return <div>{fills}</div>;
  }
}

GanttCellRenderer = withTheme(GanttCellRenderer);

class GanttItem extends React.PureComponent {
  state = {
    columnUnit: 'days' // days|months|years
  };

  render() {
    const { item, data, translations } = this.props;
    const { columnUnit } = this.state;
    const columns = this._getColumns(item, data, columnUnit);

    return (
      <div className="gantt-item data-grid-item">
        <div className="data-grid-item-controls">
          <ToggleButtonGroup
            value={columnUnit}
            exclusive
            onChange={this._onColumnUnitChanged}
          >
            <ToggleButton
              className="column-unit-button"
              value="days"
              size="small"
            >
              {translations.day || 'Tag'}
            </ToggleButton>
            <ToggleButton
              className="column-unit-button"
              value="months"
              size="small"
            >
              {translations.month}
            </ToggleButton>
            <ToggleButton
              className="column-unit-button"
              value="years"
              size="small"
            >
              {translations.year}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <ReactDataGrid
          className="data-grid rdg-light"
          columns={columns}
          rows={data?.rows || []}
          rowHeight={30}
          headerRowHeight={columnUnit === 'years' ? 30 : 60}
          rowKeyGetter={(row) => row.id}
        />
      </div>
    );
  }

  _getColumns = memoizeOne((item, data, columnUnit) => {
    if (!data) {
      return [];
    }

    const { width } = item;
    const { minStart, maxEnd } = data;
    const columns = [
      {
        key: 'navigationColumn',
        cellClass: 'navigation-cell',
        width: 30,
        maxWidth: 30,
        frozen: true,
        formatter: (props) => {
          const {
            row: { id: objectId, moduleId, submoduleId }
          } = props;

          return (
            <GotoObjectButton
              objectURL={
                objectId && getObjectViewPath(moduleId, submoduleId, objectId)
              }
            />
          );
        }
      },
      {
        key: 'name',
        cellClass: 'value-cell',
        name: 'Name',
        width: 260,
        minWidth: 20,
        maxWidth: width,
        resizable: true,
        frozen: true
      }
    ];

    const minUnitBoundary = _floorDate(minStart, columnUnit);
    const maxUnitBoundary = _ceilDate(maxEnd, columnUnit);
    const dateDiff = maxUnitBoundary.diff(minUnitBoundary, columnUnit);

    for (let i = -1; i <= dateDiff + 1; i++) {
      const start = moment(minUnitBoundary).add(i, columnUnit);
      const end = moment(start).add(1, columnUnit);
      const index = i + 1;

      columns.push({
        key: `ganttColumn${index}`,
        cellClass: classnames('gantt-cell', {
          'weekend-cell': columnUnit === 'days' && [0, 6].includes(start.day())
        }),
        width: CELL_WIDTH_BY_UNIT[columnUnit],
        maxWidth: CELL_WIDTH_BY_UNIT[columnUnit],
        start,
        end,
        unit: columnUnit,
        colSpan: (args) => {
          if (args.type === 'HEADER') {
            let span;

            switch (columnUnit) {
              case 'days':
                const monthEnd = _ceilDate(start, 'months');
                span = monthEnd.diff(start, 'days') + 1;
                break;

              case 'months':
                const yearEnd = _ceilDate(start, 'years');
                span = yearEnd.diff(start, 'months') + 1;
                break;

              default:
                return;
            }

            return min([span, dateDiff - i + 2]);
          }
        },
        formatter: GanttCellRenderer,
        headerRenderer: GanttHeaderRenderer
      });
    }

    return columns;
  });

  _onColumnUnitChanged = (e, columnUnit) => {
    this.setState({ columnUnit });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    item: { id: itemId }
  } = ownProps;
  const {
    object: {
      activeObject: { ganttDataByItem }
    }
  } = state;
  const data = ganttDataByItem[itemId];

  return {
    translations: selectTranslations(state),
    data
  };
}

export default connect(mapStateToProps)(GanttItem);

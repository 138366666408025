import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map, flattenDeep, isEmpty } from 'lodash';
import { NOTIFICATION_TYPE_SUCCESS } from '../constants/notificationTypes';
import { callRemoteMethod } from './api/api';
import { actionSchema } from './schemas/schemas';
import { selectTranslations } from './languageSlice';
import { addNotification } from './notificationSlice';

export const loadUserList = createAsyncThunk('loadUserList', async () =>
  callRemoteMethod('NeoUserService', 'loadUserList')
);

export const saveUser = createAsyncThunk(
  'saveUser',
  async ({ user }, { getState, dispatch }) => {
    const response = await callRemoteMethod('NeoUserService', 'saveUser', [
      user
    ]);
    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: selectTranslations(getState()).user_saved
      })
    );

    return response;
  }
);

export const deleteUser = createAsyncThunk('deleteUser', async ({ userId }) =>
  callRemoteMethod('NeoUserService', 'deleteUser', [userId])
);

export const loadUserGroupList = createAsyncThunk(
  'loadUserGroupList',
  async () => callRemoteMethod('NeoUserService', 'loadUserGroupList')
);

export const loadUserGroupUsers = createAsyncThunk(
  'loadUserGroupUsers',
  async ({ groupId }) =>
    callRemoteMethod('NeoUserService', 'loadUserGroupUsers', [groupId], null, {
      groupId
    })
);

export const saveUserGroup = createAsyncThunk(
  'saveUserGroup',
  async ({ group }, { getState, dispatch }) => {
    const response = await callRemoteMethod('NeoUserService', 'saveUserGroup', [
      group
    ]);
    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: selectTranslations(getState()).user_group_saved
      })
    );

    return response;
  }
);

export const deleteUserGroup = createAsyncThunk(
  'deleteUserGroup',
  async ({ groupId }) =>
    callRemoteMethod('NeoUserService', 'deleteUserGroup', [groupId])
);

export const loadPermissionsList = createAsyncThunk(
  'loadPermissionsList',
  async () =>
    callRemoteMethod('NeoUserService', 'loadPermissionsList', null, {
      actions: [actionSchema]
    })
);

export const savePermission = createAsyncThunk(
  'savePermission',
  async ({ permission }, { getState, dispatch }) => {
    const response = await callRemoteMethod(
      'NeoUserService',
      'savePermission',
      [permission]
    );
    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: selectTranslations(getState()).permissions_saved
      })
    );

    return response;
  }
);

const initialState = {
  users: [],
  groups: [],
  permissions: [],
  usersByGroup: {},
  userSavedAt: null,
  userDeletedAt: null,
  groupSavedAt: null,
  groupDeletedAt: null,
  permissionSavedAt: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [loadUserList.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.users = result;
    },
    [saveUser.fulfilled]: (state, action) => {
      state.userSavedAt = Date.now();
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.userDeletedAt = Date.now();
    },
    [loadUserGroupList.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.groups = result;
    },
    [loadUserGroupUsers.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { groupId }
      } = action.payload;

      state.usersByGroup = { [groupId]: result };
    },
    [saveUserGroup.fulfilled]: (state, action) => {
      state.groupSavedAt = Date.now();
    },
    [deleteUserGroup.fulfilled]: (state, action) => {
      state.groupDeletedAt = Date.now();
    },
    [loadPermissionsList.fulfilled]: (state, action) => {
      const {
        result: { permissions }
      } = action.payload;

      function flattenPermission(p) {
        let parent;

        switch (p.tableName) {
          case 'submodule':
            parent = `module-${p.parentId}`;
            break;

          case 'screens':
            parent = `submodule-${p.parentId}`;
            break;

          default:
            parent = null;
        }

        const newPermission = {
          key: `${p.tableName}-${p.tableId}`,
          parent,
          hasChildren: !isEmpty(p.children),
          ...p
        };
        delete newPermission.children;
        delete newPermission.parentId;

        return [newPermission, ...map(p.children, flattenPermission)];
      }

      state.permissions = flattenDeep(map(permissions, flattenPermission));
    },
    [savePermission.fulfilled]: (state, action) => {
      state.permissionSavedAt = Date.now();
    }
  }
});

export default userSlice.reducer;

import React from 'react';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import NumberFormat from 'react-number-format';
import { toISODateString, fromISODateString } from '../../utils/FormatUtils';
import { calculateItemValueByFormula } from '../../store/objectSlice';

function RoundedNumberInput(props) {
  return <CustomNumberFormat {...props} decimalScale={0} />;
}

function CurrencyInput(props) {
  return <DecimalInput {...props} suffix=" €" />;
}

function PercentInput(props) {
  return <DecimalInput {...props} suffix=" %" />;
}

function DecimalInput(props) {
  return <CustomNumberFormat {...props} fixedDecimalScale />;
}

function CustomNumberFormat({ name, inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(values) =>
        onChange({
          target: {
            name: name,
            value: values.value
          }
        })
      }
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      isNumericString
      {...other}
    />
  );
}

function DateInput({ name, value, inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(values) =>
        onChange({
          target: {
            name: name,
            value:
              toISODateString(values.formattedValue) || values.formattedValue
          }
        })
      }
      value={fromISODateString(value) || value}
      format="##.##.####"
      allowNegative={false}
      allowLeadingZeros
      {...other}
    />
  );
}

class TextFieldItem extends React.PureComponent {
  componentDidMount() {
    this._setDefault();
  }

  componentDidUpdate(prevProps) {
    const { isNewObject } = this.props;

    if (!prevProps.isNewObject && isNewObject) {
      this._setDefault();
    }
  }

  render() {
    const {
      item,
      value,
      property,
      isLoadingData,
      onChange,
      calculateItemValueByFormula
    } = this.props;
    const { flexClass, formatter, readOnly, required, promptText, maxChars } =
      item;
    const inputProps = {};
    const InputProps = {};

    if (maxChars > 0) {
      inputProps.maxLength = maxChars;
    }

    switch (formatter) {
      case 'Currency':
        InputProps.inputComponent = CurrencyInput;
        break;

      case '2 decimals':
        InputProps.inputComponent = DecimalInput;
        break;

      case 'Procent with 2 decimals':
        InputProps.inputComponent = PercentInput;
        break;

      case 'Rounded numbers':
        InputProps.inputComponent = RoundedNumberInput;
        break;

      default:
        if (property?.type === 'date') {
          InputProps.inputComponent = DateInput;
        }
    }

    return (
      <div className="text-field-item">
        <TextField
          value={value.value || ''}
          disabled={!!readOnly}
          placeholder={promptText}
          multiline={flexClass === 'NeoTextArea'}
          rows={1}
          error={required && isEmpty(value.value)}
          inputProps={inputProps}
          InputProps={InputProps}
          variant="outlined"
          fullWidth
          onChange={(e) => onChange(item, e.target.value)}
        />
        {flexClass === 'NeoFormularTextInput' &&
          (isLoadingData ? (
            <div className="loading-indicator">
              <CircularProgress size={20} thickness={4} />
            </div>
          ) : (
            <IconButton
              className="recalculate-button"
              color="primary"
              onClick={() => calculateItemValueByFormula({ item })}
            >
              <RefreshIcon />
            </IconButton>
          ))}
      </div>
    );
  }

  _setDefault = () => {
    const { item, isNewObject, onChange } = this.props;

    if (isNewObject && item.defaultText) {
      onChange(item, item.defaultText);
    }
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { properties },
    request: { pending }
  } = state;
  const { item } = ownProps;
  const property = properties[item.property];
  const isLoadingData = !!(
    pending.calculateItemValueByFormula &&
    find(
      pending.calculateItemValueByFormula,
      (args) => args.item.id === item.id
    )
  );

  return {
    property,
    isLoadingData
  };
}

export default connect(mapStateToProps, {
  calculateItemValueByFormula
})(TextFieldItem);

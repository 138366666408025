import { toNumber } from 'lodash';
import moment from 'moment';

export function formatDate(date, format = 'DD.MM.YYYY') {
  if (date) {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
      return momentDate.format(format);
    }
  }

  return date;
}

export function toISODateString(dateString) {
  const match = dateString.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);

  if (!match) {
    return null;
  }

  return `${match[3]}-${match[2]}-${match[1]}`;
}

export function fromISODateString(isoDateString) {
  const match = isoDateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);

  if (!match) {
    return null;
  }

  return `${match[3]}-${match[2]}-${match[1]}`;
}

export function formatCellValue(format, property, value) {
  switch (format) {
    case 'Currency':
    case '2 decimals':
    case 'Procent with 2 decimals':
    case 'Rounded numbers':
      if (!isNumericString(value)) {
        break;
      }

      const formatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };

      switch (format) {
        case 'Currency':
          formatOptions.style = 'currency';
          formatOptions.currency = 'EUR';
          break;

        case 'Procent with 2 decimals':
          formatOptions.style = 'percent';
          break;

        case 'Rounded numbers':
          formatOptions.minimumFractionDigits = 0;
          formatOptions.maximumFractionDigits = 0;
          break;

        default:
      }

      return toNumber(value).toLocaleString('de-DE', formatOptions);

    case 'Date':
      return formatDate(value);

    default:
  }

  return property?.type === 'date' ? formatDate(value) : value;
}

export function isNumericString(value) {
  return !isNaN(toNumber(value));
}

import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    items: []
  },
  reducers: {
    addNotification: (state, action) => {
      state.items.push(action.payload);
    },
    removeNotification: (state, action) => {
      state.items.splice(action.payload, 1);
    },
    removeAllNotifications: (state, action) => {
      state.items = [];
    }
  }
});

export const { addNotification, removeNotification, removeAllNotifications } =
  notificationSlice.actions;

export const selectNotifications = (state) => state.notification.items;

export default notificationSlice.reducer;

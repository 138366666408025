import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { map, isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AdvancedSelect from '../controls/AdvancedSelect';
import ExportDialogContent from './ExportDialogContent';
import { selectTranslations } from '../store/languageSlice';
import {
  clearExportRequest,
  getEmailTemplates,
  getEmailRecipients,
  exportAndMail
} from '../store/exportSlice';
import './ExportDialog.scss';

class ExportAndEmailDialog extends React.PureComponent {
  state = {
    isExportStep: true,
    selectedEmailTemplate: null,
    customEmail: ''
  };

  componentDidMount() {
    this._loadEmailData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      exportRequest,
      emailTemplates,
      lastEmailsSentAt,
      lastEmailsSuccessful
    } = this.props;

    if (exportRequest.objectId !== prevProps.exportRequest.objectId) {
      this._loadEmailData();
    }

    // select the first email template
    if (
      !isEmpty(emailTemplates) &&
      emailTemplates !== prevProps.emailTemplates
    ) {
      this.setState({ selectedEmailTemplate: emailTemplates[0].id });
    }

    // if emails sent successfully, close the dialog
    if (
      lastEmailsSentAt &&
      lastEmailsSuccessful &&
      lastEmailsSentAt !== prevProps.lastEmailsSentAt
    ) {
      this._onClose();
    }
  }

  render() {
    const { translations } = this.props;
    const { isExportStep } = this.state;

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="sm"
        fullWidth
        onClose={this._onClose}
      >
        <DialogTitle>{translations.export_and_email_window_title}</DialogTitle>
        {isExportStep
          ? this._renderExportStepContent()
          : this._renderEmailStepContent()}
      </Dialog>
    );
  }

  _renderExportStepContent = () => {
    const { translations } = this.props;

    return (
      <>
        <ExportDialogContent />
        <DialogActions>
          <Button onClick={this._onClose} color="primary">
            {translations.cancel}
          </Button>
          <Button onClick={this._onNext} color="primary">
            Next
          </Button>
        </DialogActions>
      </>
    );
  };

  _renderEmailStepContent = () => {
    const { translations, emailTemplates, emailRecipients, isExporting } =
      this.props;
    const { selectedEmailTemplate, customEmail } = this.state;

    return (
      <>
        <DialogContent>
          <DialogContentText>
            {translations.choose_email_template}
          </DialogContentText>
          <div className="input-row text-field-container">
            <AdvancedSelect
              options={emailTemplates}
              value={selectedEmailTemplate}
              onChange={this._onEmailTemplateChanged}
            />
          </div>
          <DialogContentText>Empfänger</DialogContentText>
          <div className="input-row">
            <ul
              className={classnames('email-recipients-list', {
                ineffective: customEmail
              })}
            >
              {map(emailRecipients, (email) => (
                <li key={email}>{email}</li>
              ))}
            </ul>
          </div>
          <div className="input-row text-field-container">
            <TextField
              value={customEmail}
              variant="outlined"
              placeholder={translations.alternative_email_address}
              fullWidth
              onChange={this._onCustomEmailChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this._onBack}>
            {translations.back}
          </Button>
          <Button
            color="primary"
            disabled={!this._isFormInputValid() || isExporting}
            onClick={this._onSend}
          >
            {translations.send_emails}
          </Button>
        </DialogActions>
      </>
    );
  };

  _loadEmailData = () => {
    const {
      exportRequest: { objectId },
      getEmailTemplates,
      getEmailRecipients
    } = this.props;

    getEmailTemplates();
    getEmailRecipients({ objectId });
  };

  _isFormInputValid = () => {
    const { emailRecipients } = this.props;
    const { selectedEmailTemplate, customEmail } = this.state;

    return (
      (!isEmpty(customEmail) || !isEmpty(emailRecipients)) &&
      selectedEmailTemplate
    );
  };

  _onEmailTemplateChanged = (value) => {
    this.setState({
      selectedEmailTemplate: value
    });
  };

  _onCustomEmailChange = (e) => {
    this.setState({ customEmail: e.target.value });
  };

  _onClose = () => {
    const { clearExportRequest } = this.props;

    clearExportRequest();
  };

  _onNext = () => {
    this.setState({ isExportStep: false });
  };

  _onBack = () => {
    this.setState({ isExportStep: true });
  };

  _onSend = () => {
    const { exportRequest, emailRecipients, exportAndMail } = this.props;
    const { selectedEmailTemplate, customEmail } = this.state;

    exportAndMail({
      exportRequest,
      emailRecipients: customEmail ? [customEmail] : emailRecipients,
      emailTemplateId: selectedEmailTemplate
    });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { objects },
    export: {
      exportRequest,
      emailTemplates: emailTemplateIds,
      emailRecipientsByObject,
      lastEmailsSentAt,
      lastEmailsSuccessful
    },
    request: { pending }
  } = state;
  const { objectId } = exportRequest;
  const emailTemplates = map(emailTemplateIds, (id) => objects[id]);
  const emailRecipients = emailRecipientsByObject[objectId];
  const isExporting =
    !!pending.getEmailTemplates ||
    !!pending.getEmailRecipients ||
    !!pending.exportAndMail;

  return {
    translations: selectTranslations(state),
    exportRequest,
    emailTemplates,
    emailRecipients,
    lastEmailsSentAt,
    lastEmailsSuccessful,
    isExporting
  };
}

export default connect(mapStateToProps, {
  clearExportRequest,
  getEmailTemplates,
  getEmailRecipients,
  exportAndMail
})(ExportAndEmailDialog);

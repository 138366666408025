import { mapValues, includes, assignWith } from 'lodash';

const initialState = {
  languages: {},
  users: {},
  actions: {},
  modules: {},
  submodules: {},
  objects: {},
  templates: {},
  screens: {},
  properties: {},
  values: {},
  items: {},
  columns: {},
  tableConfigs: {},
  printTemplates: {}
};

const mergeStrategies = {
  users: (oldEntity, newEntity, action) => {
    if (oldEntity?.sessionId && !newEntity.sessionId) {
      return oldEntity;
    }

    return newEntity;
  },
  submodules: (oldEntity, newEntity, action) => {
    if (
      (oldEntity?.name && !newEntity.name) ||
      (oldEntity?.parent && !newEntity.parent) ||
      (oldEntity?.screensArray && !newEntity.screensArray)
    ) {
      return oldEntity;
    }

    return newEntity;
  },
  properties: (oldEntity, newEntity, action) => {
    if (action.type === 'runCustomProcess/fulfilled') {
      return oldEntity;
    }

    return newEntity;
  },
  items: (oldEntity, newEntity, action) => {
    if (oldEntity && oldEntity.choices !== newEntity.choices) {
      return {
        ...oldEntity,
        choices: newEntity.choices
      };
    }

    if (oldEntity && action.type === 'getObjectsChildrenTableData/fulfilled') {
      return {
        ...oldEntity,
        dataGridDataProvider: newEntity.dataGridDataProvider
      };
    }

    return newEntity;
  }
};

export default function entitiesReducer(state = initialState, action) {
  const { type, payload } = action;

  if (payload?.entities) {
    const { entities, requestInfo } = payload;

    return mapValues(state, (currentEntities, key) => {
      if (!entities[key]) {
        return currentEntities;
      }

      // check whether or not to purge existing entities under this key
      if (
        // clear old values when a new object is loaded
        ((type === 'loadObject/fulfilled' || type === 'saveObject/fulfilled') &&
          key === 'values') ||
        // clear old items when opening the object of a different submodule
        (type === 'getScreenItems/fulfilled' &&
          requestInfo.submoduleId !== requestInfo.lastScreenSubmoduleId &&
          includes(['items'], key))
      ) {
        return entities[key];
      }

      return assignWith(
        {},
        currentEntities,
        entities[key],
        (objValue, srcValue) => {
          if (mergeStrategies[key]) {
            return mergeStrategies[key](objValue, srcValue, action);
          }
        }
      );
    });
  }

  return state;
}

export function getObjectListingPath(moduleId, submoduleId = null) {
  let path = `/object/module-${moduleId}`;

  if (submoduleId) {
    path += `/submodule-${submoduleId}`;
  }

  return path;
}

export function getObjectViewPath(
  moduleId,
  submoduleId,
  objectId,
  screenId = null
) {
  let path = `/object/module-${moduleId}/submodule-${submoduleId}/object-${objectId}`;

  if (screenId) {
    path += `/screen-${screenId}`;
  }

  return path;
}

export function getBulkEditPath(
  moduleId,
  submoduleId,
  objectIds,
  screenId = null
) {
  let path = `/objects/module-${moduleId}/submodule-${submoduleId}/objects-${objectIds.join(
    ','
  )}`;

  if (screenId) {
    path += `/screen-${screenId}`;
  }

  return path;
}

export function getSearchPath(moduleId, searchText = null) {
  let path = `/search/module-${moduleId}`;

  if (searchText) {
    const queryParams = new URLSearchParams();
    queryParams.append('q', searchText);

    return `${path}?${queryParams.toString()}`;
  }

  return path;
}

export function getUsersPath() {
  return '/users';
}

export function getUserGroupsPath() {
  return '/groups';
}

export function getPermissionsPath() {
  return '/permissions';
}

export function getFavoritesPath() {
  return '/favorites';
}

export function getHistoryPath() {
  return '/history';
}

export function getTemplatesPath(moduleId) {
  return `/templates/module-${moduleId}`;
}

export function getHomePath() {
  return '/';
}

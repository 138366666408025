import React from 'react';
import { map, find } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class ColorSelectorItem extends React.PureComponent {
  render() {
    const { item, value, onChange } = this.props;
    const { readOnly, colorList } = item;
    const selectedOption = find(colorList, { label: value.value });

    return (
      <div className="color-selector-item">
        <Select
          value={selectedOption || ''}
          renderValue={(option) => <ColorStrip colorAsUint={option.value} />}
          input={null}
          IconComponent={() => <span />}
          variant="outlined"
          disabled={!!readOnly}
          onChange={(e) => onChange(item, e.target.value.label)}
        >
          {map(colorList, (option) => (
            <MenuItem
              key={option.label}
              value={option}
              component={OptionRenderer}
            />
          ))}
        </Select>
      </div>
    );
  }
}

class OptionRenderer extends React.PureComponent {
  render() {
    const {
      'data-value': { value }
    } = this.props;

    return <ColorStrip {...this.props} colorAsUint={value} />;
  }
}

class ColorStrip extends React.PureComponent {
  render() {
    const { colorAsUint, ...otherProps } = this.props;

    return (
      <div
        style={{
          height: '100%',
          minHeight: 20,
          backgroundColor: uintToHexColor(colorAsUint),
          border: '1px solid #ccc'
        }}
        {...otherProps}
      />
    );
  }
}

function uintToHexColor(uint) {
  const hex = (uint || 0).toString(16);

  return '#' + '0'.repeat(6 - hex.length) + hex;
}

export default ColorSelectorItem;

import { NOTIFICATION_TYPE_ERROR } from '../../constants/notificationTypes';
import { addNotification } from '../notificationSlice';
import { resetSession } from '../sessionSlice';

const errorMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { type, meta, error } = action;

    if (meta) {
      const { requestStatus } = meta;

      if (
        requestStatus === 'rejected' &&
        ![
          'loadUserFromSession/rejected',
          'checkLogin/rejected',
          'logOut/rejected'
        ].includes(type)
      ) {
        console.log('error', error);

        if (error) {
          switch (error.message) {
            case '5':
              dispatch(resetSession());
              break;

            default:
              dispatch(
                addNotification({
                  type: NOTIFICATION_TYPE_ERROR,
                  message:
                    'Es liegt ein Fehler vor. Bitte kontaktieren Sie den Support.'
                })
              );
          }
        }
      }
    }

    return next(action);
  };

export default errorMiddleware;

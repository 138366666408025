import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import Main from './components/Main';
import Notifications from './components/Notifications';
import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './routes/PrivateRoute';
import { loadCookies } from './store/appSlice';
import { loadUserFromSession } from './store/sessionSlice';
import { getModuleList, loadSubmodules } from './store/moduleSlice';

class App extends React.PureComponent {
  componentDidMount() {
    const { loadCookies } = this.props;

    loadCookies();
  }

  componentDidUpdate(prevProps) {
    const { user, cookiesLoadedAt, loadUserFromSession } = this.props;

    if (prevProps.cookiesLoadedAt !== cookiesLoadedAt) {
      loadUserFromSession();
    }

    if (!prevProps.user && user) {
      this._loadInitialData();
    }
  }

  render() {
    const { isSessionUserLoaded, pendingRequests } = this.props;

    if (!isSessionUserLoaded) {
      return 'Loading...';
    }

    return (
      <ErrorBoundary>
        <div id="app">
          <Helmet>
            <title>Dynamind</title>
          </Helmet>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <PrivateRoute path="/">
                <Sidebar />
                <Main />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
          {!isEmpty(pendingRequests) && (
            <div className="general-loading-indicator">
              <LinearProgress />
            </div>
          )}
          <Notifications />
        </div>
      </ErrorBoundary>
    );
  }

  _loadInitialData = () => {
    this.props.getModuleList();
    this.props.loadSubmodules();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    app: { cookiesLoadedAt },
    session: { isSessionUserLoaded, user },
    request: { pending: pendingRequests }
  } = state;

  return {
    pendingRequests,
    user,
    cookiesLoadedAt,
    isSessionUserLoaded
  };
}

export default connect(mapStateToProps, {
  loadCookies,
  loadUserFromSession,
  getModuleList,
  loadSubmodules
})(App);

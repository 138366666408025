import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { filter, find, isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdvancedSelect from '../controls/AdvancedSelect';
import { getObjectViewPath } from '../constants/paths';
import { loadWizardData, selectWizardData } from '../store/objectSlice';
import { selectTranslations } from '../store/languageSlice';

class CreateObjectDialog extends React.PureComponent {
  state = {
    selectedModule: '',
    selectedSubmodule: '',
    selectedExtraFilter: '',
    selectedTemplate: '0'
  };

  componentDidMount() {
    const { wizardData, loadWizardData } = this.props;

    if (isEmpty(wizardData.modules)) {
      loadWizardData();
    } else {
      this.setState({ selectedModule: wizardData.modules[0].id });
    }
  }

  componentDidUpdate(prevProps) {
    const { wizardData } = this.props;

    if (wizardData !== prevProps.wizardData && !isEmpty(wizardData.modules)) {
      this.setState({ selectedModule: wizardData.modules[0].id });
    }
  }

  render() {
    const {
      translations,
      wizardData: { modules, submodules, extraFilters },
      onClose
    } = this.props;
    const {
      selectedModule,
      selectedSubmodule,
      selectedExtraFilter,
      selectedTemplate
    } = this.state;
    const submoduleOptions = selectedModule
      ? filter(
          submodules,
          (submodule) => submodule.fk_module_id === selectedModule
        )
      : [];
    const extraFilterOptions = selectedSubmodule
      ? filter(
          extraFilters,
          (extraFilter) => extraFilter.submoduleId === selectedSubmodule
        )
      : [];
    const templateOptions = this._getTemplateOptions(selectedExtraFilter);

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="sm"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>{translations.new_object}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translations.module}</DialogContentText>
          <div className="input-row text-field-container">
            <AdvancedSelect
              options={modules}
              value={selectedModule}
              onChange={this._onSelectedModuleChanged}
            />
          </div>
          {!isEmpty(submoduleOptions) && (
            <>
              <DialogContentText>{translations.category}</DialogContentText>
              <div className="input-row text-field-container">
                <AdvancedSelect
                  options={submoduleOptions}
                  value={selectedSubmodule}
                  onChange={this._onSelectedSubmoduleChanged}
                />
              </div>
            </>
          )}
          {!isEmpty(extraFilterOptions) && (
            <>
              <DialogContentText>{translations.werbeart}</DialogContentText>
              <div className="input-row text-field-container">
                <AdvancedSelect
                  options={extraFilterOptions}
                  value={selectedExtraFilter}
                  disableClearable={false}
                  onChange={this._onSelectedExtraFilterChanged}
                />
              </div>
            </>
          )}
          {!isEmpty(templateOptions) && (
            <>
              <DialogContentText>{translations.template}</DialogContentText>
              <div className="input-row text-field-container">
                <AdvancedSelect
                  options={
                    isEmpty(templateOptions)
                      ? [{ id: '0', name: translations.no_object_template }]
                      : templateOptions
                  }
                  value={selectedTemplate}
                  disableClearable={false}
                  onChange={this._onSelectedTemplateChanged}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button
            color="primary"
            disabled={!this._isFormInputValid()}
            onClick={this._onCreate}
          >
            {translations.create}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _getTemplateOptions = (extraFilterId) => {
    const {
      wizardData: { extraFilters, templates }
    } = this.props;
    const { selectedSubmodule } = this.state;
    const extraFilter =
      extraFilterId && find(extraFilters, { id: extraFilterId });

    if (!selectedSubmodule) {
      return [];
    }

    return filter(
      templates,
      (template) =>
        template.object.submodule.id === selectedSubmodule &&
        (!extraFilter || template.value?.value === extraFilter.name)
    );
  };

  _isFormInputValid = () => {
    const { selectedModule, selectedSubmodule } = this.state;

    return selectedModule && selectedSubmodule;
  };

  _onSelectedModuleChanged = (value) => {
    this.setState({
      selectedModule: value,
      selectedSubmodule: '',
      selectedExtraFilter: '',
      selectedTemplate: '0'
    });
  };

  _onSelectedSubmoduleChanged = (value) => {
    this.setState({
      selectedSubmodule: value || '',
      selectedExtraFilter: '',
      selectedTemplate: '0'
    });
  };

  _onSelectedExtraFilterChanged = (value) => {
    const templateOptions = this._getTemplateOptions(value);
    let selectedTemplate = '0';

    if (!isEmpty(templateOptions)) {
      selectedTemplate = templateOptions[0].id;
    }

    this.setState({
      selectedExtraFilter: value,
      selectedTemplate
    });
  };

  _onSelectedTemplateChanged = (value) => {
    this.setState({ selectedTemplate: value });
  };

  _onCreate = () => {
    const {
      history,
      wizardData: { templates },
      onClose
    } = this.props;
    const { selectedModule, selectedSubmodule, selectedTemplate } = this.state;
    const template = find(templates, { id: selectedTemplate });

    history.push(
      getObjectViewPath(
        selectedModule,
        selectedSubmodule,
        (template && template.object?.id) || '0'
      )
    );
    onClose();
  };
}

function mapStateToProps(state, ownProps) {
  return {
    translations: selectTranslations(state),
    wizardData: selectWizardData(state)
  };
}

export default withRouter(
  connect(mapStateToProps, { loadWizardData })(CreateObjectDialog)
);

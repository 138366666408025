import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { selectTranslations } from '../store/languageSlice';
import './ExportDialog.scss';

class TemplateNameDialog extends React.PureComponent {
  state = {
    name: this.props.defaultName || ''
  };

  render() {
    const { translations, title, isSaving, onClose } = this.props;
    const { name } = this.state;

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="sm"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <label htmlFor="name-field">{translations.name}</label>
          </DialogContentText>
          <div className="input-row text-field-container">
            <TextField
              id="name-field"
              value={name}
              autoFocus
              variant="outlined"
              fullWidth
              onChange={this._onNameChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translations.cancel}
          </Button>
          <Button
            color="primary"
            disabled={!this._isFormInputValid() || isSaving}
            onClick={this._onSave}
          >
            {translations.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _isFormInputValid = () => {
    const { name } = this.state;

    return !!name;
  };

  _onNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  };

  _onSave = () => {
    const { onSave } = this.props;
    const { name } = this.state;

    onSave(name);
  };
}

function mapStateToProps(state, ownProps) {
  return {
    translations: selectTranslations(state)
  };
}

export default connect(mapStateToProps)(TemplateNameDialog);

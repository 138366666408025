import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { find, includes } from 'lodash';
import { Helmet } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import { getSearchTextFromPath } from '../utils/PathUtils';
import {
  loadTableTemplatesList,
  getSubmoduleListView,
  loadSearchResults,
  loadHistoryList,
  getFavoriteObjectsList,
  loadTemplatesList,
  renameTemplate,
  selectModule,
  selectSubmodule,
  selectTableConfig,
  clearTableRows
} from '../store/moduleSlice';
import { selectTranslations } from '../store/languageSlice';
import ObjectListingGridContainer from './items/ObjectListingGridContainer';
import TemplateNameDialog from './TemplateNameDialog';
import './ObjectListing.scss';

class ObjectListing extends React.PureComponent {
  state = {
    templateToRename: null
  };

  componentDidMount() {
    const { moduleId, submoduleId, selectModule, selectSubmodule } = this.props;

    selectModule({ moduleId });
    selectSubmodule({ submoduleId });
    this._loadTableConfigs();
    this._loadTableData();
  }

  componentDidUpdate(prevProps) {
    const {
      globalFiltering,
      moduleId,
      submoduleId,
      tableConfig,
      searchText,
      isHistory,
      isFavorites,
      isTemplates,
      processRunAt,
      objectsDeletedAt,
      templateRenamedAt,
      selectModule,
      selectSubmodule,
      clearTableRows
    } = this.props;

    if (prevProps.templateRenamedAt !== templateRenamedAt) {
      this._onCloseRenameTemplate();
    }

    if (moduleId !== prevProps.moduleId) {
      selectModule({ moduleId });
      this._loadTableConfigs();
    }

    if (submoduleId !== prevProps.submoduleId) {
      selectSubmodule({ submoduleId });
    }

    const didCurrentListingChange =
      moduleId !== prevProps.moduleId ||
      submoduleId !== prevProps.submoduleId ||
      tableConfig !== prevProps.tableConfig ||
      searchText !== prevProps.searchText ||
      isHistory !== prevProps.isHistory ||
      isFavorites !== prevProps.isFavorites ||
      isTemplates !== prevProps.isTemplates;

    if (
      tableConfig &&
      (didCurrentListingChange ||
        prevProps.processRunAt !== processRunAt ||
        prevProps.objectsDeletedAt !== objectsDeletedAt ||
        prevProps.templateRenamedAt !== templateRenamedAt ||
        prevProps.globalFiltering !== globalFiltering)
    ) {
      if (didCurrentListingChange) {
        clearTableRows();
      }

      this._loadTableData();
    }
  }

  render() {
    const {
      translations,
      moduleId,
      submoduleId,
      submodule,
      tableConfig,
      searchText,
      templateRows,
      isHistory,
      isFavorites,
      isTemplates,
      isRenamingTemplate
    } = this.props;
    const { templateToRename } = this.state;
    let title;

    if (searchText) {
      title = `${translations.search}: ${searchText}`;
    } else if (submodule) {
      title = submodule.name;
    } else if (isHistory) {
      title = translations.history;
    } else if (isFavorites) {
      title = translations.favorites;
    } else if (isTemplates) {
      title = translations.templates;
    }

    return (
      <div className="object-listing">
        {title && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
        {tableConfig && (
          <ObjectListingGridContainer
            item={tableConfig}
            moduleId={moduleId}
            submoduleId={submoduleId}
            searchText={searchText}
            isHistory={isHistory}
            isFavorites={isFavorites}
            isTemplates={isTemplates}
            getColumns={this._getColumns}
          />
        )}
        {!!templateToRename && (
          <TemplateNameDialog
            title={translations.rename}
            defaultName={
              find(templateRows, { objectId: templateToRename })?.templateName
            }
            isSaving={isRenamingTemplate}
            onClose={this._onCloseRenameTemplate}
            onSave={this._onConfirmRenameTemplate}
          />
        )}
      </div>
    );
  }

  _loadTableConfigs = () => {
    const { moduleId, tableConfig, loadTableTemplatesList } = this.props;

    if (moduleId && !tableConfig) {
      loadTableTemplatesList({ moduleId });
    }
  };

  _loadTableData = () => {
    const {
      moduleId,
      submoduleId,
      tableConfig,
      searchText,
      isSubmoduleListing,
      isHistory,
      isFavorites,
      isTemplates,
      getSubmoduleListView,
      loadSearchResults,
      loadHistoryList,
      getFavoriteObjectsList,
      loadTemplatesList,
      selectTableConfig
    } = this.props;

    if (moduleId && tableConfig) {
      selectTableConfig({ tableConfigId: tableConfig.id });

      if (searchText) {
        loadSearchResults({
          moduleId,
          searchText
        });
      } else if (isHistory) {
        loadHistoryList();
      } else if (isFavorites) {
        getFavoriteObjectsList();
      } else if (isTemplates) {
        loadTemplatesList({ moduleId });
      } else if (isSubmoduleListing && submoduleId) {
        getSubmoduleListView({
          moduleId,
          submoduleId,
          tableConfig
        });
      }
    }
  };

  _getColumns = (defaultColumns) => {
    const { translations, isTemplates } = this.props;

    if (!isTemplates) {
      return defaultColumns;
    }

    const renameColumn = {
      key: 'renameColumn',
      cellClass: 'rename-cell',
      width: 30,
      maxWidth: 30,
      formatter: (props) => {
        const {
          row: { objectId }
        } = props;

        return (
          <Tooltip title={translations.rename} arrow>
            <Button
              data-object-id={objectId}
              className="icon-button"
              color="primary"
              variant="contained"
              onClick={this._onRenameTemplate}
            >
              <TextFormatIcon fontSize="small" />
            </Button>
          </Tooltip>
        );
      }
    };

    return [...defaultColumns, renameColumn];
  };

  _onRenameTemplate = (e) => {
    const { objectId } = e.currentTarget.dataset;

    this.setState({ templateToRename: objectId });
  };

  _onCloseRenameTemplate = () => {
    this.setState({ templateToRename: null });
  };

  _onConfirmRenameTemplate = (templateName) => {
    const { templateRows, renameTemplate } = this.props;
    const { templateToRename } = this.state;
    const templateId = find(templateRows, {
      objectId: templateToRename
    })?.templateId;

    renameTemplate({ templateId, templateName });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    match: {
      params: { moduleId, submoduleId }
    },
    location
  } = ownProps;
  const {
    entities: { submodules, tableConfigs },
    app: { globalFiltering },
    module: {
      moduleIds,
      submodulesByModule,
      tableConfigsByModule,
      selectedTableConfig,
      templateRows,
      templateRenamedAt
    },
    object: {
      activeObject: { processRunAt, objectsDeletedAt }
    },
    request: { pending }
  } = state;
  const effectiveModuleId = moduleId || moduleIds[0];
  const effectiveSubmoduleId =
    submoduleId ||
    (submodulesByModule[effectiveModuleId] &&
      submodulesByModule[effectiveModuleId][0]);
  const submodule = submodules[effectiveSubmoduleId];
  const moduleTableConfigs = tableConfigsByModule[effectiveModuleId];
  const effectiveTableConfigId =
    selectedTableConfig &&
    includes(tableConfigsByModule[effectiveModuleId], selectedTableConfig)
      ? selectedTableConfig
      : moduleTableConfigs && moduleTableConfigs[0];
  const tableConfig = tableConfigs[effectiveTableConfigId];
  const searchText = getSearchTextFromPath(location);
  const isRenamingTemplate = !!pending.renameTemplate;

  return {
    translations: selectTranslations(state),
    globalFiltering,
    moduleId: effectiveModuleId,
    submoduleId: effectiveSubmoduleId,
    submodule,
    tableConfig,
    searchText,
    templateRows,
    processRunAt,
    objectsDeletedAt,
    templateRenamedAt,
    isRenamingTemplate
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadTableTemplatesList,
    getSubmoduleListView,
    loadSearchResults,
    loadHistoryList,
    getFavoriteObjectsList,
    loadTemplatesList,
    renameTemplate,
    selectModule,
    selectSubmodule,
    selectTableConfig,
    clearTableRows
  })(ObjectListing)
);

export const EXPORT_TYPE_EMAIL = 'email';
export const EXPORT_TYPE_DOWNLOAD = 'download';

export const EXPORT_SOURCE_TABLE = 'printSourceIsTable';
export const EXPORT_SOURCE_OBJECT = 'printSourceIsObject';

export const OUTPUT_FORMAT_CSV = 'OUTPUT_CSV';
export const OUTPUT_FORMAT_PDF = 'OUTPUT_PDF';
export const OUTPUT_FORMAT_XLS = 'OUTPUT_XLS';
export const OUTPUT_FORMAT_HTML = 'OUTPUT_HTML';
export const OUTPUT_FORMAT_PLAIN_CSV = 'OUTPUT_PLAIN_CSV';

export const ORIENTATION_PORTRAIT = 'ORIENTATION_PORTRAIT';
export const ORIENTATION_LANDSCAPE = 'ORIENTATION_LANDSCAPE';

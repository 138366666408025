import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ExportDialogContent from './ExportDialogContent';
import { selectTranslations } from '../store/languageSlice';
import { clearExportRequest, exportAndDownload } from '../store/exportSlice';
import './ExportDialog.scss';

class ExportDialog extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { lastDownloadURL } = this.props;

    if (lastDownloadURL && prevProps.lastDownloadURL !== lastDownloadURL) {
      window.open(lastDownloadURL, '_blank');
      this._onClose();
    }
  }

  render() {
    const { translations, isExporting } = this.props;

    return (
      <Dialog
        className="export-dialog"
        open
        maxWidth="sm"
        fullWidth
        onClose={this._onClose}
      >
        <DialogTitle>{translations.print_format}</DialogTitle>
        <ExportDialogContent />
        <DialogActions>
          <Button color="primary" onClick={this._onClose}>
            {translations.cancel}
          </Button>
          <Button
            color="primary"
            disabled={isExporting}
            onClick={this._onExport}
          >
            {translations.print}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _onClose = () => {
    const { clearExportRequest } = this.props;

    clearExportRequest();
  };

  _onExport = () => {
    const { exportRequest, exportAndDownload } = this.props;

    exportAndDownload({ exportRequest });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    export: { exportRequest, lastDownloadURL },
    request: { pending }
  } = state;
  const isExporting = !!pending.exportAndDownload;

  return {
    translations: selectTranslations(state),
    exportRequest,
    lastDownloadURL,
    isExporting
  };
}

export default connect(mapStateToProps, {
  clearExportRequest,
  exportAndDownload
})(ExportDialog);

import React from 'react';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import RefreshIcon from '@material-ui/icons/Refresh';
import { callRemoteMethod } from '../store/api/api';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.PureComponent {
  state = {
    hasError: false
  };

  componentDidCatch(error, errorInfo) {
    callRemoteMethod('NeoLogService', 'log', [
      String(error?.stack),
      errorInfo?.componentStack
    ]);

    this.setState({
      hasError: true
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="error-boundary">
          <h1>Es liegt ein Fehler vor. Bitte kontaktieren Sie den Support.</h1>
          <div className="cta-container">
            <Button
              variant="contained"
              startIcon={<ForwardIcon />}
              onClick={this._onIgnore}
            >
              Ignorieren
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={this._onRefresh}
            >
              Neu laden
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }

  _onIgnore = () => {
    this.setState({
      hasError: false
    });
  };

  _onRefresh = () => {
    window.location.reload();
  };
}

export default ErrorBoundary;

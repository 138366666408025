import { connect } from 'react-redux';
import { map } from 'lodash';
import { selectTranslations } from '../../store/languageSlice';
import {
  deleteObjects,
  saveChildObjectInline,
  saveChildObjectExternal,
  runCustomProcess,
  updateTableRow,
  addNewTableRow,
  resetTableRow,
  setFilteredRows,
  resetFilteredRows,
  setCurrentTableRows
} from '../../store/objectSlice';
import { createExportRequest } from '../../store/exportSlice';
import DataGridItem from './DataGridItem';

function mapStateToProps(state, ownProps) {
  const {
    item,
    item: {
      id: itemId,
      dataGridColumns,
      showProcessRunner,
      processes: itemProcesses
    }
  } = ownProps;
  const {
    entities: { columns, submodules, properties },
    object: {
      activeObject: { id: activeObjectId, tableRowsByItem, processRunAt },
      filteredRowsByItem
    }
  } = state;
  const itemColumns = map(dataGridColumns, (id) => columns[id]);
  const itemSubmodules = map(item.submodules, (id) => submodules[id]);
  const rows = tableRowsByItem[itemId] || [];
  const processes = showProcessRunner ? itemProcesses : null;

  return {
    translations: selectTranslations(state),
    properties,
    activeObjectId,
    itemColumns,
    itemSubmodules,
    rows,
    filteredRows: filteredRowsByItem[itemId] || [],
    processes,
    processRunAt
  };
}

export default connect(mapStateToProps, {
  deleteObjects,
  saveChildObjectInline,
  saveChildObjectExternal,
  runCustomProcess,
  createExportRequest,
  updateTableRow,
  addNewTableRow,
  resetTableRow,
  setFilteredRows,
  resetFilteredRows,
  setCurrentTableRows
})(DataGridItem);

import { configureStore } from '@reduxjs/toolkit';
import errorMiddleware from './middleware/errorMiddleware';
import entitiesReducer from './entitiesReducer';
import appReducer from './appSlice';
import sessionReducer from './sessionSlice';
import notificationReducer from './notificationSlice';
import objectReducer from './objectSlice';
import moduleReducer from './moduleSlice';
import exportReducer from './exportSlice';
import userReducer from './userSlice';
import requestReducer from './requestReducer';
import routeReducer from './routeSlice';

export default configureStore({
  reducer: {
    entities: entitiesReducer,
    app: appReducer,
    session: sessionReducer,
    notification: notificationReducer,
    object: objectReducer,
    module: moduleReducer,
    export: exportReducer,
    user: userReducer,
    request: requestReducer,
    route: routeReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorMiddleware)
});

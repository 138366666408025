import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const ICON_STYLE = { fontSize: 22 };

export default function GotoObjectButton(props) {
  const { objectURL, ...otherProps } = props;

  return objectURL ? (
    <Link to={objectURL}>
      <Button
        className="goto-object-button"
        color="primary"
        variant="contained"
        {...otherProps}
      >
        <ChevronRightIcon style={ICON_STYLE} />
      </Button>
    </Link>
  ) : (
    <Button
      className="goto-object-button"
      color="primary"
      variant="contained"
      disabled
    >
      <ChevronRightIcon style={ICON_STYLE} />
    </Button>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, isArray, find, filter, debounce } from 'lodash';
import AdvancedSelect from '../../controls/AdvancedSelect';
import GotoObjectButton from '../../controls/GotoObjectButton';
import { getObjectViewPath } from '../../constants/paths';
import {
  loadItemData,
  getDependentItemsValues,
  resetCurrentTableRows
} from '../../store/objectSlice';
import { selectTranslations } from '../../store/languageSlice';

class ObjectSelectorNavItem extends React.PureComponent {
  render() {
    const { translations, item, value, savedOption, isLoadingData } =
      this.props;
    const { readOnly, required, choices } = item;
    const options = savedOption ? [savedOption] : [];
    const savedOptionId = savedOption?.objectId;
    const unsavedChoices = savedOptionId
      ? filter(choices, ({ objectId }) => objectId !== savedOptionId)
      : choices;

    if (!isEmpty(unsavedChoices)) {
      options.push(...unsavedChoices);
    }

    const selectedOption = find(options, { objectId: value.value });
    const linkURL = selectedOption
      ? getObjectViewPath(
          selectedOption.moduleId,
          selectedOption.submoduleId,
          selectedOption.objectId
        )
      : '';

    return (
      <div className="object-selector-nav-item">
        <AdvancedSelect
          options={options}
          value={value.value}
          optionValueField="objectId"
          optionLabelField="label"
          filterOptions={(options) => options}
          disabled={!!readOnly}
          error={required && isEmpty(value.value)}
          disableClearable={!!required}
          loading={isLoadingData}
          loadingText={translations.loading}
          onInputChange={debounce(this._onInputChange, 200)}
          onChange={this._onChange}
          onOpen={this._onOpen}
        />
        <GotoObjectButton objectURL={linkURL} onClick={this._onGotoObject} />
      </div>
    );
  }

  _onOpen = () => {
    const { item, activeObjectId, loadItemData } = this.props;
    const { pushResults, choices } = item;
    const isDataLoaded = isArray(choices);

    if (pushResults && !isDataLoaded) {
      loadItemData({ item, text: '', objectId: activeObjectId });
    }
  };

  _onInputChange = (e, value, reason) => {
    const { item, activeObjectId, loadItemData } = this.props;
    const { pushResults } = item;

    if (!pushResults && value && reason === 'input') {
      loadItemData({ item, text: value, objectId: activeObjectId });
    }
  };

  _onChange = (value, option) => {
    const { item, getDependentItemsValues, onChange } = this.props;

    onChange(item, value, option);
    getDependentItemsValues({
      propertyId: item.property,
      selectedOption: option
    });
  };

  _onGotoObject = () => {
    this.props.resetCurrentTableRows();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { values },
    object: {
      activeObject: { id: activeObjectId }
    },
    request: { pending }
  } = state;
  const { item, value } = ownProps;
  const savedValue = values[value.id];
  const savedOption = savedValue ? savedValue.complexValue : null;
  const isLoadingData = !!(
    pending.loadItemData &&
    find(pending.loadItemData, (args) => args.item.id === item.id)
  );

  return {
    translations: selectTranslations(state),
    activeObjectId,
    savedOption,
    isLoadingData
  };
}

export default connect(mapStateToProps, {
  loadItemData,
  getDependentItemsValues,
  resetCurrentTableRows
})(ObjectSelectorNavItem);

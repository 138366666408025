import { normalize } from 'normalizr';

export async function callRemoteMethod(
  service,
  method,
  args = null,
  schema = null,
  requestInfo = null
) {
  const gatewayURL = getEndpointURL('/gateway.php', { service, method });
  const fetchOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (args) {
    fetchOptions.body = JSON.stringify(args);
  }

  const isLogCall = service === 'NeoLogService';
  const response = await fetch(gatewayURL, fetchOptions);

  if (isLogCall) {
    return;
  }

  const responseCopy = response.clone();
  let responseObject;

  try {
    responseObject = await response.json();
  } catch (e) {
    const responseText = await responseCopy.text();
    callRemoteMethod('NeoLogService', 'log', [
      service,
      method,
      fetchOptions.body,
      responseText,
      String(e)
    ]);
  }

  const { result, error } = responseObject;

  if (error) {
    throw new Error(error.code);
  }

  const normalizedResult =
    result && schema ? normalize(result, schema) : responseObject;

  return {
    ...normalizedResult,
    requestInfo
  };
}

export function getEndpointURL(path, urlParams = {}) {
  const queryParams = new URLSearchParams(urlParams);
  const { sessionId, globalFiltering } = window.globalSettings || {};

  if (sessionId) {
    queryParams.set('SID', sessionId);
  }

  if (!globalFiltering) {
    queryParams.set('globalFiltering', 'false');
  }

  const url = new URL(
    path,
    process.env.REACT_APP_API_URL || window.location.origin
  );
  url.search = queryParams;

  return url;
}

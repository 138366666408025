import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import { map, mapValues, orderBy, groupBy, values, toInteger } from 'lodash';
import { getTableRowFromDataProviderRow } from '../utils/TableUtils';
import { callRemoteMethod } from './api/api';
import {
  moduleSchema,
  submoduleSchema,
  objectSchema,
  tableConfigSchema
} from './schemas/schemas';

export const getModuleList = createAsyncThunk('getModuleList', async () =>
  callRemoteMethod('NeoModuleService', 'getModuleList', [true], [moduleSchema])
);

export const loadSubmodules = createAsyncThunk('loadSubmodules', async () =>
  callRemoteMethod('NeoModuleService', 'loadSubmodules', [], [submoduleSchema])
);

export const loadTableTemplatesList = createAsyncThunk(
  'loadTableTemplatesList',
  async ({ moduleId }) =>
    callRemoteMethod(
      'NeoObjectService',
      'loadTableTemplatesList',
      [moduleId],
      [tableConfigSchema],
      { moduleId }
    )
);

export const getSubmoduleListView = createAsyncThunk(
  'getSubmoduleListView',
  async ({ moduleId, submoduleId, tableConfig }) =>
    callRemoteMethod(
      'NeoModuleService',
      'getSubmoduleListView',
      [moduleId, submoduleId, tableConfig.columnPropertyIds],
      null,
      {
        submoduleId,
        tableConfigId: tableConfig.id,
        propertyIds: tableConfig.columnPropertyIds
      }
    )
);

export const loadSearchResults = createAsyncThunk(
  'loadSearchResults',
  async ({ moduleId, searchText }) =>
    callRemoteMethod(
      'NeoSearchService',
      'loadSearchResults',
      [moduleId, searchText.split(/\s+/)],
      null,
      {
        moduleId,
        searchText
      }
    )
);

export const loadHistoryList = createAsyncThunk('loadHistoryList', async () =>
  callRemoteMethod('NeoHistoryService', 'loadHistoryList')
);

export const addHistoryItem = createAsyncThunk(
  'addHistoryItem',
  async ({ moduleId, submoduleId, objectId }) =>
    callRemoteMethod('NeoHistoryService', 'addHistoryItem', [
      {
        fk_module_id: moduleId,
        fk_submodule_id: submoduleId,
        fk_object_id: objectId
      }
    ])
);

export const getFavoriteObjectsList = createAsyncThunk(
  'getFavoriteObjectsList',
  async () => callRemoteMethod('NeoObjectService', 'getFavoriteObjectsList')
);

export const changeObjectFavoriteStatus = createAsyncThunk(
  'changeObjectFavoriteStatus',
  async (args, { getState }) => {
    const {
      entities,
      entities: { objects },
      object: {
        activeObject: { id }
      }
    } = getState();
    const object = denormalize(objects[id], objectSchema, entities);

    return callRemoteMethod(
      'NeoObjectService',
      'changeObjectFavoriteStatus',
      [{ ...object, favorite: !object.favorite }],
      objectSchema
    );
  }
);

export const loadTemplatesList = createAsyncThunk(
  'loadTemplatesList',
  async ({ moduleId }) =>
    callRemoteMethod('NeoTemplateService', 'loadTemplatesList', [moduleId])
);

export const createTemplate = createAsyncThunk(
  'createTemplate',
  async ({ name, objectId }) =>
    callRemoteMethod(
      'NeoTemplateService',
      'createTemplate',
      [{ name }, objectId, 6],
      { object: objectSchema }
    )
);

export const deleteTemplate = createAsyncThunk(
  'deleteTemplate',
  async ({ templateId, objectId }) =>
    callRemoteMethod('NeoTemplateService', 'deleteTemplate', [
      templateId,
      objectId
    ])
);

export const renameTemplate = createAsyncThunk(
  'renameTemplate',
  async ({ templateId, templateName }) =>
    callRemoteMethod('NeoTemplateService', 'renameTemplate', [
      templateId,
      templateName
    ])
);

const initialState = {
  objectListingRows: {}, // objectListingRows[submoduleId][tableConfigId] = <rows>
  searchResultRows: {}, // searchResultRows[moduleId][searchText] = <rows>
  historyRows: [],
  favoritesRows: [],
  templateRows: [],
  moduleIds: [],
  submodulesByModule: {},
  tableConfigsByModule: {},
  createdTemplate: null,
  selectedModule: null,
  selectedSubmodule: null,
  selectedTableConfig: null,
  templateDeletedAt: null,
  templateRenamedAt: null
};

const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    selectModule: (state, action) => {
      const { moduleId } = action.payload;

      state.selectedModule = moduleId;
    },
    selectSubmodule: (state, action) => {
      const { submoduleId } = action.payload;

      state.selectedSubmodule = submoduleId;
    },
    selectTableConfig: (state, action) => {
      const { tableConfigId } = action.payload;

      state.selectedTableConfig = tableConfigId;
    },
    clearTableRows: (state, action) => {
      state.objectListingRows = initialState.objectListingRows;
      state.searchResultRows = initialState.searchResultRows;
    }
  },
  extraReducers: {
    [getModuleList.fulfilled]: (state, action) => {
      const { result } = action.payload;

      state.moduleIds = result;
    },
    [loadSubmodules.fulfilled]: (state, action) => {
      const {
        result,
        entities: { submodules }
      } = action.payload;

      state.submodulesByModule = mapValues(
        groupBy(result, (submoduleId) => submodules[submoduleId].fk_module_id),
        (submoduleIds) =>
          orderBy(
            submoduleIds,
            [(submoduleId) => toInteger(submodules[submoduleId]?.sort)],
            ['desc']
          )
      );
    },
    [loadTableTemplatesList.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { moduleId }
      } = action.payload;

      state.tableConfigsByModule[moduleId] = result;
    },
    [getSubmoduleListView.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { submoduleId, tableConfigId, propertyIds }
      } = action.payload;
      const rows = map(values(result), (data, index) =>
        getTableRowFromDataProviderRow(data, index, propertyIds)
      );

      state.objectListingRows = {
        [submoduleId]: {
          [tableConfigId]: rows
        }
      };
    },
    [loadSearchResults.fulfilled]: (state, action) => {
      const {
        result,
        requestInfo: { moduleId, searchText }
      } = action.payload;
      const rows = map(values(result), (data, index) =>
        getTableRowFromDataProviderRow(data, index)
      );

      state.searchResultRows = {
        [moduleId]: {
          [searchText]: rows
        }
      };
    },
    [loadHistoryList.fulfilled]: (state, action) => {
      const { result } = action.payload;
      const rows = map(values(result), (data, index) =>
        getTableRowFromDataProviderRow(data, index)
      );

      state.historyRows = rows;
    },
    [getFavoriteObjectsList.fulfilled]: (state, action) => {
      const { result } = action.payload;
      const rows = map(values(result), (data, index) =>
        getTableRowFromDataProviderRow(data, index)
      );

      state.favoritesRows = rows;
    },
    [loadTemplatesList.fulfilled]: (state, action) => {
      const { result } = action.payload;
      const rows = map(values(result), (data, index) =>
        getTableRowFromDataProviderRow(data, index)
      );

      state.templateRows = rows;
    },
    [createTemplate.fulfilled]: (state, action) => {
      const {
        result: { object }
      } = action.payload;

      state.createdTemplate = object;
    },
    [deleteTemplate.fulfilled]: (state, action) => {
      state.templateDeletedAt = Date.now();
    },
    [renameTemplate.fulfilled]: (state, action) => {
      state.templateRenamedAt = Date.now();
    }
  }
});

export const {
  selectModule,
  selectSubmodule,
  selectTableConfig,
  clearTableRows
} = moduleSlice.actions;

export default moduleSlice.reducer;

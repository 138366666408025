import React from 'react';
import { connect } from 'react-redux';
import { map, filter, find, compact, values } from 'lodash';
import { Helmet } from 'react-helmet-async';
import memoizeOne from 'memoize-one';
import ReactDataGrid from 'react-data-grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import EditPermissionDialog from './EditPermissionDialog';
import { selectTranslations } from '../store/languageSlice';
import { loadPermissionsList } from '../store/userSlice';
import './Users.scss';

class Permissions extends React.PureComponent {
  state = {
    filteredRows: [],
    expandedRows: {},
    permissionToEdit: null
  };

  componentDidMount() {
    const { loadPermissionsList } = this.props;

    loadPermissionsList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { permissions, permissionSavedAt, loadPermissionsList } = this.props;
    const { expandedRows } = this.state;

    if (
      permissions !== prevProps.permissions ||
      expandedRows !== prevState.expandedRows
    ) {
      this._filterRows();
    }

    if (
      permissionSavedAt &&
      permissionSavedAt !== prevProps.permissionSavedAt
    ) {
      loadPermissionsList();
    }
  }

  render() {
    const { translations, actions } = this.props;
    const { filteredRows, permissionToEdit } = this.state;
    const columns = this._getColumns(actions);

    return (
      <div className="users object-listing">
        <Helmet>
          <title>{translations.permissions}</title>
        </Helmet>
        <div className="cta-container" />
        <ReactDataGrid
          className="data-grid rdg-light"
          columns={columns}
          rows={filteredRows}
          rowHeight={30}
          rowKeyGetter={(row) => row.key}
        />
        {permissionToEdit && (
          <EditPermissionDialog
            permission={permissionToEdit}
            onClose={this._onCloseEdit}
          />
        )}
      </div>
    );
  }

  _getColumns = memoizeOne((actions) => {
    const { translations } = this.props;

    return [
      {
        key: 'name',
        name: translations.area,
        resizable: true,
        formatter: (props) => {
          const { expandedRows } = this.state;
          const {
            row: { key, tableName, name, hasChildren }
          } = props;
          const isExpanded = expandedRows[key];

          return (
            <div className={`${tableName}-cell`}>
              <div className="expand-button-holder">
                {hasChildren && (
                  <IconButton
                    className="expand-button"
                    data-row-key={key}
                    onClick={this._onToggleExpand}
                  >
                    {isExpanded ? (
                      <KeyboardArrowDownIcon fontSize="small" />
                    ) : (
                      <ChevronRightIcon fontSize="small" />
                    )}
                  </IconButton>
                )}
              </div>
              {name}
            </div>
          );
        }
      },
      ...map(actions, (action) => ({
        key: action.id,
        name: action.name,
        resizable: true,
        formatter: (props) => {
          const {
            row: { key, rights }
          } = props;
          const actionRight = find(rights, { actionId: action.id });
          let text = '';
          let rightId = null;

          if (actionRight) {
            text = compact([
              ...map(actionRight.userGroups, 'name'),
              ...map(actionRight.users, 'username')
            ]).join(', ');
            rightId = actionRight.id;
          }

          return (
            <div className="right-cell">
              <div className="right-cell-users">{text}</div>
              <Tooltip title={translations.edit_permissions} arrow>
                <Button
                  className="icon-button edit-right-button"
                  data-row-key={key}
                  data-action-id={action.id}
                  data-right-id={rightId}
                  color="primary"
                  variant="contained"
                  onClick={this._onEdit}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
            </div>
          );
        }
      }))
    ];
  });

  _filterRows = () => {
    const { permissions } = this.props;
    const { expandedRows } = this.state;
    const filteredRows = filter(permissions, (row) => {
      // show row if it's at the root level
      if (!row.parent) {
        return true;
      }

      // hide row if its immediate parent is collapsed
      if (!expandedRows[row.parent]) {
        return false;
      }

      // hide row if any of its ancestors is collapsed
      if (row.tableName === 'screens') {
        const submoduleRow = find(permissions, { key: row.parent });

        if (!expandedRows[submoduleRow.parent]) {
          return false;
        }
      }

      return true;
    });

    this.setState({
      filteredRows
    });
  };

  _onToggleExpand = (e) => {
    const { rowKey } = e.currentTarget.dataset;
    const { expandedRows } = this.state;

    this.setState({
      expandedRows: {
        ...expandedRows,
        [rowKey]: !expandedRows[rowKey]
      }
    });
  };

  _onEdit = (e) => {
    const { permissions } = this.props;
    const { rowKey, actionId, rightId } = e.currentTarget.dataset;
    const row = find(permissions, { key: rowKey });
    const { tableName, tableId, name, rights } = row;
    const right = rightId ? rights[rightId] : null;

    this.setState({
      permissionToEdit: {
        tableName,
        tableId,
        name,
        rightId,
        actionId,
        users: values(right?.users),
        groups: values(right?.userGroups)
      }
    });
  };

  _onCloseEdit = () => {
    this.setState({ permissionToEdit: null });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { actions },
    user: { permissions, permissionSavedAt }
  } = state;

  return {
    translations: selectTranslations(state),
    actions,
    permissions,
    permissionSavedAt
  };
}

export default connect(mapStateToProps, { loadPermissionsList })(Permissions);

import React from 'react';
import { connect } from 'react-redux';
import { map, isEmpty } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import {
  removeNotification,
  removeAllNotifications,
  selectNotifications
} from '../store/notificationSlice';
import './Notifications.scss';

class Notifications extends React.PureComponent {
  render() {
    const { notifications } = this.props;

    return (
      <Snackbar
        open={!isEmpty(notifications)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        TransitionComponent={Slide}
        onClose={this._onCloseAllNotifications}
      >
        <div>
          {map(notifications, (notification, index) => (
            <Alert
              key={index}
              className="notification-alert"
              elevation={6}
              variant="filled"
              severity={notification.type}
              onClose={() => this._onCloseNotification(index)}
            >
              {notification.message}
            </Alert>
          ))}
        </div>
      </Snackbar>
    );
  }

  _onCloseNotification = (index) => {
    this.props.removeNotification(index);
  };

  _onCloseAllNotifications = () => {
    this.props.removeAllNotifications();
  };
}

function mapStateToProps(state, ownProps) {
  return {
    notifications: selectNotifications(state)
  };
}

export default connect(mapStateToProps, {
  removeNotification,
  removeAllNotifications
})(Notifications);

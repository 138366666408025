import React from 'react';
import { connect } from 'react-redux';
import { map, find, values, isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ReactDataGrid from 'react-data-grid';
import AdvancedSelect from '../controls/AdvancedSelect';
import { selectTranslations } from '../store/languageSlice';
import {
  getEmailTemplates,
  exportAuftrags,
  emailAuftrags,
  resetAuftragExport
} from '../store/exportSlice';
import './ExportDialog.scss';

const COLUMNS = [
  {
    key: '455',
    name: 'Auftrags-Nr.',
    width: 100,
    minWidth: 20,
    resizable: true
  },
  {
    key: '31',
    name: 'Gesellschafter',
    minWidth: 20,
    resizable: true
  },
  {
    key: '581',
    name: 'Abrechnung Mailadresse',
    minWidth: 20,
    resizable: true
  }
];

class ExportAuftragDialog extends React.PureComponent {
  state = {
    selectedEmailTemplate: null
  };

  componentDidMount() {
    const { emailTemplates, getEmailTemplates } = this.props;

    if (isEmpty(emailTemplates)) {
      getEmailTemplates();
    } else {
      this._selectDefaultEmailTemlpate();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      emailTemplates,
      lastDownloadURL,
      lastEmailsSentAt,
      lastEmailsSuccessful,
      onClose
    } = this.props;

    if (lastDownloadURL && prevProps.lastDownloadURL !== lastDownloadURL) {
      window.open(lastDownloadURL, '_blank');
      onClose();
    }

    // if emails sent successfully, close the dialog
    if (
      lastEmailsSentAt &&
      lastEmailsSuccessful &&
      lastEmailsSentAt !== prevProps.lastEmailsSentAt
    ) {
      onClose();
    }

    // select the first email template
    if (
      !isEmpty(emailTemplates) &&
      emailTemplates !== prevProps.emailTemplates
    ) {
      this._selectDefaultEmailTemlpate();
    }
  }

  render() {
    const {
      translations,
      emailTemplates,
      rows,
      emailAuftragFailures,
      isExporting,
      onClose
    } = this.props;
    const { selectedEmailTemplate } = this.state;
    const emailAddressesExist = find(rows, (row) => row['581']);

    return (
      <>
        <Dialog
          className="export-dialog"
          open
          maxWidth="md"
          fullWidth
          onClose={onClose}
        >
          <DialogTitle>{translations.email_orders_window_title}</DialogTitle>
          <DialogContent>
            <div className="input-row">
              <ReactDataGrid
                className="data-grid rdg-light"
                columns={COLUMNS}
                rows={rows}
                rowHeight={30}
                rowKeyGetter={(row) => row.id}
              />
            </div>
            <DialogContentText>
              {translations.choose_email_template}
            </DialogContentText>
            <div className="input-row text-field-container">
              <AdvancedSelect
                options={emailTemplates}
                value={selectedEmailTemplate}
                onChange={this._onEmailTemplateChanged}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {translations.cancel}
            </Button>
            <Button onClick={this._onDownload} color="primary">
              {translations.download}
            </Button>
            <Button
              color="primary"
              disabled={!emailAddressesExist || isExporting}
              onClick={this._onSend}
            >
              {translations.send_emails}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="export-dialog"
          open={!isEmpty(emailAuftragFailures)}
          maxWidth="sm"
          fullWidth
          onClose={this._onCloseFailures}
        >
          <DialogTitle>{translations.warning}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Folgende Emails konnten nicht an die entsprechenden Gesellschafter
              gesendet werden:
            </DialogContentText>
            <ul>
              {map(emailAuftragFailures, (auftrag) => (
                <li key={auftrag.id}>Auftrags-Nr. {auftrag['455']}</li>
              ))}
            </ul>
            <DialogContentText>
              Möchten Sie die zugeordneten Aufträge statt dessen als PDF
              herunterladen?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this._onCloseFailures}>
              {translations.cancel}
            </Button>
            <Button
              color="primary"
              disabled={isExporting}
              onClick={this._onDownloadFailures}
            >
              {translations.download}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  _selectDefaultEmailTemlpate = () => {
    const { emailTemplates } = this.props;

    this.setState({ selectedEmailTemplate: emailTemplates[0].id });
  };

  _onEmailTemplateChanged = (value) => {
    this.setState({
      selectedEmailTemplate: value
    });
  };

  _onDownload = () => {
    const { rows, exportAuftrags } = this.props;

    exportAuftrags({ auftragIds: map(rows, 'id') });
  };

  _onSend = () => {
    const { rows, emailAuftrags } = this.props;
    const { selectedEmailTemplate } = this.state;

    emailAuftrags({
      auftragIds: map(rows, 'id'),
      emailTemplateId: selectedEmailTemplate
    });
  };

  _onDownloadFailures = () => {
    const { emailAuftragFailures, exportAuftrags } = this.props;

    exportAuftrags({ auftragIds: map(emailAuftragFailures, 'id') });
  };

  _onCloseFailures = () => {
    const { resetAuftragExport, onClose } = this.props;

    resetAuftragExport();
    onClose();
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { objects },
    object: {
      activeObject: { id: objectId }
    },
    export: {
      emailTemplates: emailTemplateIds,
      lastDownloadURL,
      lastEmailsSentAt,
      lastEmailsSuccessful,
      emailAuftragFailures
    },
    request: { pending }
  } = state;
  const emailTemplates = map(emailTemplateIds, (id) => objects[id]);
  const object = objects[objectId];
  const task = find(object?.tasks, { actionType: 'exportAndMail' });
  const rows = values(task?.params);
  const isExporting =
    !!pending.getEmailTemplates ||
    !!pending.exportAuftrags ||
    !!pending.emailAuftrags;

  return {
    translations: selectTranslations(state),
    emailTemplates,
    rows,
    lastDownloadURL,
    lastEmailsSentAt,
    lastEmailsSuccessful,
    emailAuftragFailures,
    isExporting
  };
}

export default connect(mapStateToProps, {
  getEmailTemplates,
  exportAuftrags,
  emailAuftrags,
  resetAuftragExport
})(ExportAuftragDialog);

import React from 'react';
import { connect } from 'react-redux';
import { map, isEmpty } from 'lodash';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import PanoramaVerticalIcon from '@material-ui/icons/PanoramaVertical';
import AdvancedSelect from '../controls/AdvancedSelect';
import {
  EXPORT_SOURCE_OBJECT,
  OUTPUT_FORMAT_CSV,
  OUTPUT_FORMAT_PLAIN_CSV,
  OUTPUT_FORMAT_PDF,
  OUTPUT_FORMAT_HTML,
  OUTPUT_FORMAT_XLS,
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE
} from '../constants/exportSettings';
import { selectTranslations } from '../store/languageSlice';
import { updateExportRequest } from '../store/exportSlice';

const TABLE_OUTPUT_FORMAT_OPTIONS = [
  { label: 'CSV', value: OUTPUT_FORMAT_CSV },
  { label: 'Plain CSV (Mac OS Roman)', value: OUTPUT_FORMAT_PLAIN_CSV },
  { label: 'PDF', value: OUTPUT_FORMAT_PDF },
  { label: 'HTML', value: OUTPUT_FORMAT_HTML },
  { label: 'XLS', value: OUTPUT_FORMAT_XLS }
];

const OBJECT_OUTPUT_FORMAT_OPTIONS = [
  { label: 'PDF', value: OUTPUT_FORMAT_PDF },
  { label: 'HTML', value: OUTPUT_FORMAT_HTML },
  { label: 'XLS', value: OUTPUT_FORMAT_XLS }
];

class ExportDialogContent extends React.PureComponent {
  render() {
    const { translations, exportRequest, submodulePrintTemplates } = this.props;

    if (!exportRequest) {
      return null;
    }

    const { exportSource, outputFormat, orientation, templateId } =
      exportRequest;
    const isObject = exportSource === EXPORT_SOURCE_OBJECT;

    return (
      <DialogContent>
        <DialogContentText>
          {translations.choose_output_format}
        </DialogContentText>
        <div className="input-row text-field-container">
          <AdvancedSelect
            options={
              isObject
                ? OBJECT_OUTPUT_FORMAT_OPTIONS
                : TABLE_OUTPUT_FORMAT_OPTIONS
            }
            value={outputFormat}
            optionLabelField="label"
            optionValueField="value"
            onChange={this._onOutputFormatChanged}
          />
        </div>
        {!isEmpty(submodulePrintTemplates) && (
          <div className="input-row text-field-container">
            <AdvancedSelect
              options={submodulePrintTemplates}
              value={templateId}
              disableClearable={isObject}
              onChange={this._onPrintTemplateChanged}
            />
          </div>
        )}
        {outputFormat === OUTPUT_FORMAT_PDF && (
          <div className="input-row">
            <RadioGroup
              className="orientation-radio-group"
              value={orientation}
              row
              onChange={this._onOrientationChanged}
            >
              <Radio value={ORIENTATION_PORTRAIT} color="primary" />
              <PanoramaVerticalIcon color="primary" />
              <Radio value={ORIENTATION_LANDSCAPE} color="primary" />
              <PanoramaHorizontalIcon color="primary" />
            </RadioGroup>
          </div>
        )}
      </DialogContent>
    );
  }

  _onOutputFormatChanged = (value) => {
    const { updateExportRequest } = this.props;

    updateExportRequest({
      outputFormat: value
    });
  };

  _onPrintTemplateChanged = (value) => {
    const { updateExportRequest } = this.props;

    updateExportRequest({
      templateId: value
    });
  };

  _onOrientationChanged = (e) => {
    const { updateExportRequest } = this.props;

    updateExportRequest({
      orientation: e.target.value
    });
  };
}

function mapStateToProps(state, ownProps) {
  const {
    entities: { objects, printTemplates },
    object: {
      activeObject: { id: activeObjectId }
    },
    export: { exportRequest, printTemplatesBySubmodule }
  } = state;
  let submodulePrintTemplates = null;

  if (activeObjectId) {
    const submoduleId = objects[activeObjectId]?.submodule;
    submodulePrintTemplates =
      submoduleId &&
      map(printTemplatesBySubmodule[submoduleId], (id) => printTemplates[id]);
  }

  return {
    translations: selectTranslations(state),
    exportRequest,
    submodulePrintTemplates
  };
}

export default connect(mapStateToProps, {
  updateExportRequest
})(ExportDialogContent);

export function getCookie(name) {
  const cookieEntries = document.cookie.split(/\s*;\s*/);
  const cookieEntry = cookieEntries.find((entry) =>
    entry.startsWith(`${name}=`)
  );

  if (cookieEntry) {
    return cookieEntry.split('=')[1];
  }

  return null;
}

export function setCookie(name, value) {
  document.cookie = `${name}=${value}`;
}

import { pickBy, isEmpty } from 'lodash';

const initialState = {
  pending: {} // shape: pending[requestName][uniqueRequestId] = args
};

export default function entitiesReducer(state = initialState, action) {
  const { type, meta } = action;
  const slashIndex = type.indexOf('/');

  if (slashIndex > -1 && meta) {
    const requestName = type.substr(0, slashIndex);
    const { arg, requestId, requestStatus } = meta;
    const pending = { ...state.pending };

    if (requestStatus === 'pending') {
      pending[requestName] = {
        ...pending[requestName],
        [requestId]: pickBy(arg, (value) => !(value instanceof File)) || null
      };
    } else {
      pending[requestName] = { ...pending[requestName] };
      delete pending[requestName][requestId];

      if (isEmpty(pending[requestName])) {
        delete pending[requestName];
      }
    }

    return {
      ...state,
      pending
    };
  }

  return state;
}

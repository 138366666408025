import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/Image';
import { uploadImage } from '../../store/objectSlice';

class ImageItem extends React.PureComponent {
  fileInput = React.createRef();

  render() {
    const {
      item,
      value: { value },
      onChange
    } = this.props;

    return (
      <div className={classnames('image-item', { 'no-image': !value })}>
        {value ? (
          <img className="image-display" src={value} alt={value} />
        ) : (
          <ImageIcon className="no-image-icon" color="primary" />
        )}
        <div className="image-item-controls">
          <div className="file-input-container">
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
            <input
              ref={this.fileInput}
              type="file"
              accept="image/*"
              onChange={this._onFileSelected}
            />
          </div>
          {value && (
            <IconButton color="primary" onClick={() => onChange(item, '')}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  }

  _onFileSelected = (e) => {
    const { item, uploadImage } = this.props;
    const { files } = e.target;

    if (files.length > 0) {
      uploadImage({ propertyId: item.property, file: files[0] });
      this.fileInput.current.value = '';
    }
  };
}

export default connect(null, { uploadImage })(ImageItem);

import React from 'react';
import { connect } from 'react-redux';
import { toInteger, isEmpty } from 'lodash';
import AdvancedSelect from '../../controls/AdvancedSelect';
import { getDependentItemsValues } from '../../store/objectSlice';

class ObjectSelectorItem extends React.PureComponent {
  componentDidMount() {
    this._setDefault();
  }

  componentDidUpdate(prevProps) {
    const { isNewObject } = this.props;

    if (!prevProps.isNewObject && isNewObject) {
      this._setDefault();
    }
  }

  render() {
    const { item, value } = this.props;
    const {
      readOnly,
      allowEmptySelection,
      valueModeEnabled,
      required,
      choices
    } = item;
    const options = isEmpty(choices) ? [] : choices;

    return (
      <div className="object-selector-item">
        <AdvancedSelect
          options={options}
          value={value.value}
          optionValueField={valueModeEnabled ? 'label' : 'data'}
          optionLabelField="label"
          disabled={!!readOnly}
          error={required && isEmpty(value.value)}
          disableClearable={required || !allowEmptySelection}
          onChange={this._onChange}
        />
      </div>
    );
  }

  _setDefault = () => {
    const { item, isNewObject, getDependentItemsValues, onChange } = this.props;

    if (isNewObject) {
      const defaultIndex = toInteger(item.defaultIndex);
      const { property, valueModeEnabled, choices } = item;

      if (
        !isEmpty(choices) &&
        defaultIndex > -1 &&
        defaultIndex < choices.length
      ) {
        const option = choices[defaultIndex];
        const value = option[valueModeEnabled ? 'label' : 'data'];

        onChange(item, value, option);
        getDependentItemsValues({
          propertyId: property,
          selectedOption: option
        });
      }
    }
  };

  _onChange = (value, option) => {
    const { item, getDependentItemsValues, onChange } = this.props;

    onChange(item, value, option);
    getDependentItemsValues({
      propertyId: item.property,
      selectedOption: option
    });
  };
}

export default connect(null, { getDependentItemsValues })(ObjectSelectorItem);
